import { Field, Form, useFormikContext } from "formik";

import { Alert, Modal } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { InputField } from "components/shared/form-fields";
import { AddPolicyFormSchema } from "pages/settings/policies/add-policy/add-policy-modal/index";
import { trackEvent } from "utils";

import styles from "./add-policy-modal.module.scss";

type AddPolicyFormProps = {
  handleClose: () => void;
  show: boolean;
};

const onContactRepClick = () => {
  trackEvent("addPolicy_contactAgent");
};

export const AddPolicyForm = ({ handleClose, show }: AddPolicyFormProps) => {
  const { isValid, dirty, isSubmitting, resetForm, status, submitForm } =
    useFormikContext<AddPolicyFormSchema>();

  return (
    <Modal
      open={show}
      onClose={() => {
        handleClose();
        resetForm();
      }}
      heading="Add policy"
      primaryAction="Add policy"
      onPrimaryAction={submitForm}
      disablePrimary={!isValid || !dirty || isSubmitting}
      loadingPrimary={isSubmitting}
    >
      <div className={styles.modalContent}>
        <div>
          You can find your policy number in your policy declaration page. To
          quote a new policy,{" "}
          <MSSLink to="/my/support" onClick={onContactRepClick}>
            contact your insurance representative
          </MSSLink>
          .
        </div>
        <Form className={styles.modalContent}>
          <Field
            title="Policy number"
            name="policyNumber"
            component={InputField}
          />

          <Field
            title="Property zip code"
            name="zipCode"
            component={InputField}
            helpMessage="If your policy covers more than one location, enter the zip code of the first or primary location."
          />
        </Form>
        {status ? (
          <Alert appearance="danger" title="" description={status} />
        ) : null}
      </div>
    </Modal>
  );
};
