import { useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone-latest";
import { Controller } from "react-hook-form";

import { Alert, Heading, List, Span, UiIcon } from "@icg360/design-system";

import styles from "./upload-section.module.scss";

type RejectionErrorDisplayInfo = {
  fileName: string;
  rejectionReason: RejectionReason;
};

type RejectionReason =
  | "File type needs be .pdf, .jpg or .png."
  | "File is too large.";

export const UploadSection = ({
  preparedFiles,
  setPreparedFiles,
  submitting,
  showSuccessAlert,
  formControl: { control, register, setValue },
}) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  // Maximum file size in bytes
  const SIX_MILLION = 6000000;
  const rejectionMap: Record<string, RejectionReason> = {
    "file-invalid-type": "File type needs be .pdf, .jpg or .png.",
    "file-too-large": "File is too large.",
  };

  const rejectionErrorList: RejectionErrorDisplayInfo[] = rejectedFiles.map(
    (fileRejection: FileRejection) => {
      const { errors } = fileRejection;

      // If the file is both too large and an invalid type, only show the invalid type error.
      const errorCode = errors.reduce((curCode, { code }) => {
        if ([curCode, code].includes("file-invalid-type")) {
          curCode = "file-invalid-type";
        } else if (code === "file-too-large") {
          curCode = code;
        }
        return curCode;
      }, "");

      return {
        fileName: fileRejection.file.name,
        rejectionReason: rejectionMap[errorCode],
      };
    }
  );

  return (
    <>
      {showSuccessAlert && (
        <Alert
          appearance="info"
          title="We have the files you shared."
          description="We’ll get back to you in the next five days to let you know if there’s anything else for you to do. If you have questions or concerns, your insurance rep can help."
          className={styles.successAlert}
        />
      )}
      <Heading size="sm" className={styles.dropzoneHeading}>
        Proof of repair
      </Heading>
      <Controller
        name="files"
        control={control}
        render={() => (
          <>
            <Dropzone
              noClick
              maxSize={SIX_MILLION}
              disabled={submitting}
              accept={{
                "image/jpg": [".jpg", ".jpeg"],
                "image/png": [".png"],
                "application/pdf": [".pdf"],
              }}
              onDropRejected={setRejectedFiles}
              onDrop={(acceptedFiles) => {
                setPreparedFiles([
                  ...preparedFiles,
                  ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  ),
                ]);
                setValue(
                  "files",
                  [...preparedFiles, ...acceptedFiles] as unknown as FileList,
                  {
                    shouldValidate: true,
                  }
                );

                if (rejectionErrorList.length > 0) {
                  setRejectedFiles([]);
                }
              }}
            >
              {({ getRootProps, getInputProps, isDragActive, open }) => (
                <button
                  className={
                    isDragActive ? styles.dropAreaDragging : styles.dropArea
                  }
                  {...getRootProps()}
                  onClick={open}
                >
                  <UiIcon name="Document" size="md" />
                  <Span bold>Drag your files here</Span>
                  <Span color="quiet">Up to 6MB each</Span>
                  <Span size="sm" color="quiet">
                    We can handle: JPG, PNG, PDF
                  </Span>

                  <div className={styles.inputLabel}>
                    <Span
                      bold
                      size="sm"
                      color="interactive"
                      className={styles.inputLink}
                    >
                      Choose files
                    </Span>
                    <input
                      {...register("files")}
                      {...getInputProps({
                        type: "file",
                        name: "files",
                        multiple: true,
                      })}
                    />
                  </div>
                </button>
              )}
            </Dropzone>

            {rejectionErrorList.length > 0 && (
              <Alert
                className={styles.fileErrorAlert}
                title="Something went wrong while uploading the following images:"
                appearance="danger"
                description={
                  <List type="ul">
                    {rejectionErrorList.map(({ fileName, rejectionReason }) => {
                      return (
                        <li key={fileName + rejectionReason}>
                          {`"${fileName}" (${rejectionReason})`}
                        </li>
                      );
                    })}
                  </List>
                }
              />
            )}
          </>
        )}
      />
    </>
  );
};
