import PropTypes from "prop-types";
import { createContext, useReducer } from "react";

const UpdatePaymentContext = createContext();

const initialState = {
  paymentMethodSaved: false,
  paymentMethodInfo: null,
};

let reducer = (state, action) => {
  switch (action.type) {
    case "payment-method-update": {
      return {
        paymentMethodSaved: action.payload.paymentMethodSaved,
        paymentMethodInfo: {
          ...state.paymentMethodInfo,
          ...action.payload.paymentMethodInfo,
        },
      };
    }
  }
};

function UpdatePaymentContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <UpdatePaymentContext.Provider value={value}>
      {children}
    </UpdatePaymentContext.Provider>
  );
}

UpdatePaymentContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export { UpdatePaymentContext, UpdatePaymentContextProvider };
