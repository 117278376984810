import cx from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";

import styles from "./curved-icon-wrapper.module.scss";

type CurvedIconWrapperProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

export const CurvedIconWrapper = ({
  children,
  className,
  ...props
}: CurvedIconWrapperProps) => {
  return (
    <div className={cx(styles.iconWrapper, className)} {...props}>
      {children}
    </div>
  );
};
