import PropTypes from "prop-types";

import { Card, Link as DSLink, Heading } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { Stack } from "components/common/stack";
import { en as locale } from "locale";

import styles from "./faq-sidebar.module.scss";
import { pageCategoryMap, useFaqSidebar } from "./hooks";

export const FaqSidebar = ({ page }) => {
  const { faqs, answerShown, toggleQuestion } = useFaqSidebar(page);

  const faqAnswer = (faq) => (
    <Stack className={styles.answer} gap="xs">
      <span className={styles.boldQuestion}>{faq.question}</span>
      <faq.answer />
      <DSLink onClick={() => toggleQuestion(null)}>
        {locale.buttons.back}
      </DSLink>
    </Stack>
  );

  const faqList = () => (
    <>
      <ul id="faqs" className={styles.mainList}>
        {faqs.slice(0, 3).map((faq, idx) => (
          <li id={faq.id} key={idx} className={styles.listItem}>
            <DSLink onClick={() => toggleQuestion(faq.id)}>
              {faq.question}
            </DSLink>
          </li>
        ))}
      </ul>
      <MSSLink to="/my/support" className={styles.toFaqLink}>
        {locale.buttons.viewAllFaqs}
      </MSSLink>
    </>
  );

  return (
    <Card>
      <Heading size="md" className={styles.faqHeadline}>
        {locale.titles.containers.faqs}
      </Heading>
      {answerShown
        ? faqAnswer(faqs.find((f) => f.id === answerShown))
        : faqList()}
    </Card>
  );
};

FaqSidebar.propTypes = {
  isCommercial: PropTypes.bool,
  page: PropTypes.oneOf(Object.keys(pageCategoryMap)),
  paymentMethod: PropTypes.string,
};
