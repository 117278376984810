import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./container.module.scss";

export const Container = ({ children, className }) => (
  <div className={cx(styles.container, className)}>{children}</div>
);

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
