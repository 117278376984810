import { useContext } from "react";

import { Link as DSLink, Heading, Hr, Span } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useThumbtackModalContext } from "components/thumbtack-modal";
import { formatDate, trackEvent } from "utils";

import styles from "./underwriting-condition-item.module.scss";

export const UnderwritingConditionItem = ({
  underwritingCondition,
  zipCode,
}) => {
  const { openThumbtackModal } = useThumbtackModalContext();
  const { selectedPolicy } = useContext(AuthAppContext);
  const { dueDate } =
    selectedPolicy?.inspection?.newBusinessInspectionWriteOuts ?? {};

  return (
    <div className={styles.writeOutSection}>
      <Heading size="sm" className={styles.writeOutHeader}>
        {underwritingCondition.referralText}
      </Heading>
      <div className={styles.fixDescription}>
        <Span>{underwritingCondition.actionStatement}</Span>
      </div>
      <div>
        <Span>
          {underwritingCondition.proof}{" "}
          {dueDate ? `by ${formatDate(dueDate, "MM/DD/YYYY")}` : ""}.
        </Span>
        <div className={styles.contractorLink}>
          Need help?{" "}
          <DSLink
            onClick={() => {
              openThumbtackModal(
                zipCode,
                underwritingCondition?.category,
                underwritingCondition?.searchTerm
              );
              trackEvent("writeout_findContractorClicked");
            }}
          >
            Find a contractor
          </DSLink>
        </div>
      </div>
      <Hr />
    </div>
  );
};
