import { type LDFlagValue } from "launchdarkly-js-client-sdk";
import { type JSX } from "react";

import { isLDIdentified, useFlags } from "utils";

type FeatureProps = {
  flag: string;
  flagValue?: LDFlagValue;
  fallback: () => JSX.Element;
  children: JSX.Element;
};

/**
 * Feature component lets you declare a fallback if a flag is false. If true
 * children of the component will be rendered.
 *
 * ```
 * <Feature flag="newThing" fallback={() => <Navigate to="/" />}>
 *   <div>Only rendered when flag is on!</div>
 * </Feature>
 * ```
 */
export const Feature = ({
  flag,
  fallback,
  children,
  flagValue = true,
}: FeatureProps) => {
  const flags = useFlags();
  if (!isLDIdentified() || flags[flag] === flagValue) {
    return children;
  }
  return fallback();
};
