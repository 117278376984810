import { Link as DSLink, UiIcon } from "@icg360/design-system";

import styles from "./mss-link.module.scss";

type EmailIconLinkProps = {
  email: string;
};

export const EmailIconLink = ({ email }: EmailIconLinkProps) => (
  <div className={styles.iconLink}>
    <div className={styles.linkIcon}>
      <UiIcon name="Envelope" />
    </div>
    <DSLink href={`mailto:${email}`}>{email}</DSLink>
  </div>
);
