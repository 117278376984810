import { BaseLayout } from "components/base-layout";

const UpdateCoverage = () => {
  return (
    <BaseLayout hideNavigation>
      <div>Update Coverage Goes Here</div>
    </BaseLayout>
  );
};

export default UpdateCoverage;
