import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./progress-bar.module.scss";

export const ProgressBar = ({ progress }) => {
  const progressStyle = cx(
    styles[`progress${progress || "0"}`],
    styles.progress
  );

  return (
    <div className={styles.progressBar}>
      <div className={progressStyle} />
    </div>
  );
};

ProgressBar.propTypes = { progress: PropTypes.number };
