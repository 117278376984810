import { PropsWithChildren, ReactNode } from "react";

import {
  Badge,
  BadgeProps as DesignSystemBadgeProps,
  Span,
  Spinner,
} from "@icg360/design-system";

import styles from "./welcome-banner-section.module.scss";

export type BadgeProps = {
  appearance: DesignSystemBadgeProps["appearance"];
  text: string;
};

type WelcomeBannerSectionProps = PropsWithChildren<{
  title: string;
  badgeProps?: BadgeProps;
  footer?: ReactNode;
  loading?: boolean;
}>;

export const WelcomeBannerSection = ({
  children,
  title,
  badgeProps,
  footer,
  loading = false,
}: WelcomeBannerSectionProps) => {
  return (
    <div className={styles.welcomeSection}>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.titleRow}>
            <Span size="sm" bold>
              {title}
            </Span>
            {badgeProps ? (
              <Badge appearance={badgeProps.appearance} size="compact">
                {badgeProps.text}
              </Badge>
            ) : null}
          </div>
          <div className={styles.contentRow}>
            {loading ? (
              <div className={styles.loaderWrapper}>
                <Spinner />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
        {!loading && footer ? (
          <div className={styles.actions}>{footer}</div>
        ) : null}
      </div>
    </div>
  );
};
