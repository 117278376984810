import { useCallback, useEffect, useState } from "react";

import { LoadingPage } from "components/shared/loading-page";
import { PageFooter } from "components/shared/page-footer";
import { CONFIG } from "config";
import { ORIGINS } from "consts";
import {
  useDecodeDataMutation,
  usePaperlessDocumentsEnrollFromEmailMutation,
} from "gql/__generated__/hooks";
import { en as locale } from "locale";
import {
  KeystoneApiClient,
  isError,
  logError,
  trackEvent,
  urlSearchParamsObject,
  useFlags,
} from "utils";

import {
  AlreadyEnrolledStatus,
  EnrollmentErrorStatus,
  EnrollmentSuccessfulStatus,
} from "./paperless-enrollment-status";

enum enrollmentStatusStates {
  SUCCESS,
  CONFLICT,
  ERROR,
  LOADING,
}

const { decodeData: restDecodeData } = new KeystoneApiClient(
  CONFIG.KEYSTONE_API_HREF
);

const trackPaperlessEnrollmentEvent = async (eventName, data) => {
  trackEvent(eventName, {
    ...data,
    enrollmentOrigin: ORIGINS.PAPERLESS_ENROLLMENT_EMAIL,
  });
};

const PaperlessEnrollment = () => {
  const { retireProxy, universalLogin } = useFlags();
  const [paperlessDocumentsEnrollFromEmail] =
    usePaperlessDocumentsEnrollFromEmailMutation();

  const [decodeData] = useDecodeDataMutation();

  const [currentState, setCurrentState] = useState(
    enrollmentStatusStates.LOADING
  );

  const enrollInPaperlessDocuments = useCallback(
    async (encodedData) => {
      try {
        const { data } = await paperlessDocumentsEnrollFromEmail({
          variables: { data: encodedData },
        });

        const { success, errorCode, errors } =
          data?.paperlessDocumentsEnrollFromEmail ?? {};

        if (success) {
          return {
            event: "Paperless Documents Enrolled",
            state: enrollmentStatusStates.SUCCESS,
          };
        } else {
          return {
            event: "Paperless Documents Enrollment Error Displayed",
            state:
              errorCode === 409
                ? enrollmentStatusStates.CONFLICT
                : enrollmentStatusStates.ERROR,
            errorCode,
            errorMessage: errors?.length && errors[0],
          };
        }
      } catch (err) {
        logError(`Enroll in paperless documents: ${err.message}`);
        return {
          event: "Paperless Documents Enrollment Error Displayed",
          state: enrollmentStatusStates.ERROR,
          errorCode: 500,
          errorMessage: "Server error",
        };
      }
    },
    [paperlessDocumentsEnrollFromEmail]
  );

  const decodeUserData = useCallback(
    async (encodedData) => {
      try {
        let responseData;
        if (retireProxy && universalLogin) {
          const res = await restDecodeData(encodedData);
          if (!isError(res)) responseData = res;
        } else {
          const { data } = await decodeData({
            variables: { data: encodedData },
          });
          responseData = data?.decodeData;
        }

        const { policyId: policyNumber, email } =
          responseData?.decodeData ?? {};

        return { policyNumber, email };
      } catch (err) {
        logError(`Paperless enrollment - decode data: ${err.message}`);
        return null;
      }
    },
    [decodeData, retireProxy, universalLogin]
  );

  useEffect(() => {
    const { data: encodedData } = urlSearchParamsObject();
    const enroll = async () => {
      const decodedUserData = await decodeUserData(encodedData);
      trackPaperlessEnrollmentEvent(
        "Paperless Documents Enrollment Page Loaded",
        decodedUserData
      );

      const { event, state, ...rest } = await enrollInPaperlessDocuments(
        encodedData
      );
      trackPaperlessEnrollmentEvent(event, {
        ...decodedUserData,
        ...rest,
      });
      setCurrentState(state);
    };
    enroll();
  }, [decodeUserData, enrollInPaperlessDocuments]);

  if (currentState === enrollmentStatusStates.LOADING) {
    return <LoadingPage title={locale.paperlessEnrollment.loadingPageTitle} />;
  }

  return (
    <>
      {currentState === enrollmentStatusStates.SUCCESS && (
        <EnrollmentSuccessfulStatus />
      )}
      {currentState === enrollmentStatusStates.CONFLICT && (
        <AlreadyEnrolledStatus />
      )}
      {currentState === enrollmentStatusStates.ERROR && (
        <EnrollmentErrorStatus />
      )}
      <PageFooter />
    </>
  );
};

export default PaperlessEnrollment;
