import {
  type LDClient,
  type LDContext,
  type LDFlagSet,
  initialize,
} from "launchdarkly-js-client-sdk";
import { useCallback, useContext, useEffect, useState } from "react";

import { GlobalContext } from "components/root/global-provider";
import { CONFIG } from "config";
import { logError, trackEvent } from "utils";

let flags: LDFlagSet = {};
let identified = false;

export function flagCheck(flag: string) {
  return !!flags[flag];
}

export function isLDIdentified() {
  return identified;
}

/**
 * Waits for full initialization of the LaunchDarkly client. This will timeout
 * after 2 seconds, letting waiting code continue with default flag values.
 * If there's no error, LD client can continue initializing in the background.
 *
 * To track impact in production a `feat_flag_wait` event is sent to MixPanel.
 */
export const launchDarklyClient = new Promise<LDClient>((resolve, reject) => {
  // TODO: remove after new auth is fully adopted
  const params = new URLSearchParams(window.location.search);
  if (params.has("use-new-auth")) {
    sessionStorage.setItem("use-new-auth", "1");
  }
  const useNewAuth = !!sessionStorage.getItem("use-new-auth");
  // END

  const start = performance.now();
  const WAIT_TIMEOUT = 2000;
  const client = initialize(CONFIG.LAUNCH_DARKLY_CLIENT_ID, {
    kind: "user",
    key: "UNAUTHENTICATED_USER",
    email: "",
    firstName: "",
    useNewAuth, // TODO: remove after new auth is fully adopted
  });
  const timeout = setTimeout(() => {
    trackEvent("feat_flag_wait", { ms: WAIT_TIMEOUT, timeout: true });
    resolve(client);
  }, WAIT_TIMEOUT);
  client.on("initialized", () => {
    flags = kebabToCamelcase(client.allFlags());
    clearTimeout(timeout);
    const finish = performance.now();
    trackEvent("feat_flag_wait", { ms: finish - start, timeout: false });
    resolve(client);
  });
  client.on("failed", (err) => {
    clearTimeout(timeout);
    logError(`LaunchDarkly failed to initialize: ${err.message}`);
    reject();
  });
});

/**
 * Custom useFlags hook allows for hook overrides in querystring.
 */
export const useFlags = () => {
  const { flagOverrides } = useContext(GlobalContext);
  const [reactFlags, setReactFlags] = useState<LDFlagSet>(flags);

  // Convert flags to camelcase and replace query string overrides.
  const updateFlags = useCallback(
    (client: LDClient) => {
      const ldFlags = kebabToCamelcase(client.allFlags());
      flags = Object.keys(flagOverrides).reduce(
        (accum, flagOverride) => {
          if (flagOverride in accum) {
            accum[flagOverride] = flagOverrides[flagOverride];
          }
          return accum;
        },
        { ...ldFlags }
      );
      setReactFlags(flags);
    },
    [flagOverrides]
  );

  // Get promise-wrapped client, update flags, and listen for changes.
  useEffect(() => {
    (async () => {
      const client = await launchDarklyClient;
      const handleChange = () => {
        updateFlags(client);
      };
      client.on("change", handleChange);
      updateFlags(client);
      return () => {
        client.off("change", handleChange);
      };
    })();
  }, [updateFlags]);

  return reactFlags;
};

/**
 * Identify user with LaunchDarkly, allowing for targeted flag values.
 */
export async function identify(context: LDContext) {
  try {
    const client = await launchDarklyClient;
    const ctx = {
      ...context,
      useNewAuth: !!sessionStorage.getItem("use-new-auth"), // TODO: remove after new auth is fully adopted
    };
    await client.identify(ctx);
  } catch (e) {
    // if this fails it should just use the previously created UNAUTHORIZED_USER flags
    trackEvent("Error Setting LD Context", {
      ...context,
      errorMessage: e.message,
    });
    logError(`LaunchDarkly identify: ${e.message}`);
  }
  identified = true;
}

/**
 * Converts kebab-case flag keys to camelcase.
 */
function kebabToCamelcase(input: LDFlagSet) {
  const output: LDFlagSet = {};
  Object.keys(input).forEach((k) => {
    output[k.replace(/-./g, (m) => m.toUpperCase()[1])] = input[k];
  });
  return output;
}
