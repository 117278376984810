import { Field, Form, useFormikContext } from "formik";

import { Alert, Modal } from "@icg360/design-system";

import { InputField } from "components/shared/form-fields";
import { en as locale } from "locale";

import styles from "./edit-email-form.module.scss";

type EditEmailFormProps = {
  handleClose: () => void;
  show: boolean;
  heading?: string;
};

const EditEmailForm = ({ handleClose, show, heading }: EditEmailFormProps) => {
  const { isValid, isSubmitting, dirty, resetForm, status, submitForm } =
    useFormikContext();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      heading={heading ?? "Update email address"}
      onPrimaryAction={submitForm}
      primaryAction="Update"
      loadingPrimary={isSubmitting}
      disablePrimary={!isValid || !dirty}
    >
      <div className={styles.wrapper}>
        {status && <Alert appearance="danger" title="" description={status} />}
        <Form>
          <div className={styles.wrapper}>
            <Field
              name={"email"}
              title={locale.settingsPage.newEmailAddress}
              component={InputField}
              placeholder={locale.form.placeholder.emailAddress}
            />
            <Field
              name={"confirmEmail"}
              title={locale.settingsPage.confirmEmailAddress}
              component={InputField}
              placeholder={locale.form.placeholder.confirmAddress}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditEmailForm;
