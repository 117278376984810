import {
  Component,
  type ErrorInfo,
  PropsWithChildren,
  type ReactNode,
} from "react";

import { FullPageError } from "pages";
import { logError, trackEvent } from "utils";

/**
 * Pass an optional `errorElement` to override default full-page error message.
 */
type ErrorBoundaryProps = PropsWithChildren<{
  errorElement?: ReactNode | null;
}>;

type ErrorBoundaryState = { error: unknown | null };

// Adapted from https://react.dev/reference/react/Component
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any, info: ErrorInfo) {
    logError(`Error boundary: ${error.message}`);
    trackEvent("MSS Error", { error, info });
  }

  render() {
    if (this.state.error) {
      // Error path
      return this.props.errorElement ?? <FullPageError />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
