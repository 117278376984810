import cx from "classnames";
import { HTMLAttributes, PropsWithChildren } from "react";

import styles from "./stack.module.scss";

type StackProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  gap?: "xs" | "sm" | "md" | "lg";
};

const Stack = ({ children, gap = "md", className, ...props }: StackProps) => {
  return (
    <div className={cx(styles.stack, className)} {...props}>
      {Array.isArray(children)
        ? children.map(
            (child, i) =>
              child && (
                <div
                  key={i}
                  className={cx(styles.stackItem, {
                    [styles.gapXs]: gap === "xs",
                    [styles.gapSm]: gap === "sm",
                    [styles.gapMd]: gap === "md",
                    [styles.gapLg]: gap === "lg",
                  })}
                >
                  {child}
                </div>
              )
          )
        : children}
    </div>
  );
};

export default Stack;
