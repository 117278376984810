import { Field, Form, Formik, useFormikContext } from "formik";
import cloneDeep from "lodash.clonedeep";
import { Col, Row } from "react-bootstrap";
import { string } from "yup";

import { Button, Tooltip, UiIcon } from "@icg360/design-system";

import {
  InputField,
  SelectField,
  TextAreaField,
} from "components/shared/form-fields";
import {
  GENERAL_SUPPORT,
  addressSchema,
  detailsRequiredReasons,
  mailingUpdateReasons,
  statesWithoutTerritories,
} from "consts";
import { useUpdateInsuredInfoMutation } from "gql/__generated__/hooks";
import { contactItemDataQuery } from "gql/queries";
import { logError, trackEvent } from "utils";

import styles from "./update-mailing-address.module.scss";

const mailingUpdateSchema = addressSchema.shape({
  mailingAddressUpdateReason: string()
    .oneOf(mailingUpdateReasons.map(({ value }) => value))
    .required("Please enter the reason for the update"),
  zipFirstFive: string()
    .required("Please enter a valid zip code")
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Please enter a valid zip code"),
  mailingAddressUpdateDetails: string().when("mailingAddressUpdateReason", {
    is: (reason) => detailsRequiredReasons.includes(reason),
    then: string().trim().required("More details are required"),
  }),
});

type UpdateMailingAddressFormFields = {
  streetLine1: string;
  streetLine2: string;
  city: string;
  state: string;
  zipFirstFive: string;
  mailingAddressUpdateReason: string;
  mailingAddressUpdateDetails: string;
};

const initialValues: UpdateMailingAddressFormFields = {
  streetLine1: "",
  streetLine2: "",
  city: "",
  state: "",
  zipFirstFive: "",
  mailingAddressUpdateReason: "",
  mailingAddressUpdateDetails: "",
};

type UpdateMailingAddressProps = {
  policyId: string;
  userEmail: string;
  onContactInfoUpdateError: () => void;
  onContactInfoUpdateSuccess: () => void;
  onFormCancel: () => void;
};

const UpdateMailingAddress = ({
  policyId,
  userEmail,
  onContactInfoUpdateError,
  onContactInfoUpdateSuccess,
  onFormCancel,
}: UpdateMailingAddressProps) => {
  const [updateMailingAddress] = useUpdateInsuredInfoMutation();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const { data: updateMailingAddressData } = await updateMailingAddress({
        variables: {
          policyID: policyId,
          emailAddress: userEmail,
          userUpdates: { mailingAddress: values },
        },
        update: (store, { data }) => {
          if (data?.updateInsuredInfo) {
            const readData = cloneDeep(
              store.readQuery<contactItemDataQuery>({
                query: contactItemDataQuery,
                variables: { policyID: policyId },
              })
            );

            const { mailingAddress } = { mailingAddress: values };

            if (mailingAddress && readData) {
              const updatedMailingAddress = (({
                streetLine1,
                streetLine2,
                city,
                state,
                zipFirstFive,
              }) => ({
                street1: streetLine1,
                street2: streetLine2,
                city,
                state,
                zip: zipFirstFive,
              }))(mailingAddress);

              readData.userDetails = {
                ...readData.userDetails,
                mailingAddress: {
                  ...readData.userDetails.mailingAddress,
                  ...updatedMailingAddress,
                },
              };

              store.writeQuery({
                query: contactItemDataQuery,
                data: readData,
                variables: { policyID: policyId },
              });
            }
          }
        },
        context: {
          clientName: "keystone-api",
        },
      });

      if (updateMailingAddressData?.updateInsuredInfo) {
        onContactInfoUpdateSuccess();
        resetForm();
        trackEvent("Mailing Address Updated", {
          changeReason: values.mailingAddressUpdateReason,
        });
      } else {
        trackEvent("Error Displayed (Update Contact Mailing Address)");
        onContactInfoUpdateError();
      }

      setSubmitting(false);
    } catch (err) {
      trackEvent("Error Displayed (Update Contact Mailing Address)", err);
      onContactInfoUpdateError();
      setSubmitting(false);
      logError(`Update mailing address: ${err.message}`);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={mailingUpdateSchema}
    >
      <UpdateMailingAddressForm onFormCancel={onFormCancel} />
    </Formik>
  );
};

type UpdateMailingAddressFormProps = {
  onFormCancel: () => void;
};

const UpdateMailingAddressForm = ({
  onFormCancel,
}: UpdateMailingAddressFormProps) => {
  const { values, isValid, dirty, isSubmitting } =
    useFormikContext<UpdateMailingAddressFormFields>();
  const { mailingAddressUpdateReason } = values;

  return (
    <Form>
      <Row>
        <Col md={6} className={styles.colField}>
          <Field
            name="streetLine1"
            title="Address line 1"
            component={InputField}
          />
        </Col>
        <Col md={6} className={styles.colField}>
          <Field
            name="streetLine2"
            title="Address line 2 (optional)"
            component={InputField}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} className={styles.colField}>
          <Field name="city" title="City" component={InputField} />
        </Col>
        <Col md={3} className={styles.colField}>
          <Field
            name="state"
            component={SelectField}
            title="State"
            options={statesWithoutTerritories.map(({ abbreviation, name }) => ({
              value: abbreviation,
              label: name,
            }))}
            fullWidth
          />
        </Col>
        <Col md={3} className={styles.colField}>
          <Field name="zipFirstFive" title="Zip code" component={InputField} />
        </Col>
      </Row>
      <Row>
        <Col md={12} className={styles.colField}>
          <Field
            name="mailingAddressUpdateReason"
            component={SelectField}
            title={
              <>
                Reason for update
                <Tooltip
                  content={`If you don't see a reason that meets your circumstances or need assistance updating your mailing address, please contact CustomerCare at customercare@sagesure.com or ${GENERAL_SUPPORT}.`}
                >
                  <span className={styles.tooltipIcon}>
                    <UiIcon name="Question" size="sm" />
                  </span>
                </Tooltip>
              </>
            }
            options={mailingUpdateReasons.map(({ value, label }) => ({
              value,
              label,
            }))}
            fullWidth
          />
        </Col>
      </Row>
      {mailingAddressUpdateReason && (
        <Row>
          <Col md={12} className={styles.colField}>
            <Field
              name="mailingAddressUpdateDetails"
              title={`${
                !detailsRequiredReasons.includes(mailingAddressUpdateReason)
                  ? "Details (optional)"
                  : "Details"
              }`}
              component={TextAreaField}
              placeholder="Please provide more details about this address change. If necessary, our underwriting department may contact you for more information."
              maxChars={500}
            />
          </Col>
        </Row>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          appearance="tertiary"
          onClick={onFormCancel}
          data-testid="mailing-update-cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!isValid || !dirty || isSubmitting}
          loading={isSubmitting}
          data-testid="mailing-update-btn"
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default UpdateMailingAddress;
