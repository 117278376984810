import { Formik } from "formik";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { object, string } from "yup";

import { Alert } from "@icg360/design-system";

import { useLogin } from "utils";

import { LoginForm } from "./login-form";

const schema = object().shape({
  username: string().required("Please enter a valid email address"),
  password: string().required("Please enter a password"),
});

const Login = () => {
  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [searchParams] = useSearchParams();
  const message = searchParams.get("message");
  const messageSuccess = searchParams.get("success") !== "false";

  const onErrorMsg = useCallback((errorMessages) => {
    if (errorMessages) {
      setErrorMessages(
        Array.isArray(errorMessages) ? errorMessages : [errorMessages]
      );
    } else {
      setErrorMessages([]);
    }
  }, []);

  const { handleLogin } = useLogin(onErrorMsg);

  return (
    <>
      {message ? (
        <Alert
          title=""
          appearance={messageSuccess ? "success" : "info"}
          description={message}
          data-testid={
            messageSuccess ? "loginSuccessMessage" : "loginInfoMessage"
          }
        />
      ) : null}
      {errorMessages?.length ? (
        <Alert
          title=""
          appearance="danger"
          description={errorMessages[0]}
          data-testid="loginErrorMessage"
        />
      ) : null}
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={handleLogin}
        validationSchema={schema}
      >
        <LoginForm onErrorMsg={onErrorMsg} />
      </Formik>
    </>
  );
};

export default Login;
