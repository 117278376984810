import { useContext, useEffect } from "react";
import { Col } from "react-bootstrap";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { Coverage, Details } from "components/renderers";
import { AuthAppContext } from "components/root/auth-app-provider";
import EasyPayCard from "components/shared/easypay-card";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { NotificationName } from "components/shared/notifications/notifications";
import { PaperlessCard } from "components/shared/paperless-card";
import { en as locale } from "locale";
import { FeaturedOffers } from "pages/overview/featured-offers/featured-offers";
import { useFeaturedOffers } from "pages/overview/featured-offers/hooks";
import { WelcomeBanner } from "pages/overview/welcome-banner";
import {
  isEasyPayEligible,
  scrollToYPosition,
  trackEvent,
  useFlags,
} from "utils";

import ExtendedCoverages from "./extended-coverages";
import { CoverageContext } from "./extended-coverages/hooks";
import CoverageUpdate from "./extended-coverages/update-coverage";
import { useError } from "./extended-coverages/update-coverage/hooks";
import styles from "./overview.module.scss";

const AVAILABLE_NOTIFICATIONS: NotificationName[] = [
  "Coverage",
  "Leakbot",
  "CancellationPayment",
];

const Overview = () => {
  const { updateCoverageRoute, featuredOffers: featuredOffersFlag } =
    useFlags();
  const { userDetails, userBilling, isPolicyDataReady } =
    useContext(AuthAppContext);

  const featuredOffers = useFeaturedOffers();

  // TODO: remove CoverageContext and errorState with updateCoverageRoute PPS-5580
  const { coveragePage, setCoveragePage } = useContext(CoverageContext);
  const errorState = useError();

  useEffect(() => {
    if (coveragePage.scroll_to) scrollToYPosition(coveragePage.scroll_to);
  }, [coveragePage]);

  useEffect(() => {
    trackEvent("Overview");
  }, []);

  if (!isPolicyDataReady) {
    return <BaseLayout loading />;
  }

  if (coveragePage.open && !updateCoverageRoute) {
    return (
      <BaseLayout hideNavigation>
        <CoverageUpdate
          error={errorState.error}
          dispatchError={errorState.dispatchError}
          setCoveragePage={setCoveragePage}
        />
      </BaseLayout>
    );
  }

  const Main = () => (
    <Stack>
      <Details />
      <Coverage />
      <ExtendedCoverages />
    </Stack>
  );

  const Sidebar = () => (
    <Stack>
      {isEasyPayEligible(userBilling, userDetails) ? <EasyPayCard /> : null}
      <InsuranceRepresentative />
      <FaqSidebar page="overview" />
      <PaperlessCard pageName={locale.titles.overview} />
    </Stack>
  );

  const TopRow = () => (
    <>
      <Col lg={featuredOffersFlag && featuredOffers.length ? 6 : 12}>
        <WelcomeBanner />
      </Col>
      {featuredOffersFlag && featuredOffers.length ? (
        <Col lg={6} className={styles.featuredOffers}>
          <FeaturedOffers offers={featuredOffers} />
        </Col>
      ) : null}
    </>
  );

  return (
    <BaseLayout availableNotifications={AVAILABLE_NOTIFICATIONS}>
      <MainLayout sidebar={<Sidebar />} topRow={<TopRow />} splitTopRow>
        <Main />
      </MainLayout>
    </BaseLayout>
  );
};

export default Overview;
