import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";

import { AddPolicy } from "./add-policy";
import PolicyItem from "./policy-item";

export const Policies = () => {
  const { policies } = useContext(AuthAppContext);

  const policyItems = policies?.map((policy) => (
    <PolicyItem
      key={policy.policyId}
      policy={policy}
      hasMultiplePolicies={policies.length > 1}
    />
  ));

  return (
    <SettingsPanelLayout
      title={
        <>
          Policies <AddPolicy />
        </>
      }
      policyItems={policyItems}
    />
  );
};
