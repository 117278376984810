import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { AddressLoader } from "components/loader";
import { useEnrollmentStatusDataQuery } from "gql/__generated__/hooks";

type EnrollmentStatusProps = {
  policyId?: string | null;
};

const EnrollmentStatus = ({ policyId }: EnrollmentStatusProps) => {
  const { loading, data: enrollmentStatusData } = useEnrollmentStatusDataQuery({
    skip: !policyId,
    variables: { policyID: policyId ?? "" },
  });

  if (loading) {
    return <AddressLoader />;
  }

  const { userDetails } = enrollmentStatusData ?? {};

  return (
    <>
      <div>
        <InlineDataRow>
          <DataTerm>Leakbot:</DataTerm>
          <DataDefinition>
            {userDetails?.isEnrolledInLeakBot ? "Enrolled" : "Not enrolled"}
          </DataDefinition>
        </InlineDataRow>
        {/* TODO: // Get Enrolled Date */}
        {/* {isEnrolledInLeakBot &&
      <InlineDataRow>
        <DataTerm>{locale.dataTable.dateEnrolled}:</DataTerm>
        <DataDefinition>{'04/15/2022'}</DataDefinition>
      </InlineDataRow>
      } */}
      </div>
    </>
  );
};

export default EnrollmentStatus;
