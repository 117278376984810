export const isBusinessHours: () => boolean = () => {
  const easternTime = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const currentDay = new Date(easternTime).getDay();
  const currentHour = new Date(easternTime).getHours();
  const isWeekday = [1, 2, 3, 4, 5].includes(currentDay);
  const isSaturday = currentDay === 6;
  const eightAM = 8;
  const ninePM = 21;
  const tenAM = 10;
  const threePM = 15;

  if (isWeekday) {
    return currentHour >= eightAM && currentHour < ninePM;
  } else if (isSaturday) {
    return currentHour >= tenAM && currentHour < threePM;
  }

  return false;
};
