import { RegularContentLoader } from "components/loader";
import { PolicySummary } from "components/shared/policy-summary";
import { useHomeServicesDataQuery } from "gql/__generated__/hooks";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import { authUser } from "utils";

import EnrollmentStatus from "./enrollment_status";

const HomeServices = () => {
  const { loading, data: homeServicesData } = useHomeServicesDataQuery({
    variables: { email: authUser()?.email ?? "" },
  });

  if (loading) {
    return <RegularContentLoader />;
  }

  const { userPolicies } = homeServicesData ?? {};

  const policyItems = userPolicies?.map((policy) => (
    <PolicySummary
      key={policy?.policyId}
      address={policy?.propertyAddress}
      policyId={policy?.policyId ?? ""}
      isCommercial={!!policy?.isCommercial}
    >
      <EnrollmentStatus policyId={policy?.policyId} />
    </PolicySummary>
  ));

  return (
    <SettingsPanelLayout title="Home Services" policyItems={policyItems} />
  );
};

export default HomeServices;
