import { Heading, MarketingIcon } from "@icg360/design-system";

import { Body } from "components/common/body";
import { CTACard } from "components/common/card";
import { MSSLink } from "components/common/link";
import { trackEvent } from "utils";

import styles from "./paperless-card.module.scss";

export const PaperlessCardEnrolled = () => {
  return (
    <CTACard
      title={<Heading size="md">Paperless enrolled</Heading>}
      icon={
        <div className={styles.headerIcon}>
          <MarketingIcon name="Paperless" />
        </div>
      }
    >
      <Body>
        We&apos;ll donate $1 to the Arbor Day Foundation to plant trees in
        neighborhoods devastated by natural disasters.
      </Body>
      <Body>
        You can view details of your Paperless enrollment in{" "}
        <MSSLink
          to="/my/settings/paperless"
          onClick={() => {
            trackEvent("paperlessCard_settingsClicked");
          }}
          style={{ marginTop: 0 }}
        >
          Settings
        </MSSLink>
        .
      </Body>
    </CTACard>
  );
};
