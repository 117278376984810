import cx from "classnames";
import {
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";

import { Alert, Card, Heading } from "@icg360/design-system";

import { CurvedIconWrapper } from "components/common/curved-icon-wrapper";

import styles from "./card.module.scss";

type CardProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

type CTACardProps = Omit<CardProps, "title"> & {
  title: string | ReactElement;
  icon?: ReactElement;
};

const CTACard = ({ children, title, icon, className = "" }: CTACardProps) => (
  <Card className={cx(styles.ctaCard, className)}>
    <div className={styles.iconHeader}>
      <CurvedIconWrapper>{icon}</CurvedIconWrapper>
      <div className={styles.iconHeaderTitle}>{title}</div>
    </div>
    {children}
  </Card>
);

const SidebarCTACard = ({ children, title, icon }: CTACardProps) => (
  <Card className={styles.ctaCard}>
    <div className={styles.iconHeader}>
      <div className={styles.iconWrapper}>{icon}</div>
      <Heading size="md">{title}</Heading>
    </div>
    {children}
  </Card>
);

type SectionCardProps = PropsWithChildren<{
  title: string | ReactNode;
  className?: string;
  icon?: ReactNode;
  error?: { set: boolean; title: string; description: string };
}>;

const SectionCard = ({
  children,
  title,
  className = "",
  error = { set: false, title: "", description: "" },
}: SectionCardProps) => (
  <Card className={className}>
    {error.set && (
      <Alert
        appearance="danger"
        title={error.title}
        description={error.description}
      />
    )}
    <div>{title}</div>
    {children}
  </Card>
);

export { Card, CTACard, SectionCard, SidebarCTACard };
