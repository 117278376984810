import moment from "moment";
import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { PAYMENT_METHOD_CODE, PAYMENT_METHOD_DETAILS } from "consts";
import { acceptPaymentOnCancelledPolicy, formatDate } from "utils";

export enum BILLING_STATES {
  cancelledPaymentDue,
  cancelled,
  paid,
  mortgageePastDue,
  mortgagee,
  directPastDue,
  easypayPastDue,
  directScheduled,
  easypayScheduled,
}

export const useBillingState = () => {
  const { userBilling, userDetails } = useContext(AuthAppContext);

  const { accounting } = userBilling ?? {};
  const { policyStateAttributes, policyStatus, currentTerm } =
    userDetails ?? {};
  const { reasonCode = { value: "" } } = policyStateAttributes ?? {};
  const { effectiveDate: renewalDate = "" } = currentTerm ?? {};

  // Get policy's billing details:
  const { paymentMethod: currentPaymentMethod } =
    PAYMENT_METHOD_DETAILS[accounting?.paymentPlan?.planType?.toLowerCase()];
  const isMortgagee = currentPaymentMethod.includes(
    PAYMENT_METHOD_CODE.INVOICE
  );
  const isDirect = currentPaymentMethod.includes(
    PAYMENT_METHOD_CODE.DIRECTBILL
  );
  const isEasypay = currentPaymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
  const isCancelled = policyStatus === "CANCELLEDPOLICY";
  const paymentDueAndCancelled = acceptPaymentOnCancelledPolicy(
    policyStatus,
    reasonCode?.value,
    renewalDate,
    accounting?.minimumPayment
  );

  // Determine payment amount, due date, and if it is overdue.
  let [noUpcomingPayment, isOverduePayment] = [false, false];

  if (accounting?.dueDate === "No Upcoming Payment") {
    noUpcomingPayment = true;
  } else {
    isOverduePayment = moment(new Date()).isAfter(
      moment(formatDate(accounting?.dueDate), "MMM DD, YYYY")
    );
  }

  if (isCancelled) {
    return paymentDueAndCancelled
      ? BILLING_STATES.cancelledPaymentDue
      : BILLING_STATES.cancelled;
  }

  let billingState;
  if (isDirect) {
    billingState = isOverduePayment
      ? BILLING_STATES.directPastDue
      : noUpcomingPayment
      ? BILLING_STATES.paid
      : BILLING_STATES.directScheduled;
  }

  if (isEasypay) {
    billingState = isOverduePayment
      ? BILLING_STATES.easypayPastDue
      : noUpcomingPayment
      ? BILLING_STATES.paid
      : BILLING_STATES.easypayScheduled;
  }

  if (isMortgagee) {
    billingState = isOverduePayment
      ? BILLING_STATES.mortgageePastDue
      : BILLING_STATES.mortgagee;
  }

  return billingState;
};
