import { useContext } from "react";

import HomeServicesAlert from "components/home-services/home-services-alert";
import { AuthAppContext } from "components/root/auth-app-provider";
import CancellationPaymentNotice from "components/shared/cancellation-payment-notice";
import {
  useShowCancellationPaymentNotice,
  useShowCoverageNotification,
  useShowHomeServicesAlert,
  useShowLeakBotBanner,
  useShowScheduledPaymentNotifications,
} from "components/shared/notifications/hooks";
import CoverageNotification from "pages/overview/extended-coverages/coverage-notification";

import LeakBotBanner from "../leakbot-banner/leakbot-banner";
import { ScheduledPaymentNotification } from "../scheduled-payment-notification";

export type NotificationName =
  | "CancellationPayment"
  | "Coverage"
  | "HomeServices"
  | "Leakbot"
  | "ScheduledPayment";

type Props = {
  availableNotifications: NotificationName[];
};

export const Notifications = ({ availableNotifications }: Props) => {
  const { userDetails } = useContext(AuthAppContext);

  const { showScheduledPaymentNotifications, scheduledPayments } =
    useShowScheduledPaymentNotifications(availableNotifications);

  const showLeakbotBanner = useShowLeakBotBanner(availableNotifications);

  const showHomeServiceAlert = useShowHomeServicesAlert(availableNotifications);
  const showCancellationPaymentNotice = useShowCancellationPaymentNotice(
    availableNotifications
  );
  const showCoverageNotification = useShowCoverageNotification(
    availableNotifications
  );

  return (
    <>
      {showHomeServiceAlert && <HomeServicesAlert />}
      {showCancellationPaymentNotice && <CancellationPaymentNotice />}
      {showScheduledPaymentNotifications && (
        <ScheduledPaymentNotification scheduledPayments={scheduledPayments} />
      )}
      {showLeakbotBanner && <LeakBotBanner userDetails={userDetails} />}
      {showCoverageNotification && <CoverageNotification />}
    </>
  );
};
