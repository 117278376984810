import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Alert } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { FaqSidebar } from "components/shared/faq-sidebar";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { useDocumentsPageDataQuery } from "gql/__generated__/hooks";
import { SelfServiceCenter } from "pages/documents/documents/self-service-center";
import { authUser, scrollToTop, trackEvent } from "utils";

import AllPolicyDocs from "./documents/all-policy-docs";
import PaperlessDocumentsEnrollWidget from "./documents/paperless-documents-enroll-widget";
import PolicyDocs from "./documents/policy-docs";

const Documents = () => {
  const user = authUser();
  const { selectedPolicyId } = useContext(AuthAppContext);

  const [searchParams] = useSearchParams();
  const documentsSigned = searchParams.get("documents_signed") === "true";

  useEffect(() => {
    if (documentsSigned) {
      trackEvent("documents_selfServiceCenter_signSuccess");
    }
  }, [documentsSigned]);

  const {
    data: documentsPageData,
    loading,
    error,
  } = useDocumentsPageDataQuery({
    variables: {
      policyID: selectedPolicyId,
      email: user?.email ?? "",
    },
  });

  useEffect(() => {
    trackEvent("Documents");
    scrollToTop();
  }, []);

  if (error) {
    return (
      <BaseLayout>
        <MainLayout sidebar={<FaqSidebar page="documents" />}>
          <FetchingError page="Documents" />
        </MainLayout>
      </BaseLayout>
    );
  }

  if (loading) {
    return <BaseLayout loading />;
  }

  const { userBilling, userDetails, userDocuments } = documentsPageData ?? {};

  const Main = () => (
    <Stack>
      {documentsSigned ? (
        <Alert
          title="Thank you!"
          description="We've successfully received your signed documents."
          appearance="success"
        />
      ) : null}
      <SelfServiceCenter />
      <PolicyDocs
        GetUserDocuments={{ userDocuments }}
        GetUserBilling={{ userBilling }}
      />
      <AllPolicyDocs
        GetUserDocuments={{ userDocuments }}
        GetUserDetails={{ userDetails }}
      />
    </Stack>
  );

  const Sidebar = () => (
    <Stack>
      <PaperlessDocumentsEnrollWidget
        GetUserDetails={{ userDetails }}
        errors={!!error}
      />
      <InsuranceRepresentative />
      <FaqSidebar page="documents" />
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={<Sidebar />}>
        <Main />
      </MainLayout>
    </BaseLayout>
  );
};

export default Documents;
