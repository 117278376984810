import classNames from "classnames";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { Button } from "@icg360/design-system";

import { HeaderLogo } from "components/shared/logo-link";
import { screenSizes } from "consts";

import MobileNavigation from "./mobile-navigation";
import MobilePolicySelector from "./mobile-policy-selector";
import Navigation from "./navigation";
import styles from "./page-header.module.scss";
import ProfileDropdown from "./profile-dropdown";

type PageHeaderProps = {
  hideNavigation?: boolean;
};

const PageHeader = ({ hideNavigation = false }: PageHeaderProps) => {
  const [mobileMenuState, setMobileMenuState] = useState<
    "closed" | "menu" | "policySelector"
  >("closed");

  const isLaptop = useMediaQuery({
    maxWidth: screenSizes.laptopL,
    minWidth: screenSizes.laptop,
  });

  return (
    <div
      className={classNames(
        styles.headerContainer,
        mobileMenuState !== "closed" && styles.headerContainerOnTop
      )}
    >
      <div className={styles.primaryHeader} data-testid="primary-header">
        <div className={styles.menuIconWrapper}>
          <Button
            appearance="neutral"
            onClick={() => setMobileMenuState("menu")}
            className={styles.hamburgerIcon}
            icon="Menu"
          />

          {mobileMenuState === "menu" ? (
            <Button
              appearance="neutral"
              className={styles.menuHeader}
              onClick={() => setMobileMenuState("closed")}
              icon="Close"
            />
          ) : null}
        </div>
        <div className={styles.logoNavWrapper}>
          <HeaderLogo logo={isLaptop ? "icon" : "default"} />
          {!hideNavigation ? (
            <div className={styles.mainNavigation}>
              <Navigation />
            </div>
          ) : null}
        </div>
        <div className={styles.profileDropdown}>
          <ProfileDropdown />
        </div>
      </div>
      {/* Catches bubbled up events from buttons, not meant to be clicked directly */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(
          styles.mobileNavigation,
          mobileMenuState === "menu" ? styles.show : ""
        )}
        onClick={() => setMobileMenuState("closed")}
      >
        <MobileNavigation
          onPolicySelectorOpen={(e) => {
            e.stopPropagation();
            setMobileMenuState("policySelector");
          }}
        />
      </div>
      {/* Catches bubbled up events from buttons, not meant to be clicked directly */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(
          styles.mobilePolicySelector,
          mobileMenuState === "policySelector" ? styles.show : ""
        )}
        onClick={() => setMobileMenuState("closed")}
      >
        <MobilePolicySelector />
      </div>
    </div>
  );
};

export default PageHeader;
