import PropTypes from "prop-types";

import {
  Alert,
  Link as DSLink,
  Heading,
  MarketingIcon,
} from "@icg360/design-system";

import { FooterBanner } from "components/shared/footer-banner";
import UnauthLayout from "components/shared/unauth-layout/index";
import { useFlags } from "utils";

import styles from "./paperless-enrollment-status.module.scss";

const PaperlessEnrollmentStatus = ({ children }) => (
  <>
    <UnauthLayout size="lg">
      <div className={styles.paperlessIcon}>
        <MarketingIcon name="Paperless" size="lg" />
      </div>
      <Heading size="lg">SageSure Paperless Enrollment</Heading>
      {children}
    </UnauthLayout>
    <FooterBanner
      bannerText="Easily manage your policy with an online SageSure account."
      bannerButtonText="Register for an Account"
      bannerLink="/register"
    />
  </>
);

PaperlessEnrollmentStatus.propTypes = {
  children: PropTypes.node,
};

const EnrollmentSuccessfulStatus = () => (
  <PaperlessEnrollmentStatus>
    <Alert
      appearance="success"
      title=""
      description="Your enrollment was successful."
    />
    <div>
      Thank you for opting to go paperless! All future policy documents will be
      sent to you via email.
    </div>
  </PaperlessEnrollmentStatus>
);

const AlreadyEnrolledStatus = () => (
  <PaperlessEnrollmentStatus>
    <Alert
      appearance="info"
      title=""
      description="Our records show that you're already enrolled in paperless."
    />
    <div>
      <DSLink href="/">Log in</DSLink> to your SageSure account to access your
      policy online.
    </div>
  </PaperlessEnrollmentStatus>
);

const EnrollmentErrorStatus = () => {
  const { supportPhoneNumber } = useFlags();
  return (
    <PaperlessEnrollmentStatus>
      <Alert
        appearance="danger"
        title=""
        description="We're unable to complete your enrollment at the moment."
      />
      <div>
        Please click the link in your email to try again. If you are still
        unable to enroll, contact our Customer Care team at{" "}
        <DSLink href="mailto:CustomerCare@SageSure.com">
          CustomerCare@SageSure.com
        </DSLink>{" "}
        or{" "}
        <DSLink href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</DSLink>
        .
      </div>
    </PaperlessEnrollmentStatus>
  );
};

export {
  EnrollmentSuccessfulStatus,
  EnrollmentErrorStatus,
  AlreadyEnrolledStatus,
  PaperlessEnrollmentStatus,
};
