import {
  type CarrierInfo,
  type ClaimConstants,
  type ClaimList,
  type DecodeData,
} from "gql/__generated__/hooks";
import { type EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import { authToken } from "./auth";
import {
  type KeystoneCarrierInfo,
  type KeystoneClaimsData,
  type KeystoneHomeServicesMessages,
  type KeystonePaymentInfo,
  type KeystonePolicyDetail,
  type KeystonePolicyInfo,
  type KeystoneUser,
  type PolicyDetail,
  carrierInfoMap,
  claimsDataMap,
  policyDetailMap,
} from "./keystone-api-mapping";

type QueryParams = Record<string, string>;

type KeystoneResult<T> = T | KeystoneError;

type KeystoneSuccess = { success: boolean };

const UNAUTH_ERR_MSG =
  "no auth token provided to a private keystone-api request";
const NO_EMAIL_ERR_MSG = "no user email provided";
const NO_POLICY_ID_ERR_MSG = "no policy ID provided";
const NO_FNOL_EVENT_ERR_MSG = "no FNOL event provided";
const NO_ZIP_CODE_ERR_MSG = "no ZIP Code provided";
const NO_DATA_ERR_MSG = "no encoded data provided";
const NO_PAYMENT_INFO_MSG = "no payment info provided";

/**
 * Keystone API
 */
export class KeystoneApiClient {
  private readonly baseUrl: string;
  private readonly token?: string;

  /**
   * Setup a keystone-api client to make direct requests to the backend.
   * - provide the `baseUrl`` for the appropriate environment, like https://stage-sagesure-svc.icg360.org/cru-4/keystone
   * - optionally, provide a `token` to avoid using Auth0, probably only useful in tests
   */
  constructor(baseUrl: string, token?: string) {
    this.baseUrl = baseUrl;
    this.token = token;
  }

  /**
   * Internal implementation for GET requests to Keystone API.
   * - endpoint is a relative path applied to the API base url
   * - if a token is provided it will be sent as an auth header
   * - an optional query string will be constructed as needed
   * - all errors are handled, api consumers shouldn't need to catch exceptions
   */
  private readonly get = async <T>(
    endpoint: string,
    token?: string,
    query?: QueryParams
  ): Promise<KeystoneResult<T>> => {
    const url = new URL(`${this.baseUrl}/${endpoint}`);
    if (query) {
      Object.keys(query).forEach((key) => {
        url.searchParams.append(key, query[key]);
      });
    }
    const headers: HeadersInit = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await fetch(url, { headers });
      return parseResponse(response);
    } catch (err) {
      return new NetworkError(err.message);
    }
  };

  /**
   * Internal implementation for POST requests to Keystone API.
   * - endpoint is a relative path applied to the API base url
   * - if a token is provided it will be sent as an auth header
   * - optional body is sent as-is
   * - all errors are handled, api consumers shouldn't need to catch exceptions
   */
  private readonly post = async <T>(
    endpoint: string | URL,
    token?: string,
    body?: BodyInit
  ): Promise<KeystoneResult<T>> => {
    const url = new URL(`${this.baseUrl}/${endpoint}`);
    const headers: HeadersInit = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    try {
      const response = await fetch(url, {
        headers,
        method: "POST",
        body,
      });
      return parseResponse(response);
    } catch (err) {
      return new NetworkError(err.message);
    }
  };

  /**
   * Token is fetched using Auth0 unless one is provided to the client.
   */
  private readonly getToken = async () => {
    if (this.token) {
      return this.token;
    }
    return authToken();
  };

  /**
   * Decode an encrypted string of user data
   */
  decodeData = async (
    dataString: string
  ): Promise<KeystoneResult<DecodeData>> => {
    if (!dataString) {
      return new MissingArgumentsError(NO_DATA_ERR_MSG);
    }
    const url = new URL(`${this.baseUrl}/api/rest/v1/data/decode`);
    return await this.post<DecodeData>(url, "", dataString);
  };

  /**
   * Fetch a keystone user by email.
   */
  getUser = async (email: string) => {
    const token = await this.getToken();
    if (!email) {
      return new MissingArgumentsError(NO_EMAIL_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    return await this.get<KeystoneUser>("api/rest/v1/user/email", token, {
      email,
    });
  };

  /**
   * Fetch a user's policy list by email.
   */
  getPoliciesList = async (email: string) => {
    const token = await this.getToken();
    if (!email) {
      return new MissingArgumentsError(NO_EMAIL_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    const encodedEmail = encodeURIComponent(email);
    return this.get<KeystonePolicyInfo[]>(
      `api/rest/v1/user/${encodedEmail}/policyList`,
      token
    );
  };

  /**
   * Fetch a policy's claim list
   */
  getClaimsList = async (
    policyId: string
  ): Promise<KeystoneResult<ClaimList>> => {
    const token = await this.getToken();
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    if (!policyId) {
      return new MissingArgumentsError(NO_POLICY_ID_ERR_MSG);
    }

    const encodedPolicyId = encodeURIComponent(policyId);
    const response = await this.get<KeystoneClaimsData>(
      `api/rest/v1/claims/${encodedPolicyId}?lossFlag=true`,
      token
    );

    if (response instanceof KeystoneError) {
      return response;
    }

    return claimsDataMap(response);
  };

  /**
   * Fetch policy details
   */
  getPolicy = async (
    policyId: string
  ): Promise<KeystoneResult<PolicyDetail>> => {
    const token = await this.getToken();
    if (!policyId) {
      return new MissingArgumentsError(NO_POLICY_ID_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    const apiRes = await this.get<KeystonePolicyDetail>(
      `api/rest/v1/policy/${policyId}`,
      token
    );
    if (apiRes instanceof KeystoneError) {
      return apiRes;
    }
    return policyDetailMap(apiRes);
  };

  /**
   * Fetch a policy's claim constants
   */
  getClaimConstants = async (
    policyId: string
  ): Promise<KeystoneResult<ClaimConstants>> => {
    const token = await this.getToken();
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    const apiRes = await this.get<ClaimConstants>(
      `api/rest/v1/claims/constants/${policyId}`,
      token
    );

    return apiRes;
  };

  /**
   * Fetch a policy's carrier info
   */
  getCarrierInfo = async (
    policyId: string
  ): Promise<KeystoneResult<CarrierInfo>> => {
    const token = await this.getToken();
    if (!policyId) {
      return new MissingArgumentsError(NO_POLICY_ID_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    const apiRes = await this.get<KeystoneCarrierInfo>(
      `api/rest/v1/carrierinfodetails/${policyId}`,
      token
    );
    if (apiRes instanceof KeystoneError) {
      return apiRes;
    }
    return carrierInfoMap(apiRes);
  };

  /**
   * Fetch legacy home services benefits.
   */
  getHomeServicesBenefits = async (
    policyId: string
  ): Promise<KeystoneResult<KeystoneHomeServicesMessages>> => {
    const token = await this.getToken();
    if (!policyId) {
      return new MissingArgumentsError(NO_POLICY_ID_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    return this.get<KeystoneHomeServicesMessages>(
      `api/rest/v1/homeservices/benefits/${policyId}`,
      token
    );
  };

  /**
   * Submit an FNOL (First Notice Of Loss) event.
   */
  fnolChannelSubmit = async (event: unknown) => {
    const token = await this.getToken();
    if (!event) {
      return new MissingArgumentsError(NO_FNOL_EVENT_ERR_MSG);
    }
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }

    return this.post("/api/rest/v1/channel", token, JSON.stringify(event));
  };

  verifyPayment = async (
    policyId: string,
    zip: string
  ): Promise<KeystoneResult<KeystonePaymentInfo>> => {
    if (!policyId) {
      return new MissingArgumentsError(NO_POLICY_ID_ERR_MSG);
    }
    if (!zip) {
      return new MissingArgumentsError(NO_ZIP_CODE_ERR_MSG);
    }
    const params = new URLSearchParams();
    params.append("policyId", policyId);
    params.append("zipcode", zip);
    return this.post<KeystonePaymentInfo>(
      `api/rest/v1/paymentinfo/verify?${params.toString()}`
    );
  };

  enrollEasypay = async (
    paymentInfo: EasyPayFormValues,
    user: { firstName: string; lastName: string; email: string }
  ): Promise<KeystoneResult<KeystoneSuccess>> => {
    const token = await this.getToken();
    if (!token) {
      return new UnauthorizedRequestError(UNAUTH_ERR_MSG);
    }
    if (!paymentInfo || !user) {
      return new MissingArgumentsError(NO_PAYMENT_INFO_MSG);
    }
    const formData = new FormData();
    formData.append("paymentFormData", JSON.stringify(paymentInfo));
    formData.append("sessionData", JSON.stringify({ user }));
    return this.post<KeystoneSuccess>(
      `api/rest/v1/easypay/enroll`,
      token,
      formData
    );
  };
}

/**
 * Use to fetch from multiple required keystone endpoints. If any of the
 * requests fail, the entire thing will return null.
 */
export async function fetchRequired<T extends unknown[]>(
  ...requests: {
    [K in keyof T]: Promise<KeystoneResult<T[K]>>;
  }
) {
  const results = await Promise.all(requests);
  if (results.some((res) => res instanceof KeystoneError)) {
    return null;
  }
  return results as T;
}

/**
 * Type guard for keystone errors. After the check comes back true, TS compiler
 * will see the value as a KeystoneError.
 */
export function isError<T>(result: KeystoneResult<T>): result is KeystoneError {
  return result instanceof KeystoneError;
}

/**
 * Used in client to parse api responses.
 */
async function parseResponse<T>(res: Response): Promise<KeystoneResult<T>> {
  const body = await res.json();
  if (!res.ok) {
    const message = body.message || res.statusText;
    if (res.status === 400) {
      return new BadRequestError(message || "bad request data");
    } else if (res.status === 404) {
      return new NotFoundError(message || "not found");
    } else if (res.status === 401 || res.status === 403) {
      return new UnauthorizedRequestError(message || "unauthorized");
    }
    return new KeystoneServerError(message || "internal server error");
  }
  return body as T;
}

/**
 * This is the base class for all the possible client errors. API consumers
 * should use the `instanceof` operator to determine an error type, like
 * `if (err instanceof BadRequestError) { ... }`
 */
class KeystoneError extends Error {
  constructor(message: string) {
    super(message);
  }
}

/**
 * Request never sent, missing required arguments.
 */
class MissingArgumentsError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "MissingArgumentsError";
    this.cause = "Missing or malformed data provided, request never sent";
  }
}

/**
 * Error was caused by an issue with the data sent to the server.
 */
class BadRequestError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "BadRequestError";
    this.cause = "Missing or malformed data sent to server";
  }
}

/**
 * Not found error, wrong location or no results for this query.
 */
class NotFoundError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
    this.cause = "Wrong location or no results found";
  }
}

/**
 * User isn't authorized to make the request.
 */
class UnauthorizedRequestError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "UnauthorizedRequestError";
    this.cause = "Missing, malformed, or expired authorization token";
  }
}

/**
 * Internal backend server error.
 */
class KeystoneServerError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "KeystoneServerError";
    this.cause = "Internal Keystone API error";
  }
}

/**
 * Problem connecting to service.
 */
class NetworkError extends KeystoneError {
  constructor(message: string) {
    super(message);
    this.name = "NetworkError";
    this.cause = "Network error contacting API server";
  }
}
