import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  Heading,
  MarketingIcon,
  Span,
} from "@icg360/design-system";

import { Container } from "components/common/container";
import { EmailIconLink, MSSLink, PhoneIconLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";

import styles from "./ineligible-offer.module.scss";

export const IneligibleOffer = () => {
  const navigate = useNavigate();
  const { policies, userInsuranceRep } = useContext(AuthAppContext);
  const hasMultiplePolicies = (policies?.length ?? 0) > 1;
  if (!userInsuranceRep) {
    return null;
  }

  const {
    agencyName,
    agentStreetNumber,
    agentStreetName,
    agentZipCode,
    agentCity,
    agentState,
    agentEmail,
    agentPhone: unFormattedPhone = "",
  } = userInsuranceRep;

  return (
    <Container>
      {!hasMultiplePolicies ? (
        <div className={styles.backButtonWrapper}>
          <Button
            leadingIcon="ArrowLeft"
            appearance="tertiary"
            onClick={() => navigate("/my/overview")}
          >
            Back to overview
          </Button>
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.headerIcon}>
            <MarketingIcon name="ExclamationPoint" />
          </div>
          <Heading size="lg">This policy is not eligible</Heading>
          <Span>
            {hasMultiplePolicies ? (
              <>
                <MSSLink to="/my/settings/perks">View other policies</MSSLink>{" "}
                and check eligibility.{" "}
              </>
            ) : (
              ""
            )}
            For more questions, contact your insurance representative.
          </Span>
          <Card className={styles.repCard}>
            <div>
              <MarketingIcon name="Talk" size="sm" />
            </div>
            <div className={styles.repCardContent}>
              <Heading size="sm">
                <div>{agencyName}</div>
                {/* agent possibly missing street address */}
                {agentStreetNumber && agentStreetName && (
                  <div>{`${agentStreetNumber} ${agentStreetName}`}</div>
                )}
                <div>{`${agentCity}, ${agentState} ${agentZipCode}`}</div>
              </Heading>
              {agentEmail ? <EmailIconLink email={agentEmail} /> : null}
              {unFormattedPhone ? (
                <PhoneIconLink phone={unFormattedPhone} />
              ) : null}
            </div>
          </Card>
        </div>
      </div>
    </Container>
  );
};
