import { expirationDate, number } from "card-validator";
import { yupToFormErrors } from "formik";
import { boolean, object, ref, string } from "yup";

import { en as locale } from "locale";

const passwordValidationRules = locale.registrationPage.passwordValidationRules;

export const passwordSchema = object().shape({
  password: string()
    .required()
    .matches(/^\S*$/, passwordValidationRules[0])
    .min(8, passwordValidationRules[4])
    .matches(/[0-9]/, passwordValidationRules[1])
    .matches(/[A-Z]/, passwordValidationRules[3])
    .matches(/[a-z]/, passwordValidationRules[2]),
  passwordConfirmation: string().oneOf(
    [ref("password"), undefined],
    passwordValidationRules[5]
  ),
});

// This may not be a great place for this...
export const validatePasswordFormik = async (values) => {
  try {
    await passwordSchema.validate(
      {
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
      },
      {
        abortEarly: false,
      }
    );
  } catch (e) {
    const errors = e.inner.map(({ message }) => message);
    return { ...yupToFormErrors(e), errors };
  }
};

export const requestResetSchema = object().shape({
  email: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
});

export const addressSchema = object().shape({
  streetLine1: string().trim().required(locale.help.streetInvalid),
  streetLine2: string(),
  city: string()
    .required(locale.help.cityInvalid)
    .trim()
    .matches(/^([a-zA-Z]|\s)+$/, locale.help.cityInvalid),
  state: string().required(locale.help.stateInvalid),
  zipFirstFive: string()
    .required(locale.formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.zipCode),
});

export const changeEmailSchema = object().shape({
  email: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.help.emailNotValid),
  emailConfirmation: string()
    .required()
    .oneOf([ref("email")], locale.help.addressesMismatch),
});

export const policyZipCodeSchema = object().shape({
  policyNumber: string()
    .required(locale.formFieldValidations.policyNumber)
    .matches(
      /^[a-zA-Z0-9]{10}([a-zA-Z0-9])?([a-zA-Z0-9])?$/,
      locale.formFieldValidations.policyNumber
    ),
  zipCode: string()
    .required(locale.formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.zipCode),
});

export const addPolicySchema = object().shape({
  policyNumber: string()
    .required(locale.formFieldValidations.twelveDigitPolicyNumber)
    .matches(
      /^[a-zA-Z0-9]{12}$/,
      locale.formFieldValidations.twelveDigitPolicyNumber
    ),
  zipCode: string()
    .required(locale.formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.zipCode),
});

export const updatePhoneNumberSchema = object().shape({
  phoneNumber: string()
    .required(locale.formFieldValidations.phoneNumber)
    .matches(
      /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      locale.formFieldValidations.phoneNumber
    ),
  confirmPhoneNumber: string()
    .required(locale.formFieldValidations.phoneNumber)
    .oneOf([ref("phoneNumber")], locale.help.phoneMismatch),
});

export const userRegistrationCeremonySchema = object().shape({
  firstName: string().required(locale.formFieldValidations.firstName).trim(),
  lastName: string().required(locale.formFieldValidations.lastName).trim(),
  email: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
  zipCode: string()
    .required(locale.formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.zipCode),
  policyNumber: string()
    .required(locale.formFieldValidations.policyNumber)
    .matches(
      /^[a-zA-Z0-9]{10}([a-zA-Z0-9])?([a-zA-Z0-9])?$/,
      locale.formFieldValidations.policyNumber
    ),
});

export const userActivationSchema = object().shape({
  firstName: string().required(locale.formFieldValidations.firstName).trim(),
  lastName: string().required(locale.formFieldValidations.lastName).trim(),
  email: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
});

export const achSchema = object().shape({
  easypayMethod: string().required(locale.formFieldValidations.required),
  bankStatementName: string().required(
    locale.formFieldValidations.bankStatementName
  ),
  financialInstitutionName: string().required(
    locale.formFieldValidations.financialInstitutionName
  ),
  accountNumber: string()
    .required(locale.formFieldValidations.accountNumber)
    .matches(/^[a-zA-Z0-9]*$/, locale.formFieldValidations.accountNumber)
    .min(6, locale.formFieldValidations.accountNumber)
    .max(17, locale.formFieldValidations.accountNumber),
  routingNumber: string()
    .matches(/^\d{9}$/, locale.formFieldValidations.routingNumber)
    .required(locale.formFieldValidations.routingNumber),
  authorize: boolean().oneOf([true], locale.formFieldValidations.required),
});

export const ccSchema = object().shape({
  cardholderName: string().required(locale.formFieldValidations.cardholderName),
  cardNumber: string()
    .required(locale.formFieldValidations.cardNumber)
    .test(
      "valid-card-number",
      locale.formFieldValidations.cardNumber,
      (value) => number(value).isValid
    ),

  cardExpirationDate: string()
    .required(locale.formFieldValidations.cardExpirationDate)
    .matches(
      /^((^0?[1-9])|(^1[0-2]))\/\d{4}$/,
      locale.formFieldValidations.cardExpirationDate
    )
    .test(
      "valid-expiration-date",
      locale.formFieldValidations.cardExpirationDate,
      (value) => {
        if (!value) return false;
        return expirationDate(value).isValid;
      }
    ),
  cardZipCode: string()
    .required(locale.formFieldValidations.cardZipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.cardZipCode),
  authorize: boolean().oneOf([true], locale.formFieldValidations.required),
});

export const emailSettingsSchema = object().shape({
  newInsuredEmail: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
  confirmInsuredEmail: string()
    .oneOf(
      [ref("newInsuredEmail"), undefined],
      locale.formFieldValidations.confirmEmail
    )
    .required(locale.formFieldValidations.emailRequired),
  applyToPaperless: boolean(),
});

export const updateRoofSchema = (initialYear) =>
  object().shape({
    constructionYearRoof: string()
      .matches(/^[0-9]{4}/, "Please enter a valid year.")
      .required("Year is required.")
      .test(
        "valid-roof-year",
        `Please enter a year later than ${initialYear}.`,
        (value) => parseInt(value, 10) > initialYear
      )
      .test(
        "valid-roof-year",
        `Can't be in the future.`,
        (value) => parseInt(value, 10) <= new Date().getFullYear()
      ),
  });

export const tempPaperlessEmailSchema = object().shape({
  emailAddress: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
});
