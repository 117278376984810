import { useNavigate } from "react-router-dom";

import { Button, Heading, MarketingIcon } from "@icg360/design-system";

import { Body } from "components/common/body";
import { CTACard } from "components/common/card";
import { en as locale } from "locale";
import { trackEvent } from "utils";

import * as styles from "./easypay-card.module.scss";

export const EasyPayCard = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    trackEvent("Easypay Enroll Banner: Clicked");
    navigate("/my/billing/update");
  };

  return (
    <CTACard
      icon={
        <div className={styles.moneyIcon}>
          <MarketingIcon name="Money" />
        </div>
      }
      title={<Heading size="md">{locale.easypayBanner.title}</Heading>}
    >
      <Body>
        <b>{locale.easypayBanner.subtitle}</b>
      </Body>
      <Body>{locale.easypayBanner.content}</Body>

      <Button
        appearance="secondary"
        size="default"
        onClick={handleClick}
        data-testid="enrollInEasyPayButton"
      >
        {locale.easypayBanner.submit}
      </Button>
    </CTACard>
  );
};

export default EasyPayCard;
