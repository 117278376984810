import { Field, useFormikContext } from "formik";

import { Button, Heading } from "@icg360/design-system";

import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";
import { useFlags } from "utils";

import styles from "./choose-day.module.scss";

type ChooseDayProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const ChooseDay = ({ setStep }: ChooseDayProps) => {
  const { easyPayFullPayOnly } = useFlags();
  const { values } = useFormikContext<EasyPayFormValues>();

  return (
    <div className={styles.cardBody}>
      <Heading size="lg">Choose a payment processing day</Heading>
      <p>
        We&apos;ll email your next payment date and amount when you finish
        signing up.
      </p>

      <Field
        id="draftDate"
        component={RadioButtonGroup}
        fieldChildren={() => (
          <>
            <Field
              name="draftDate"
              id="1stMonth"
              label="1st of the month"
              component={RadioButtonField}
              checked={values.draftDate === "1stMonth"}
              className={styles.radio}
            />
            <Field
              name="draftDate"
              id="8thMonth"
              label="8th of the month"
              component={RadioButtonField}
              checked={values.draftDate === "8thMonth"}
              className={styles.radio}
            />
            <Field
              name="draftDate"
              id="15thMonth"
              label="15th of the month"
              component={RadioButtonField}
              checked={values.draftDate === "15thMonth"}
              className={styles.radio}
            />
            <Field
              name="draftDate"
              id="22ndMonth"
              label="22nd of the month"
              component={RadioButtonField}
              checked={values.draftDate === "22ndMonth"}
              className={styles.radio}
            />
          </>
        )}
      />

      <div className={styles.navigation}>
        <Button
          appearance="tertiary"
          onClick={() => setStep(easyPayFullPayOnly ? "welcome" : "choosePlan")}
          data-testid="easypay-day-back-btn"
        >
          Go back
        </Button>
        <Button
          onClick={() => setStep("chooseMethod")}
          data-testid="easypay-day-continue-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
