import { Field, Form, Formik, useFormikContext } from "formik";
import { ReactNode, useContext, useEffect, useState } from "react";

import { Button, Card, Heading, addToast } from "@icg360/design-system";

import { Body } from "components/common/body";
import { AuthAppContext } from "components/root/auth-app-provider";
import {
  CheckboxField,
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { useSendPropertyProfileUpdateNotificationMutation } from "gql/__generated__/hooks";
import { en as locale } from "locale";
import { logError, scrollToTop, trackEvent } from "utils";

import { ReviewTrampoline } from "./review-trampoline";
import styles from "./update-trampoline.module.scss";

export type TrampolineFormValues = {
  trampoline: null | boolean;
  trampolineShockAbsorbing: null | boolean;
  iAgreeCheckbox: boolean;
};

const initialValues: TrampolineFormValues = {
  trampoline: null,
  trampolineShockAbsorbing: null,
  iAgreeCheckbox: false,
};

type UpdateTrampolineProps = {
  onClose: () => void;
  onUpdateSuccess: () => void;
};

const UpdateTrampoline = ({
  onClose,
  onUpdateSuccess,
}: UpdateTrampolineProps) => {
  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();

  const handleSubmit = async (
    { trampoline, trampolineShockAbsorbing },
    { setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      trackEvent("Property trampoline - Updates submitted", {
        trampoline,
        trampolineShockAbsorbing,
      });

      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates: {
            trampoline,
            trampolineHasShockAbsorbingPadding: trampolineShockAbsorbing,
          },
        },
        context: {
          clientName: "keystone-api",
        },
      });
      if (data) {
        trackEvent("Property trampoline - Update completed");
        onUpdateSuccess();
        onClose();
        addToast(
          "We have received your update! Once validated, you will see these changes reflected here on your property profile.",
          { icon: true, duration: 10000 }
        );
      }
    } catch (error) {
      logError(`Update trampoline: ${error.message}`);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property trampoline - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <UpdateTrampolineForm onClose={onClose} />
    </Formik>
  );
};

type UpdateTrampolineFormProps = {
  onClose: () => void;
};

const UpdateTrampolineForm = ({ onClose }: UpdateTrampolineFormProps) => {
  const [review, setReview] = useState(false);
  const [hasSelectedTrampoline, setHasSelectedTrampoline] = useState(false);
  const [error, setError] = useState<string | null>();

  const { values, status, setFieldValue } =
    useFormikContext<TrampolineFormValues>();
  const { trampoline, trampolineShockAbsorbing, iAgreeCheckbox } = values;

  let progressValue = 50;
  let sideHeadline = locale.updateTrampoline.supportHeadline;
  let sideBody: ReactNode = locale.updateTrampoline.supportBody;

  useEffect(() => {
    if (status?.type === "error") {
      setError(locale.propertyProfile.error);
    }
  }, [status]);

  useEffect(() => {
    setError(null);
    scrollToTop();
    if (review) {
      trackEvent("Trampoline review page load", {
        trampoline,
        trampolineShockAbsorbing,
      });
    }
  }, [review, trampolineShockAbsorbing, trampoline]);

  const setNextSteps = () => {
    if (!trampoline) {
      trackEvent("Property Trampoline - Sign attestation page load", {
        trampoline,
      });
    } else if (trampoline) {
      trackEvent(
        "Property Trampoline - Edit shock-absorbing padding page load",
        { trampoline, trampolineShockAbsorbing }
      );
    }

    setHasSelectedTrampoline(true);
  };

  let main = (
    <Card className={styles.cardContainer}>
      <Heading size="lg">{locale.updateTrampoline.doYouHaveTrampoline}</Heading>
      <br />
      <Form>
        <Field
          name="trampoline"
          component={RadioButtonGroup}
          className={styles.radioGroup}
          fieldChildren={() => (
            <>
              <Field
                name="trampoline"
                id="trampolineYes"
                label={locale.updateTrampoline.yes}
                className={styles.radio}
                onChange={() => setFieldValue("trampoline", true)}
                component={RadioButtonField}
                checked={trampoline}
              />
              <Field
                name="trampoline"
                id="trampolineNo"
                label={locale.updateTrampoline.no}
                className={styles.radio}
                onChange={() => {
                  setFieldValue("trampoline", false);
                  setFieldValue("trampolineShockAbsorbing", null);
                }}
                component={RadioButtonField}
                checked={trampoline === null ? false : !trampoline}
              />
            </>
          )}
        />
      </Form>
      <div className={styles.navigation}>
        <Button appearance="tertiary" onClick={onClose}>
          {locale.easypayEnroll.goBack}
        </Button>
        <Button
          appearance="primary"
          size="default"
          onClick={() => setNextSteps()}
          disabled={trampoline === null}
          data-testid="trampoline-yes-no-continue-btn"
        >
          {locale.easypayEnroll.continue}
        </Button>
      </div>
    </Card>
  );

  if (hasSelectedTrampoline) {
    progressValue = 75;
    if (!trampoline) {
      main = (
        <Card className={styles.cardContainer}>
          <Heading size="lg">{locale.updateTrampoline.pleaseConfirm}</Heading>
          <br />
          <Body>{locale.updateTrampoline.confirmation}</Body>
          <Form>
            <br />
            <Field
              name="iAgreeCheckbox"
              label={locale.updateTrampoline.iAgree}
              component={CheckboxField}
            />
          </Form>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onClick={() => setHasSelectedTrampoline(false)}
              data-testid="no-trampoline-go-back-btn"
            >
              {locale.easypayEnroll.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              onClick={() => setReview(true)}
              disabled={!iAgreeCheckbox}
              data-testid="no-trampoline-continue-btn"
            >
              {locale.easypayEnroll.continue}
            </Button>
          </div>
        </Card>
      );
    } else {
      sideHeadline = locale.updateTrampoline.safetyHeadline;
      sideBody = locale.updateTrampoline.safetyBody;
      main = (
        <Card className={styles.cardContainer}>
          <Heading size="lg">
            {locale.updateTrampoline.doYouHavePadding}
          </Heading>
          <br />
          <Form>
            <Field
              name="trampolinePadding"
              component={RadioButtonGroup}
              className={styles.radioGroup}
              fieldChildren={() => (
                <>
                  <Field
                    name="trampolinePadding"
                    id="trampolinePaddingYes"
                    label={locale.updateTrampoline.yes}
                    className={styles.radio}
                    onChange={() =>
                      setFieldValue("trampolineShockAbsorbing", true)
                    }
                    component={RadioButtonField}
                    checked={trampolineShockAbsorbing}
                  />
                  <Field
                    name="trampolinePadding"
                    id="trampolinePaddingNo"
                    label={locale.updateTrampoline.no}
                    className={styles.radio}
                    onChange={() =>
                      setFieldValue("trampolineShockAbsorbing", false)
                    }
                    component={RadioButtonField}
                    checked={
                      trampolineShockAbsorbing !== null &&
                      !trampolineShockAbsorbing
                    }
                  />
                </>
              )}
            />
          </Form>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onClick={() => setHasSelectedTrampoline(false)}
            >
              {locale.easypayEnroll.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              onClick={() => setReview(true)}
              disabled={trampolineShockAbsorbing === null}
              data-testid="trampoline-padding-continue-btn"
            >
              {locale.easypayEnroll.continue}
            </Button>
          </div>
        </Card>
      );
    }
  }

  if (review) {
    main = <ReviewTrampoline setReview={setReview} error={error} />;
    progressValue = 90;
    sideHeadline = locale.updateTrampoline.reviewHeadline;
    sideBody = locale.updateTrampoline.reviewBody;
  }

  return (
    <>
      <ProgressBar progress={progressValue} />
      <MainLayout
        sidebar={
          !trampoline && hasSelectedTrampoline && !review ? null : (
            <PropertyProfileSidebar
              headline={sideHeadline}
              body={<p>{sideBody}</p>}
              iconName="Question"
            />
          )
        }
      >
        {main}
      </MainLayout>
    </>
  );
};

export default UpdateTrampoline;
