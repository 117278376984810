import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./form-field-error.module.scss";

export const FormFieldError = ({ children, className }) => (
  <span
    data-testid="field-error"
    className={cx(styles.formFieldError, className)}
  >
    {children}
  </span>
);

FormFieldError.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
