import { Spinner } from "@icg360/design-system";

import styles from "./fullpage-spinner.module.scss";

const FullpageSpinner = () => {
  return (
    <div className={styles.wrapper}>
      <Spinner />
    </div>
  );
};

export default FullpageSpinner;
