import { useEffect, useState } from "react";
import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { CONFIG } from "config";
import { authHeaders, handleUnauthorized, logError, useFlags } from "utils";

const ELIGIBLE_STATUSES = ["SENT", "OFFERED", "ENROLLED", "ELIGIBLE"];

export const useEligibleOffers = (keystonePolicyId: string) => {
  const { universalLogin, shingleResealOffer } = useFlags();
  const [eligibleOffers, setEligibleOffers] = useState<
    KeystoneOfferEligibility[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!keystonePolicyId) {
      return;
    }
    const implementedOfferingNames = ["ting-offer"];
    if (shingleResealOffer) {
      implementedOfferingNames.push("shingle-opportunity");
    }

    (async () => {
      setLoading(true);
      try {
        const endpoint = `${CONFIG.KEYSTONE_PROXY_HREF}/api/offers/${keystonePolicyId}`;
        const headers = universalLogin ? await authHeaders() : {};
        const res = await fetch(endpoint, {
          headers,
          credentials: universalLogin ? "same-origin" : "include",
        });
        if (res.ok) {
          const offers = await res.json();
          setEligibleOffers(
            offers.filter(
              (offer) =>
                ELIGIBLE_STATUSES.includes(offer.offerStatusCode) &&
                implementedOfferingNames.includes(offer.offeringInternalName)
            )
          );
        } else {
          if ([401, 403].includes(res.status)) {
            await handleUnauthorized({ endpoint });
          }
        }
      } catch (err) {
        // swallow the error, we just won't show any offers
        logError(`settings offers: ${err.message}`);
      }
      setLoading(false);
    })();
  }, [universalLogin, keystonePolicyId, shingleResealOffer]);

  return { eligibleOffers, loading };
};
