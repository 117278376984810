import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./inline.module.scss";

const Inline = ({ children, gap = "md", className = "", ...props }) => {
  return (
    <div className={cx(styles.inline, className)} {...props}>
      {children.map(
        (child, i) =>
          child && (
            <div
              key={i}
              className={cx(styles.inlineItem, {
                [styles.gapXs]: gap === "xs",
                [styles.gapSm]: gap === "sm",
                [styles.gapMd]: gap === "md",
                [styles.gapLg]: gap === "lg",
              })}
            >
              {child}
            </div>
          )
      )}
    </div>
  );
};

Inline.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  gap: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  className: PropTypes.string,
};

export default Inline;
