import { useContext } from "react";
import { Container } from "react-bootstrap";

import { Alert } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { HomeServicesContext } from "components/home-services/home-services-provider";
import { en as locale } from "locale";
import { trackEvent } from "utils";

import { ALL_WRITE_OUT_MSG, RENEWAL_WRITE_OUT_MSG } from "./constants";
import styles from "./home-services.module.scss";

const HomeServicesAlert = () => {
  const { homeServicesMessages } = useContext(HomeServicesContext);
  if (!homeServicesMessages?.length) {
    return null;
  }
  const message = homeServicesMessages.find((msg) =>
    [RENEWAL_WRITE_OUT_MSG, ALL_WRITE_OUT_MSG].includes(msg.type)
  );

  if (!message) {
    return null;
  }

  return (
    <Container className={styles.alertContainer}>
      <Alert
        appearance="danger"
        title={locale.inspectionWriteOut.alert.title}
        description={
          <>
            Please make these{" "}
            <MSSLink
              onClick={() => trackEvent("Inspection Write-Out - Alert Click")}
              to={`/my/property/services/${message?.id}`}
              style={{ color: "black" }}
            >
              repairs to your property
            </MSSLink>{" "}
            to renew your policy.
          </>
        }
      />
    </Container>
  );
};

export default HomeServicesAlert;
