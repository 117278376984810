import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useMultiStepFormState<T>(context) {
  type ContextType = {
    state: T;
    setState: Dispatch<SetStateAction<T>>;
  };

  const navigate = useNavigate();
  const { state, setState } = useContext<ContextType>(context);

  const { state: locationState } = useLocation();

  useEffect(() => {
    if (!state) {
      setState({ ...locationState });
    }
  }, [locationState, setState, state]);

  const setFields = useCallback(
    (newFields) => {
      setState((prevState) => {
        return { ...prevState, ...newFields };
      });
    },
    [setState]
  );

  const formNavigate = useCallback(
    (path) => {
      navigate(path, { state });
    },
    [navigate, state]
  );

  const setFieldsAndNavigate = useCallback(
    (path, newFields) => {
      setState((prevState) => {
        return { ...prevState, ...newFields };
      });
      navigate(path, { state: { ...state, ...newFields } });
    },
    [navigate, setState, state]
  );

  return {
    state: state ?? locationState,
    setState,
    setFields,
    setFieldsAndNavigate,
    formNavigate,
  };
}
