import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useMatch } from "react-router-dom";

import { screenSizes } from "consts";
import { setCookie, trackEvent } from "utils";

const BASE_PATH = "/my/settings";
export const SECURITY_PATH = `${BASE_PATH}/security`;
export const POLICIES_PATH = `${BASE_PATH}/policies`;
export const CONTACT_PATH = `${BASE_PATH}/contact`;
export const PAPERLESS_PATH = `${BASE_PATH}/paperless`;
export const PAYMENT_PATH = `${BASE_PATH}/payment`;
export const HOMESERVICES_PATH = `${BASE_PATH}/home-services`;
export const OFFERS_PATH = `${BASE_PATH}/perks`;

export const useSettingsTab = () => {
  const isMobile = useMediaQuery({ maxWidth: screenSizes.laptop });
  const isRoot = !!useMatch(BASE_PATH);
  const location = useLocation();
  let section = location.pathname.split("/")[3];
  if (!section) {
    // When viewing on desktop policies is displayed as default section
    section = isMobile ? "" : "policies";
  }

  useEffect(() => {
    // Disable automatic modals from showing in edge case of user switching
    // policies via the settings page.
    setCookie("mss-easypay-modal-dismissed", true);
    setCookie("mss-paperless-modal-dismissed", true);
  }, []);

  useEffect(() => {
    trackEvent("Settings page loaded", {
      settingsTabType: section,
    });
  }, [section]);

  return { isMobile, isRoot, section };
};
