import { Link as DSLink, Modal } from "@icg360/design-system";

import { en as locale } from "locale";
import { trackEvent } from "utils";

import styles from "./contact-us-modal.module.scss";

type ContactUsModalProps = {
  show: boolean;
  handleClose: () => void;
};

export const ContactUsModal = ({ show, handleClose }: ContactUsModalProps) => {
  return (
    <Modal open={show} onClose={handleClose} noFooter heading="Contact Us">
      <div className={styles.modalContent}>
        <div>
          <div className={styles.modalBold}>
            {locale.loginPage.contactUsModal.questions}
          </div>
          <div>{locale.loginPage.contactUsModal.contactRep}</div>
        </div>
        <div className={styles.modalBold}>
          {locale.loginPage.contactUsModal.otherQuestions}
          <DSLink
            href={locale.loginPage.contactUsModal.phoneLink}
            onClick={() => trackEvent("landingV2_contact_phoneNumberClicked")}
          >
            {locale.loginPage.contactUsModal.phone}
          </DSLink>
        </div>
        <div>
          <div className={styles.modalBold}>
            {locale.loginPage.contactUsModal.businessHoursHeading}
          </div>
          <div>{locale.loginPage.contactUsModal.weekdays}</div>
          <div>{locale.loginPage.contactUsModal.weekend}</div>
        </div>
      </div>
    </Modal>
  );
};
