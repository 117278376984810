import { ZoneContextManager } from "@opentelemetry/context-zone";
import {
  CompositePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { B3InjectEncoding, B3Propagator } from "@opentelemetry/propagator-b3";
import {
  SimpleSpanProcessor,
  WebTracerProvider,
} from "@opentelemetry/sdk-trace-web";

import { CONFIG } from "config";

/**
 * Setup OpenTelemetry tracing.
 * - @opentelemetry/instrumentation-document-load
 * - @opentelemetry/instrumentation-fetch
 * - @opentelemetry/instrumentation-xml-http-request
 *
 * Initialize tracing instrumentations functionally due to an issue with the ZoneContextManager
 * in unit tests. The ZoneContextManager is used to track span context across async operations.
 * Even when initialization is wrapped in a conditional block, the declaration is read by JS/Node
 * and processed which results in numerous test failures. The workaround is to separate the
 * tracing provider from the initialization and functionally pass in the trace provider to
 * register its instrumentations.
 *
 * Possibly related to https://github.com/open-telemetry/opentelemetry-js/issues/3030
 */
const initializeTracing = (traceProvider: WebTracerProvider) => {
  // Skip tracing if no collector is defined
  if (CONFIG.OTEL_COLLECTOR) {
    traceProvider.addSpanProcessor(
      new SimpleSpanProcessor(
        new OTLPTraceExporter({
          url: CONFIG.OTEL_COLLECTOR,
        })
      )
    );

    traceProvider.register({
      contextManager: new ZoneContextManager(),
      propagator: new CompositePropagator({
        propagators: [
          new W3CTraceContextPropagator(),
          new B3Propagator({ injectEncoding: B3InjectEncoding.MULTI_HEADER }),
        ],
      }),
    });

    const matchSageSureSubdomainsOrLocal =
      /^https?:\/\/(.*?\.(icg360\.(org|net|com)|sagesure\.(net|com))|localhost:\d{4})(\/.*)?/;
    const matchAllExceptSageSureOrLocal =
      /^https?:\/\/(?!(.*?\.(icg360\.(org|net|com)|sagesure\.(net|com))|localhost:\d{4}))(\/.*)?/;

    registerInstrumentations({
      instrumentations: [
        new FetchInstrumentation({
          propagateTraceHeaderCorsUrls: [matchSageSureSubdomainsOrLocal],
          ignoreUrls: [matchAllExceptSageSureOrLocal],
        }),
        new XMLHttpRequestInstrumentation({
          propagateTraceHeaderCorsUrls: [matchSageSureSubdomainsOrLocal],
          ignoreUrls: [matchAllExceptSageSureOrLocal],
        }),
      ],
    });
  }
};

export { initializeTracing };
