import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLoginUserMutation } from "gql/__generated__/hooks";
import {
  getQueryParameter,
  identifyUserFS,
  identifyUserMixpanel,
  logError,
  trackEvent,
} from "utils";

import { removeAllCookies } from "./cookies";

export const useLoginRedirect = () => {
  const navigate = useNavigate();

  return useCallback(
    async (loginUserResponse) => {
      const { data, redirect } = loginUserResponse;

      identifyUserMixpanel(data);
      trackEvent("User Logged In");
      identifyUserFS(data);

      removeAllCookies();

      const targetUrl = redirect || getQueryParameter("to") || "/my/overview";
      navigate(targetUrl);
    },
    [navigate]
  );
};

export const useLogin = (onErrorMsg) => {
  const loginRedirectUser = useLoginRedirect();

  const [loginUser] = useLoginUserMutation();
  const [submitting, setSubmitting] = useState(false);

  const handleLogin = async ({ username, password }) => {
    trackEvent("Login Button Clicked", { loginDetail: username });
    setSubmitting(true);

    try {
      const { data } = await loginUser({ variables: { username, password } });
      const { loginUser: loginUserResponse } = data ?? {};
      const { success, errors, errorCode } = loginUserResponse ?? {};

      if (errors) {
        setSubmitting(false);
        onErrorMsg(errors);
        trackEvent("Error Displayed (Login)", {
          errorCode,
          errorMessage: errors[0],
        });
      } else if (success) {
        loginRedirectUser(loginUserResponse);
        trackEvent("Login Completed");
      }
    } catch (err) {
      logError(`Login: ${err.message}`);
      const errorMessage =
        "We're having technical difficulties. Please try again later.";
      setSubmitting(false);
      onErrorMsg([errorMessage]);
      trackEvent("Error Displayed (Login)", {
        errorCode: 500,
        errorMessage,
      });
    }
  };

  return { handleLogin, submitting };
};
