import cx from "classnames";
import PropTypes from "prop-types";

import { Heading } from "@icg360/design-system";

import styles from "./table.module.scss";

export const Table = ({ children, className, ...props }) => {
  return (
    <table className={cx(styles.table, className)} {...props}>
      {children}
    </table>
  );
};

export const TableRow = ({ children, className, ...props }) => {
  return (
    <tr className={className} {...props}>
      {children}
    </tr>
  );
};

export const TableCell = ({ children, className, align, ...props }) => (
  <td className={cx(className, styles[align])} {...props}>
    {children}
  </td>
);

export const TableHeader = ({ children, className, align, ...props }) => (
  <th className={cx(className, styles[align])} {...props}>
    <Heading size="xs">{children}</Heading>
  </th>
);

export const TableHead = ({ children, className, ...props }) => (
  <thead className={className} {...props}>
    {children}
  </thead>
);

export const TableBody = ({ children, className, ...props }) => (
  <tbody className={className} {...props}>
    {children}
  </tbody>
);

const commonPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

Table.propTypes = commonPropTypes;
TableRow.propTypes = commonPropTypes;
TableCell.propTypes = {
  ...commonPropTypes,
  align: PropTypes.oneOf(["left", "right", "center"]),
};
TableHeader.propTypes = {
  ...commonPropTypes,
  align: PropTypes.oneOf(["left", "right", "center"]),
};
TableHead.propTypes = commonPropTypes;
TableBody.propTypes = commonPropTypes;
