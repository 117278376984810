import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./radio-button.module.scss";

export const RadioButton = ({
  name,
  children,
  className,
  value,
  checked,
  onChange,
}) => (
  <div className={classnames(styles.radioBtnContainer, className)}>
    <div className={classnames(styles.radioInput, checked && styles.checked)}>
      <input
        name={name}
        id={value}
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {children}
    </div>
  </div>
);

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
