import * as styles from "./shingle-repair-pilot.module.scss";

const WaiverContent = () => {
  return (
    <div className={styles.waiverContainer}>
      <div className={styles.waiverContentWrapper}>
        <p>
          The Participant has volunteered to participate in one of SageSure’s
          research pilot programs (herein referred to as the “Program”). Under
          the terms of the Program, SageSure Insurance Managers LLC (“SageSure”)
          has entered into an agreement with a third party (the “Company”) that
          has contracted with numerous home professionals (each, a “Pro”) to
          seal/reseal roof shingles on residences situated in certain
          hurricane-prone areas (the “Services”). The Company will make
          available potential Pros that Participant can enlist for the Services,
          but neither SageSure nor the Company is making any representations or
          warranties to the Participant regarding the Pros, their capabilities,
          background or experience. The Participant will contract directly with
          the pro and the terms of that contract (the “Pro Contract”) will
          govern for all matters relating to the Services.
        </p>
        <p>
          In consideration for Participant’s desire to participate in the
          Program, the Participant, for himself/herself/itself and for
          his/her/its heirs, personal representatives, executors, administrators
          and assigns, agrees as follows:
        </p>
        <p>
          <b>Assumption of Risk:</b> The Participant hereby understands,
          acknowledges, and consents to participate voluntarily in the Program.
          Participant, knowingly and voluntarily, accepts, and assumes
          responsibility for any and all danger, and risks, whether known or
          unknown, that could arise out of, or occur during, Participant’s
          participation in the Program even if such risks are due to the
          negligence of SageSure or the Company and/or their respective agents,
          contractors, volunteers or employees. Participant authorizes SageSure
          and the Company, and their respective agents, contractors, volunteers,
          or employees to perform the services necessary for the Program, as
          well as authorizes and consents to the capturing and retention of any
          pictures and videos necessary for the Program. Participant
          acknowledges that it may receive benefits through the voluntary
          Program, including but not limited to roof clearing and sealant
          applied to shingles. Participant agrees that there is no guarantee of
          receiving any such benefits through the voluntary Program. Participant
          further acknowledges and agrees that any services provided by SageSure
          and/or the Company through the Program are provided on an “AS IS”
          basis.
        </p>
        <p>
          {`THE PROGRAM AND ANY RELATED SERVICES PROVIDED BY SAGESURE AND THE
          COMPANY ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT
          WARRANTY OF ANY KIND, WHETHER WRITTEN OR ORAL, EXPRESS OR IMPLIED.
          SAGESURE AND COMPANY EACH DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING
          BUT NOT LIMITED TO ALL WARRANTIES OF TITLE, NON-INFRINGEMENT,
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER SAGESURE
          NOR COMPANY REPRESENT OR WARRANT THAT THE SERVICES PROVIDED THROUGH
          THE PROGRAM WILL MEET PARTICIPANTS NEEDS OR REQUIREMENTS IN ANY WAY,
          THAT THE SERVICES WILL BE ACCURATE OR RELIABLE, THAT THE SERVICES WILL
          BE SECURE FROM ERROR, OR THAT DEFECTS IN THE SERVICES WILL BE
          CORRECTED. ANY ISSUES RELATED TO THE SERVICES PROVIDED BY THE PRO THAT
          WAS SELECTED BY THE PARTICIPANT SHALL BE GOVERNED BY THE TERMS OF THE
          PRO CONTRACT.`}
        </p>
        <p>
          <b>Release and Waiver.</b> The Participant hereby releases, waives,
          discharges, and covenants not to sue SageSure, the Company, and each
          of their respective affiliates, administrators, directors, agents,
          coaches and other employees, other participants, volunteers, and,
          owners, operators and lessors of premises used to conduct the Program
          (collectively, the “Releases”), from and for any liability resulting
          from participating in the Program including, without limitation any
          personal injury, accident or illness (including death), and/or
          property loss or damage, however caused, even if caused by the
          negligence of SageSure or the Company, and/or their respective agents,
          contractors, volunteers or employees, arising from, or in any way
          related to, Participant’s participation in the Program. Participant
          understands that their participation in the Program is strictly
          voluntary and a personal decision. Participant is aware that their
          participation is not a requirement of any duties or responsibilities
          as a customer or producer of SageSure.
        </p>
        <p>
          <b>Confidentiality.</b> The Participant shall not, directly or
          indirectly, disclose any confidential information regarding the
          Program to third parties without SageSure’s prior written consent,
          which consent may be withheld at SageSure’s discretion.
        </p>
        <p>
          <b>Indemnification and Hold Harmless.</b> The Participant hereby
          agrees to indemnify, defend and hold the Releasees harmless from any
          and all claims, actions, suits, procedures, costs, expenses, damages
          and liabilities including, but not limited to, attorney’s fees,
          arising from, or in any way related to, the Participant’s
          participation in the Program.
        </p>
        <p>
          <b>Severability.</b> The Participant expressly agrees that the
          foregoing assumption of risk, release and waiver of liability,
          indemnity and consent agreement is intended to be as broad and
          inclusive as is permitted by the law of the State of Florida and that
          if any portion thereof is held invalid, it is agreed that the balance
          shall, notwithstanding, continue in full legal force and effect.
        </p>
        <p>
          Acknowledgment of Understanding. The Participant has read this
          assumption of risk, release and waiver of liability, indemnity and
          consent agreement, and has had the opportunity to ask questions about
          the same. The Participant fully understands this assumption of risk,
          release and waiver of liability and indemnity agreement, that the
          Participant is giving up substantial rights in connection therewith,
          and that its terms are contractual, and not a mere recital. The
          Participant acknowledges that they are agreeing to this agreement
          freely and voluntarily and that no oral representations, statements,
          or inducements, apart from the foregoing written agreement, have been
          made.
        </p>
        <p>
          <b>Governing Law.</b> This Program shall be governed by the laws of
          the State of Florida without regard for its conflicts of laws
          principles. The Participant agrees to submit to the jurisdiction of
          the federal and state courts located in the state in which the subject
          residence is located.
        </p>
        <p>
          <b>Miscellaneous.</b> This waiver and release constitutes the entire
          agreement between the Participant and SageSure, and Participant and
          the Company, with respect to the Program and supersedes any prior
          contemporaneous representations, written or oral, with regard to the
          subject matter hereof.
        </p>
      </div>
    </div>
  );
};

export default WaiverContent;
