import { useState } from "react";

import { Button, MarketingIcon } from "@icg360/design-system";

import { PaperlessModal } from "components/paperless-modal";
import { CommonPolicyDataQuery } from "gql/__generated__/hooks";

import styles from "./enroll-paperless.module.scss";

type EnrollPaperlessProps = {
  userDetails: CommonPolicyDataQuery["userDetails"];
  userBilling: CommonPolicyDataQuery["userBilling"];
  isEnrolledDocuments: boolean;
  isEnrolledBilling: boolean;
  policyId: string;
  refetch: () => void;
};

const EnrollPaperless = ({
  isEnrolledDocuments,
  isEnrolledBilling,
  userDetails,
  userBilling,
  policyId,
  refetch,
}: EnrollPaperlessProps) => {
  const [showPaperlessModal, setShowPaperlessModal] = useState(false);

  const { insuredPreferences } = userDetails ?? {};

  return (
    <>
      {(!isEnrolledDocuments || !isEnrolledBilling) && (
        <Button
          appearance="secondary"
          onClick={() => setShowPaperlessModal(true)}
          data-testid="enroll-paperless-btn"
        >
          <span className={styles.paperlessIcon}>
            <MarketingIcon name="Paperless" size="sm" />
          </span>
          Enroll in paperless
        </Button>
      )}
      {(!isEnrolledBilling || !isEnrolledDocuments) && (
        <PaperlessModal
          show={showPaperlessModal}
          onClose={() => setShowPaperlessModal(false)}
          documentsEmailAddress={
            insuredPreferences?.opInsuredEmailAddressDocuments ||
            insuredPreferences?.opInsuredEmailAddress
          }
          userDetails={userDetails}
          userBilling={userBilling}
          policyId={policyId}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default EnrollPaperless;
