import classNames from "classnames";
import get from "lodash.get";
import PropTypes from "prop-types";
import { useRef } from "react";
import { FormGroup } from "react-bootstrap";

import { FormFieldError } from "components/common/form-field-error";
import { RadioButtonLabel } from "components/common/form-label";
import { FormLegend } from "components/common/form-legend";
import { RadioButton } from "components/common/radio-button";
import { formFieldProps, formProps } from "consts";
import { scrollToError } from "utils";

import styles from "./radio-button-field.module.scss";

export const RadioButtonField = ({
  field: { name, onChange, onBlur, isSubmitting },
  form: { errors },
  id,
  label,
  className,
  selected,
  ...props
}) => {
  const errorsField = get(errors, name);

  const inputRef = useRef(null);
  scrollToError(name, inputRef, errorsField, isSubmitting, errors);
  return (
    <div className={classNames(styles.inlineBtnContainer, className)}>
      <RadioButton
        name={name}
        id={id}
        value={id}
        checked={id === selected}
        onChange={(e) => {
          onChange(e);
          if (props.onChange) props.onChange(e);
        }}
        onBlur={onBlur}
        {...props}
      >
        <RadioButtonLabel htmlFor={id}>{label}</RadioButtonLabel>
      </RadioButton>
    </div>
  );
};

RadioButtonField.propTypes = {
  field: PropTypes.shape(formFieldProps),
  form: PropTypes.shape(formProps),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

export const RadioButtonGroup = ({
  error,
  touched,
  title,
  className,
  ...props
}) => (
  <FormGroup className={className}>
    <fieldset>
      <FormLegend className={styles.formLegend}>{title}</FormLegend>
      <props.fieldChildren />
      {touched && <FormFieldError>{error}</FormFieldError>}
    </fieldset>
  </FormGroup>
);

RadioButtonGroup.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  fieldChildren: PropTypes.any.isRequired,
};
