import React, { useContext } from "react";

import { Span } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { getMortgageeClass } from "utils";

import styles from "./payment-plan-sidebar.module.scss";

const PaymentPlanMortgagee = () => {
  const { userBilling } = useContext(AuthAppContext);
  const { mortgagees, billingAddress } = userBilling ?? {};

  return (
    <div className={styles.contentWrapper}>
      {mortgagees?.length ? (
        mortgagees.map(
          ({ name, loanNumber, city, state, zip, street1, street2 }, depth) => {
            return (
              <React.Fragment key={depth}>
                <div>
                  <div>{`${getMortgageeClass(depth)} mortgagee`}</div>
                  <Span bold>
                    {name}
                    <br />
                    {`${street1}${street2 ? `, ${street2}` : ""}`}
                    <br />
                    {`${city}, ${state} ${zip}`}
                  </Span>
                </div>

                <div>
                  <div>Loan number</div>
                  <Span bold>{loanNumber}</Span>
                </div>
              </React.Fragment>
            );
          }
        )
      ) : (
        <div> No Mortgagee Associated with this Policy </div>
      )}
      <div>
        <div>Billing address</div>
        <Span bold>
          {billingAddress?.line1}
          <br />
          {billingAddress?.line2}
          <br />
          {billingAddress?.line3}
        </Span>
      </div>
    </div>
  );
};

export default PaymentPlanMortgagee;
