import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

import { setMixpanelSuperProperties, trackEvent } from "utils";

import CeremonyLinkForm from "./ceremony-link-form";

const schema = object().shape({
  selectedEmail: string().required("Please select an email for linking."),
});

type CeremonyLinkProps = {
  emailList: string[];
  policyNumber: string;
  setSelectedEmail: (string) => void;
};

export const CeremonyLink: React.FC<CeremonyLinkProps> = ({
  emailList,
  policyNumber,
  setSelectedEmail,
}) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    selectedEmail: emailList.length > 0 ? emailList[0] : "",
  });

  useEffect(() => {
    setInitialValues({
      selectedEmail: emailList.length > 0 ? emailList[0] : "",
    });
  }, [emailList]);

  const handleSubmit = ({ selectedEmail }, { setSubmitting }) => {
    setMixpanelSuperProperties({
      accountEmail: selectedEmail,
    });
    trackEvent("GEICO: Link Account Button Clicked");
    setSelectedEmail(selectedEmail);
    navigate("/ceremony/login");
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize={true}
    >
      <CeremonyLinkForm emailList={emailList} policyNumber={policyNumber} />
    </Formik>
  );
};
