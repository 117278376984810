import { useContext } from "react";

import { Card, Heading } from "@icg360/design-system";

import { EmailIconLink, PhoneIconLink } from "components/common/link";
import LiveChatButton from "components/live-chat-button";
import { GlobalContext } from "components/root/global-provider";
import { useFlags } from "utils";

import styles from "./support-sidebar.module.scss";

const availableHours = (
  <div className={styles.smallText}>
    <div>Monday - Friday: 8am - 9pm EST</div>
    <div>Saturday: 10am - 3pm EST</div>
  </div>
);

export const SupportSidebar = () => {
  const { liveChat, supportPhoneNumber } = useFlags();
  const { liveChatStatus } = useContext(GlobalContext);

  return (
    <Card>
      <div className={styles.cardContent}>
        <Heading size="md">SageSure customer care</Heading>
        Customer care can assist with any questions about billing or your
        SageSure account.
        <div>
          <div className={styles.contactHeader}>
            <EmailIconLink email="customercare@sagesure.com" />
          </div>
          <span className={styles.smallText}>
            {"We'll reply within 48 hours."}
          </span>
        </div>
        <div>
          <div className={styles.contactHeader}>
            <PhoneIconLink phone={supportPhoneNumber} />
          </div>
          {availableHours}
        </div>
        {liveChat && liveChatStatus !== "uninitialized" ? (
          <div>
            <div className={styles.noIcon}>
              <LiveChatButton location="SupportSidebar" />
            </div>
            {availableHours}
          </div>
        ) : null}
      </div>
    </Card>
  );
};
