import { Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { MSSLink } from "components/common/link";

import styles from "./page-error.module.scss";

const PageError = () => {
  return (
    <div className={styles.pageError}>
      <Heading align="center" size="lg">
        Internal Server Error.
      </Heading>
      <Body align="center">
        There was an error loading the data for this page. Please{" "}
        <MSSLink to="/my/support">contact support</MSSLink> if the problem
        persists.
      </Body>
    </div>
  );
};

export default PageError;
