import { useCallback, useContext, useEffect, useState } from "react";

import { GlobalContext } from "components/root/global-provider";
import { chatSettings } from "consts";
import { UserPolicyIDsQuery } from "gql/__generated__/hooks";
import { trackEvent, useFlags } from "utils";

// This is a guess...
type ChatBus = {
  publish?: (message: string, options: Record<string, string>) => void;
};

type LiveChatArgs = {
  emailAddress?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  selectedPolicy?: string | null;
  userPolicies?: UserPolicyIDsQuery["userPolicies"];
};

type InitializationStatus = "uninitialized" | "initializing" | "initialized";

export const useLiveChat = ({
  emailAddress,
  firstName,
  lastName,
  selectedPolicy,
  userPolicies,
}: LiveChatArgs): void => {
  const { liveChat } = useFlags();
  const [chatBus, setChatBus] = useState<ChatBus>({});
  const [initStatus, setInitStatus] =
    useState<InitializationStatus>("uninitialized");

  const loadLiveChat = useCallback((userInfo) => {
    setInitStatus("initializing");
    //Set chat config on window before loading script so that it's defined for IIFE in chat.js
    window.__8x8Chat = {
      ...chatSettings,
      onInit: (bus) => {
        bus.publish("customer:set-info", {
          ...userInfo,
          PolicyLink: `${chatSettings.ipcManager}/${userInfo.ClaimPolicyNumber}`,
          Source: "MySageSure",
        });
        setChatBus(bus);
        setInitStatus("initialized");
      },
    };

    const script = document.createElement("script");

    script.src = `${chatSettings.domain}${chatSettings.path}/CHAT/common/js/chat.js`;
    script.async = true;
    script.type = "text/javascript";

    document.body.appendChild(script);
  }, []);

  const updateLiveChatUserInfo = useCallback(
    (userInfo) => {
      chatBus.publish?.("customer:set-info", {
        ...userInfo,
        PolicyLink: `${chatSettings.ipcManager}/${userInfo.ClaimPolicyNumber}`,
      });
    },
    [chatBus]
  );

  useEffect(() => {
    if (
      !liveChat ||
      !emailAddress ||
      !firstName ||
      !lastName ||
      !userPolicies
    ) {
      return;
    }

    const userInfo = {
      FirstName: firstName,
      LastName: lastName,
      EmailAddress: emailAddress,
      EmailAddressID: btoa(emailAddress),
      ClaimPolicyNumber: selectedPolicy,
      PolicyList: userPolicies.map((p) => p.policyId).join(", "),
    };

    if (initStatus === "uninitialized") {
      loadLiveChat(userInfo);
    } else if (initStatus === "initialized") {
      updateLiveChatUserInfo(userInfo);
    } else {
      // Ignore while initializing, it'll get updated when it's done initializing
    }
  }, [
    emailAddress,
    firstName,
    initStatus,
    lastName,
    liveChat,
    loadLiveChat,
    selectedPolicy,
    updateLiveChatUserInfo,
    userPolicies,
  ]);
};

export const useTriggerLiveChat = (location: string) => {
  const { liveChatStatus } = useContext(GlobalContext);

  return useCallback(() => {
    trackEvent(`Chat Button Clicked`, {
      time: liveChatStatus,
      location,
    });
    //When platform is in maintenance, 8x8 redirects to a maintenance domain
    const useMaintenanceIframe = document.querySelector(
      `iframe[src^="${chatSettings.maintenanceDomain}"]`
    ) as HTMLIFrameElement;

    const [iframe, origin] = useMaintenanceIframe
      ? [useMaintenanceIframe, chatSettings.maintenanceDomain]
      : [
          document.querySelector(
            `iframe[src^="${chatSettings.domain}"]`
          ) as HTMLIFrameElement,
          chatSettings.domain,
        ];

    if (iframe) {
      iframe.contentWindow?.postMessage(
        JSON.stringify({
          topic: "button:click",
          data: {
            asPopout: false,
          },
        }),
        origin
      );
    }
  }, [liveChatStatus, location]);
};
