import { useContext, useEffect } from "react";

import { BaseLayout } from "components/base-layout";
import { Stack } from "components/common/stack";
import { AuthAppContext } from "components/root/auth-app-provider";
import { InsuranceRepresentative } from "components/shared/insurance-representative";
import { MainLayout } from "components/shared/main-layout";
import { SupportSidebar } from "components/shared/support-sidebar";
import { useGetEligibleCoveragesQuery } from "gql/__generated__/hooks";
import { useFaqs } from "hooks/use-faqs";
import { getQueryParameter, logError } from "utils";

import FAQList from "./faq-list";

const Support = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);

  const {
    data: eligibleCoveragesData,
    loading: eligibleCoveragesLoading,
    error: eligibleCoveragesError,
  } = useGetEligibleCoveragesQuery({
    variables: {
      policyID: selectedPolicyId,
    },
    context: {
      clientName: "keystone-api",
    },
  });

  const openQuestion = getQueryParameter("question");
  const displayedFAQs = useFaqs({
    noUpsell:
      eligibleCoveragesData?.getEligibleCoverages?.coverages?.length === 0,
  });

  // We're purposely logging and ignoring errors here.
  // We just hide the one faq dependent on it
  useEffect(() => {
    if (eligibleCoveragesError) {
      logError(
        `Use get eligible coverages: (${eligibleCoveragesError.name}: ${eligibleCoveragesError.message})`
      );
    }
  }, [eligibleCoveragesError]);

  const Sidebar = () => (
    <Stack>
      <SupportSidebar />
      <InsuranceRepresentative />
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={["HomeServices", "CancellationPayment"]}
    >
      <MainLayout sidebar={<Sidebar />} mainLoading={eligibleCoveragesLoading}>
        <Stack>
          <FAQList displayedFAQs={displayedFAQs} openQuestion={openQuestion} />
        </Stack>
      </MainLayout>
    </BaseLayout>
  );
};

export default Support;
