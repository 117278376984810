import { Field, Form, withFormik } from "formik";
import PropTypes from "prop-types";
import { object, string } from "yup";

import { Alert, Button, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { MSSLink } from "components/common/link";
import { InputField } from "components/shared/form-fields";
import { CONFIG } from "config";
import { en as locale } from "locale";
import { logError, setMixpanelSuperProperties, trackEvent } from "utils";

import styles from "./ceremony-login-form.module.scss";

const CeremonyLoginForm = ({ dirty, isValid, status, values: { email } }) => {
  return (
    <div className={styles.formWrapper}>
      <Form>
        <Heading size="md" className={styles.formTitle}>
          {locale.ceremony.login.title}
        </Heading>
        <Body>{locale.ceremony.login.description}</Body>
        {status && (
          <div className={styles.alerts}>
            {status?.type === "error" ? (
              <Alert
                appearance="danger"
                title=""
                description={status.message}
              />
            ) : (
              <Alert
                appearance="success"
                title=""
                description={status.message}
              />
            )}
          </div>
        )}
        <Field
          name={"email"}
          title="Email Address"
          component={InputField}
          placeholder={locale.form.placeholder.emailAddress}
        />
        <Field
          type="password"
          name="password"
          title="Password"
          component={InputField}
          placeholder={locale.form.placeholder.password}
        />
        <Body className={styles.forgotPasswordLink} align="right">
          <MSSLink
            to="/request-reset"
            onClick={() => {
              setMixpanelSuperProperties({
                accountEmail: email,
              });
              trackEvent("GEICO: Forget Password Clicked");
            }}
          >
            {locale.loginPage.forgotPasswordLink}
          </MSSLink>
        </Body>
        <Button
          type="submit"
          id="loginBtn"
          disabled={!isValid || !dirty}
          data-testid="ceremony-login-btn"
          fluid
        >
          {locale.loginPage.loginButton}
        </Button>
      </Form>
    </div>
  );
};

CeremonyLoginForm.propTypes = {
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  errors: PropTypes.any,
  status: PropTypes.string,
  values: PropTypes.shape({
    email: PropTypes.string,
  }),
};

const schema = object().shape({
  email: string().required("Email required.").email("Enter a valid email."),
  password: string().required("No password provided."),
});

export default withFormik({
  mapPropsToValues: ({ selectedEmail }) => ({
    email: selectedEmail,
    password: "",
  }),
  validationSchema: schema,
  handleSubmit: async (
    { email, password },
    { setSubmitting, setStatus, props: { uuid, policyNumber } }
  ) => {
    try {
      const res = await fetch(`${CONFIG.KEYSTONE_PROXY_HREF}/api/saml-bridge`, {
        body: JSON.stringify({
          policyNumber,
          email,
          password,
          uuid,
          origin: "link",
        }),
        headers: {
          "content-type": "application/json",
          // "Access-Control-Expose-Headers": "Location"
        },
        method: "POST",
        redirect: "follow",
        credentials: "include",
      });

      if (res.status >= 400) {
        trackEvent("GEICO: Error Displayed (login)", {
          errorCode: res.status,
          errorMessage: locale.registrationPage.redirectError,
        });
        throw new Error(locale.registrationPage.redirectError);
      } else {
        setStatus({ type: "success", message: "Successfully logged in!" });

        trackEvent("GEICO SSO: Log In", {
          "Destination URL": body,
        });

        const body = await res.text();
        window.location = body;
      }

      setSubmitting(false);
    } catch (err) {
      logError(`Ceremony login form: (${err.name}: ${err.message})`);
      setStatus({ type: "error", message: err.message });
      setSubmitting(false);
    }
  },
})(CeremonyLoginForm);
