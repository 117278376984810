import PropTypes from "prop-types";
import React, { createContext, useEffect, useReducer, useState } from "react";

import { AlertProps } from "@icg360/design-system";

import { CLAIMS_SUPPORT, GENERAL_SUPPORT, extendedCoveragesCopy } from "consts";
import { OptionalCoveragesQuery } from "gql/__generated__/hooks";
import { trackEvent } from "utils";

type CoveragePageState = {
  policyId: string;
  open: boolean;
  scroll_to?: number;
  type?: AlertProps["appearance"];
  title?: string;
  description?: string;
  y?: number;
};

const initialCoveragePageState: CoveragePageState = {
  policyId: "",
  open: false,
};

function makeCoveragesString(arr) {
  if (arr.length === 1) return arr[0];
  return (
    arr.slice(0, arr.length - 1).join(", ") + " and " + arr[arr.length - 1]
  );
}

export const CoverageContext = createContext<{
  coveragePage: CoveragePageState;
  setCoveragePage: React.Dispatch<CoveragePageState>;
}>({
  coveragePage: initialCoveragePageState,
  setCoveragePage: () => null,
});

export function CoverageContextProvider({ children }) {
  const coverageReducer = (state, action): CoveragePageState => {
    switch (action.type) {
      case "close":
        return {
          open: false,
          scroll_to: action.y,
          policyId: "",
        };

      case "danger":
        return {
          type: "danger",
          open: false,
          policyId: action.policyId,
          title: "Oops, something went wrong!",
          description: `We were not able to complete your purchase. Please try again or contact customer service at ${GENERAL_SUPPORT}.`,
        };

      case "open":
        return {
          policyId: action.policyId,
          open: true,
          y: action.y,
        };

      case "success":
        return {
          type: "success",
          open: false,
          policyId: action.policyId,
          title: "Your coverage has been updated!",
          description:
            "The extended coverages you selected have been added to your policy.",
        };

      case "warning": {
        const { failed } = action;
        const failed_names = failed.map(
          (coverage) => extendedCoveragesCopy[coverage].short
        );
        const not_covered = `We were not able to complete your purchase of the following coverages: ${makeCoveragesString(
          failed_names
        )}.`;

        return {
          type: "warning",
          open: false,
          policyId: action.policyId,
          title: "Some coverages not added!",
          description: `${
            failed.length > 0 && not_covered
          } Please try again or contact customer service at ${CLAIMS_SUPPORT}`,
        };
      }
      default:
        return initialCoveragePageState;
    }
  };

  const [coveragePage, setCoveragePage] = useReducer(
    coverageReducer,
    initialCoveragePageState
  );

  const value = { coveragePage, setCoveragePage };

  return (
    <CoverageContext.Provider value={value}>
      {children}
    </CoverageContext.Provider>
  );
}

export const useCoverages = (coverages) => {
  const [coveragesToShow, setCoveragesToShow] = useState<
    OptionalCoveragesQuery["getEligibleCoverages"]["coverages"]
  >([]);

  useEffect(() => {
    const availableCoverages = (coverages ?? []).filter(
      (coverage) => coverage.eligibility
    );

    setCoveragesToShow(availableCoverages);

    if (availableCoverages.length) {
      trackEvent("Additional Coverage Displayed", {
        listOfCoverages: availableCoverages.map((c) => c.name),
      });
    }
  }, [coverages]);

  return coveragesToShow;
};

CoverageContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
