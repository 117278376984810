import cx from "classnames";
import { Image } from "react-bootstrap";

import { MSSLink } from "components/common/link";
import SagesureLogoIcon from "images/sagesure-logo-icon.svg";
import SagesureLogo from "images/sagesure-logo.svg";
import { trackEvent } from "utils";

import styles from "./logo-link.module.scss";

type LogoImageLinkProps = {
  className?: string;
  onClick?: () => null;
};

const LogoImageLink = ({ className, onClick }: LogoImageLinkProps) => {
  return (
    <MSSLink
      to="/"
      className={cx(styles.logoLink, className)}
      onClick={onClick}
    >
      <Image
        src={"/assets/images/sagesure-logo.svg"}
        width="222px"
        height="54px"
        alt="SageSure logo"
      />
    </MSSLink>
  );
};

const LOGO_MAP = {
  default: <SagesureLogo width="116" />,
  mobile: <SagesureLogo width="70" />,
  icon: <SagesureLogoIcon width="33" />,
};

type HeaderLogoProps = {
  logo?: keyof typeof LOGO_MAP;
};

const HeaderLogo = ({ logo = "default" }: HeaderLogoProps) => {
  return (
    <MSSLink
      to="/"
      className={cx(styles.logoLink, styles.headerLogo)}
      onClick={() => trackEvent("Home")}
      title="SageSure Logo"
    >
      {LOGO_MAP[logo]}
    </MSSLink>
  );
};

export { HeaderLogo, LogoImageLink };
