import {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  shape,
  string,
} from "prop-types";

export const userBillingProp = {
  userBilling: shape({
    accounting: shape({
      minimumPayment: string.isRequired,
      totalBalance: string.isRequired,
      invoiceAmountCurrent: string.isRequired,
      paymentPlan: shape({ planType: string.isRequired }),
      easyPayAccount: shape({ lastFourDigits: string, paymentMethod: string }),
    }),
    mortgagees: array.isRequired,
  }),
};

export const latestDocProp = {
  route: string.isRequired,
  label: string.isRequired,
  group: string.isRequired,
};

export const userDocumentsProp = {
  userDocuments: shape({
    grouped: array.isRequired,
    latest: shape({
      declaration: shape(latestDocProp),
      invoice: shape(latestDocProp),
    }),
  }),
};

export const agentAddressProp = {
  agencyName: string,
  agentStreetNumber: string,
  agentStreetName: string,
  agentCity: string,
  agentState: string,
  agentZipCode: string,
  agentPhone: string,
  agentEmail: string,
};

export const userIdentificationProp = {
  GetUserIdentification: shape({
    userIdentification: shape({
      city: string.isRequired,
      email: string.isRequired,
      firstName: string.isRequired,
      id: string.isRequired,
      lastName: string.isRequired,
      policyNum: string.isRequired,
      policyStatus: string.isRequired,
      productType: string.isRequired,
      state: string.isRequired,
      zip: string.isRequired,
    }),
    loading: bool.isRequired,
  }),
};

export const userClaimsListProp = {
  userClaimsList: shape({
    PolicyNumber: string.isRequired,
    InsuredName: string,
    ClaimsInfo: arrayOf(
      shape({
        ClaimNumber: string.isRequired,
        DateOfLoss: string.isRequired,
        Status: string.isRequired,
        LossCauseDescription: string.isRequired,
        ClaimDescription: string.isRequired,
        IndependentAdjusterName: string.isRequired,
        IndependentAdjusterPhoneNumber: string.isRequired,
        payments: arrayOf(
          shape({
            paymentAmount: string.isRequired,
            paymentDate: string.isRequired,
          })
        ),
      })
    ),
    SubmissionsInfo: arrayOf(
      shape({
        DateOfLoss: string.isRequired,
        LossAtInsuredProperty: bool.isRequired,
        LocationOfLoss: shape({
          Address: string,
          AddressLine2: string,
          City: string,
          State: string,
          Zip: string,
        }),
        TypeOfLoss: string.isRequired,
        LossDescription: string.isRequired,
        ClaimSubmittedByPolicyholder: bool.isRequired,
        ClaimSubmittedBy: shape({
          Name: string,
          Relationship: string,
          PrimaryPhoneNumber: string,
          PrimaryPhoneNumberType: string,
          AlternatePhoneNumber: string,
          AlternatePhoneNumberType: string,
        }),
        ContactInfo: shape({
          Name: string.isRequired,
          Relationship: string.isRequired,
          Email: string.isRequired,
          PrimaryPhoneNumber: string.isRequired,
          PrimaryPhoneNumberType: string.isRequired,
          AlternatePhoneNumber: string.isRequired,
          AlternatePhoneNumberType: string.isRequired,
          WantsToReceiveSMSUpdates: bool.isRequired,
        }),
      })
    ),
  }),
};

export const userClaimsConstantsProp = {
  userClaimsList: shape({
    LossTypes: arrayOf(string),
    RelationshipToPolicyHolderTypes: arrayOf(string),
    RelationshipToCommercialPolicyHolderTypes: arrayOf(string),
    PrimaryPhoneTypes: arrayOf(string),
    SecondaryPhoneTypes: arrayOf(string),
  }),
};

export const userCarrierInfoProp = {
  userCarrierInfo: shape({
    id: string,
    name: string,
    nameShort: string,
    phone: string,
    fax: string,
    hours: string,
    website: string,
    logo: string,
    email: string,
  }),
};

export const userInsuranceRepProp = {
  userInsuranceRep: shape(agentAddressProp),
};

export const userPropertyProfileDataProp = {
  userPropertyProfileData: shape({
    burglarAlarm: string,
    constructionYearRoof: number,
    fireAlarm: string,
    poolFence: string,
    poolType: string,
  }),
};

export const userAdditionalCoveragesProp = {
  userAdditionalCoverages: shape({
    identityFraudCoverage: string,
    identityFraudCoveragePremium: string,
    identityFraudIncreaseLimit: string,
    identityFraudTotalLimit: string,
    mechanicalBreakdownCoverage: string,
    mechanicalBreakdownCoveragePremium: string,
    mechanicalElectricalEquipmentGroundLevel: string,
    serviceLineCoverage: string,
    serviceLineDeductible: string,
    serviceLinePremium: string,
  }),
};

export const addressProp = {
  street1: string.isRequired,
  street2: string,
  city: string.isRequired,
  state: string.isRequired,
  zip: string.isRequired,
};

export const insuredPreferences = {
  easyPayEnrollmentStatus: string,
};

export const userPolicyPropertiesProp = {
  userPolicyProperties: shape({
    carrierGroup: string.isRequired,
    policyType: string.isRequired,
    agencyLocationCode: string.isRequired,
    policyState: string.isRequired,
  }),
};

export const paperlessEnrollmentStatusProp = {
  paperlessEnrollmentStatus: string,
  loading: bool.isRequired,
};

export const userBillingAddressProp = {
  userBillingAddress: string,
  loading: bool.isRequired,
};

export const userDetailsProp = {
  userDetails: shape({
    currentTerm: shape({
      policyNumber: string.isRequired,
      effectiveDate: string.isRequired,
      effectiveDatePolicyTerm: string.isRequired,
      expirationDate: string.isRequired,
      totalPremium: string.isRequired,
      coverageA: string,
      coverageB: string,
      coverageC: string,
      coverageD: string,
      coverageE: string,
      coverageEPerOccurrence: string,
      coverageEAggregate: string,
      coverageF: string,
      hurricaneDeductibleAmount: string,
      allOtherPerilsDeductible: string,
      dbaName: string,
      windHailDeductible: string,
      propertyDamageLiabilityDeductiblePerClaim: string,
      propertyDamageLiabilityDeductiblePerOccurrence: string,
      termLocationData: array.isRequired,
    }),
    propertyAddress: shape(addressProp),
    mailingAddress: shape(addressProp),
    additionalAddresses: arrayOf(shape(addressProp)),
    policyStatus: string.isRequired,
    insuredPreferences: shape(insuredPreferences),
    isCommercial: bool.isRequired,
    isBookRoll: bool.isRequired,
  }),
};

export const userInfoProp = {
  userInfo: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
    email: string.isRequired,
  }),
};

export const paymentMethodInfoProp = {
  serviceFeesDisplay: string,
  otherPaymentsDisplay: string,
  paymentFrequency: string,
  firstPaymentDisplay: string,
  paymentFrequencyLabel: string,
  paymentMethodLabel: string,
  updatingExistingEnrollment: bool,
};

export const userPolicyIDProp = {
  policyId: string.isRequired,
  property: shape(addressProp),
  isCommercial: bool.isRequired,
  policyState: string.isRequired,
};

export const userPolicyIDsProp = {
  userPolicyIDs: arrayOf(shape(userPolicyIDProp)),
};

export const transactionProp = {
  description: string,
  amount: string,
  date: string,
};

export const userTransactionsProp = {
  userTransactions: shape({ transactions: arrayOf(shape(transactionProp)) }),
};

export const mortgageeProp = {
  name: string,
  loanNumber: string,
  street1: string,
  street2: string,
  city: string,
  state: string,
  zip: string,
  index: number,
};

export const policyPaymentPlanProp = {
  planId: string,
  downPayment: string,
  installmentAmount: string,
  paymentPlanSetupFee: string,
  installmentCharge: string,
  numberOfPayments: string,
  billingMethod: string,
};

export const policyPaymentPlansProp = {
  policyPaymentPlans: arrayOf(shape(policyPaymentPlanProp)),
  loading: bool.isRequired,
};

export const serviceFeesProp = {
  serviceFees: string,
  loading: bool.isRequired,
};

export const formikProp = {
  handleChange: func.isRequired,
  values: any.isRequired,
  dirty: bool.isRequired,
  isValid: bool.isRequired,
  setFieldValue: func.isRequired,
};

export const formFieldProps = {
  name: string,
  value: oneOfType([string, bool, object]),
  onChange: func,
  onBlur: func,
};

export const formProps = {
  touched: object,
  errors: object,
};

export const coverageProps = shape({
  name: string,
  protectionValue: number,
  proratedPrice: string,
  premiumNew: string,
  price: string,
  eligibility: bool,
  startDate: string,
});
