import { CommonPolicyDataQuery } from "gql/__generated__/hooks";
import { formatAddress, formatPhone } from "utils";

export const existingPhoneNumber = (phoneNumber?: string) =>
  phoneNumber?.replace(/\D/g, "") ?? "";

export const formatLeakBotData = (
  userDetails: CommonPolicyDataQuery["userDetails"]
) => {
  return {
    name: userDetails?.primaryInsured?.name?.toUpperCase(),
    address: userDetails?.propertyAddress
      ? formatAddress(userDetails.propertyAddress)
      : "",
  };
};

export const formatLeakBotPayload = ({ policyId, userInfo, userDetails }) => {
  const { firstName, lastName } = userInfo ?? {};
  const { street1, street2, city, state, zip } =
    userDetails?.propertyAddress ?? {};

  return {
    policyId,
    firstName,
    lastName,
    address1: street1,
    address2: street2,
    city,
    state,
    postalCode: zip,
    countryCode: "US",
    email: "",
    phone: "",
  };
};

export const formatPhoneNumber = (phone) => {
  return formatPhone(phone.replace(/\D/g, ""));
};

export const isValidEmail = (str) => {
  if (!str) return false;
  const xp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return xp.test(String(str).toLowerCase());
};

export const isValidPhone = (str) => {
  if (!str) return false;
  const xp = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  return xp.test(str);
};

export const isValidZip = (str) => {
  if (!str) return false;
  const VALID_ZIP_LENGTHS = [4, 5];
  const onlyDigits = /^\d+$/;
  return onlyDigits.test(str) && VALID_ZIP_LENGTHS.includes(str.length);
};

export const maskPolicyNumber = (str, digits) => {
  return "*".repeat(Math.min(digits, str.length)) + str.slice(digits);
};
