import { useContext, useEffect, useState } from "react";

import { PAYMENT_METHOD_CODE } from "consts";
import { ReviewQuery } from "gql/__generated__/hooks";
import type { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";
import { UpdatePaymentContext } from "pages/payment-plan-update/update-payment-context";
import { getFrequencyName, getPaymentInfoDisplay } from "utils";

type SelectedPaymentPlanParams = {
  currentPaymentMethod: EasyPayFormValues["paymentMethod"];
  paymentFrequency: EasyPayFormValues["paymentFrequency"];
  policyPaymentPlans?: ReviewQuery["policyPaymentPlans"];
  easypayProcessingFees?: ReviewQuery["easypayProcessingFees"];
  updatingExistingEnrollment: boolean;
  loading: boolean;
};

export const useSelectedPaymentPlan = ({
  currentPaymentMethod,
  paymentFrequency,
  policyPaymentPlans = [],
  easypayProcessingFees = "0",
  updatingExistingEnrollment,
  loading,
}: SelectedPaymentPlanParams) => {
  const { dispatch } = useContext(UpdatePaymentContext);
  const [paymentPlanValues, setPaymentPlanValues] = useState({
    otherPayments: "",
    paymentMethodDisplay: "",
    paymentFrequencyDisplay: "",
    firstPaymentDisplay: "",
    otherPaymentsDisplay: "",
    serviceFeesDisplay: "",
  });

  const [editing, setEditing] = useState("");

  useEffect(() => {
    if (loading) {
      return;
    }
    //Find payment plan that matches form selection
    const {
      downPayment,
      installmentAmount,
      paymentPlanSetupFee,
      numberOfPayments,
      installmentCharge,
    } =
      policyPaymentPlans.find(
        (policyPaymentPlan) =>
          getFrequencyName(policyPaymentPlan?.numberOfPayments) ===
            paymentFrequency &&
          policyPaymentPlan?.billingMethod?.toLowerCase() ===
            currentPaymentMethod
      ) ?? {};

    //Calculate service fees
    let serviceFees =
      Number(paymentPlanSetupFee) +
      Number(installmentCharge) * Number(numberOfPayments);

    if (
      easypayProcessingFees &&
      currentPaymentMethod === PAYMENT_METHOD_CODE.EASYPAYCC
    ) {
      serviceFees += Number(easypayProcessingFees);
    }

    //Get formatted display values
    const {
      paymentMethodDisplay,
      paymentFrequencyDisplay,
      firstPaymentDisplay,
      otherPaymentsDisplay,
      serviceFeesDisplay,
    } = getPaymentInfoDisplay({
      paymentMethod: currentPaymentMethod,
      paymentFrequency,
      firstPayment: downPayment,
      otherPayments: installmentAmount,
      serviceFees,
    });

    //Update state with computed values
    setPaymentPlanValues({
      otherPayments: installmentAmount ?? "",
      paymentMethodDisplay,
      paymentFrequencyDisplay,
      firstPaymentDisplay,
      otherPaymentsDisplay,
      serviceFeesDisplay,
    });
  }, [
    currentPaymentMethod,
    easypayProcessingFees,
    loading,
    paymentFrequency,
    policyPaymentPlans,
  ]);

  const handlePaymentPlanSubmit = () => {
    const { paymentFrequencyDisplay, paymentMethodDisplay, ...rest } =
      paymentPlanValues;
    dispatch({
      type: "payment-method-update",
      payload: {
        paymentMethodSaved: false,
        paymentMethodInfo: {
          paymentFrequency,
          paymentFrequencyLabel: paymentFrequencyDisplay,
          paymentMethodLabel: paymentMethodDisplay,
          updatingExistingEnrollment,
          ...rest,
        },
      },
    });
  };

  return [{ editing, handlePaymentPlanSubmit, setEditing }];
};
