import { Link as DSLink, UiIcon } from "@icg360/design-system";

import styles from "./mss-link.module.scss";

type PhoneIconLinkProps = {
  phone: string;
};

export const PhoneIconLink = ({ phone }: PhoneIconLinkProps) => (
  <div className={styles.iconLink}>
    <div className={styles.linkIcon}>
      <UiIcon name="Phone" />
    </div>
    <DSLink href={`tel:${phone.replace(/\D/g, "")}`}>{phone}</DSLink>
  </div>
);
