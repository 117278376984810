import { useMutation } from "@apollo/client";
import DOMPurify from "dompurify";
import { Formik } from "formik";
import { object, string } from "yup";

import { Alert, Link as DSLink, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { MSSLink } from "components/common/link";
import { FormFooterLinks } from "components/shared/form-footer-links";
import { CONFIG } from "config";
import { HTTP_BAD_REQUEST, validatePasswordFormik } from "consts";
import { registerUserMutation } from "gql/mutations";
import { en as locale } from "locale";
import { formatError, logError, trackEvent } from "utils";

import { CeremonyRegistrationForm } from "./ceremony-registration-form";
import styles from "./ceremony-registration.module.scss";

const registrationSchema = object().shape({
  policyNumber: string()
    .required(locale.formFieldValidations.policyNumber)
    .matches(
      /^[a-zA-Z0-9]{10}([a-zA-Z0-9])?([a-zA-Z0-9])?$/,
      locale.formFieldValidations.policyNumber
    ),
  zipCode: string()
    .required(locale.formFieldValidations.zipCode)
    .matches(/^[0-9]{5}(-[0-9]{4})?$/, locale.formFieldValidations.zipCode),
  firstName: string().required(locale.formFieldValidations.firstName),
  lastName: string().required(locale.formFieldValidations.lastName),
  email: string()
    .required(locale.formFieldValidations.emailRequired)
    .email(locale.formFieldValidations.emailInvalid),
});

const initialValues = {
  policyNumber: "",
  zipCode: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirmation: "",
};

export type CeremonyRegistrationSchema = typeof initialValues;

type CeremonyRegistrationProps = {
  firstName: string;
  status: { type: string; message: string };
  uuid?: string;
};

export const CeremonyRegistration = ({
  firstName: userFirstName,
  status,
  uuid,
}: CeremonyRegistrationProps) => {
  const [registerUser] = useMutation(registerUserMutation);

  const handleSubmit = async (
    { policyNumber, firstName, lastName, zipCode, email, password },
    { setSubmitting, setStatus }
  ) => {
    try {
      setSubmitting(true);
      setStatus(null);
      const {
        data: { registerUser: registered },
      } = await registerUser({
        variables: {
          userInfo: {
            emailVerified: false,
            policyList: policyNumber,
            email,
            firstName,
            lastName,
            password,
            zipCode,
          },
        },
      });

      if (!registered || !registered.success) {
        trackEvent("Error Displayed (Geico Registration)", {
          accountEmail: email,
          errorCode: registered.errorCode,
          errorMessage: registered.errors[0],
          policyNumber,
          zipCode,
        });
        setStatus({
          type: "error",
          message: registered.errors
            ? registered.errors.join(" ")
            : "There was an error while attempting to create this account",
        });
        setSubmitting(false);
        return;
      }

      const samlBridgeResponse = await fetch(
        `${CONFIG.KEYSTONE_PROXY_HREF}/api/saml-bridge`,
        {
          body: JSON.stringify({
            policyNumber,
            email,
            password,
            uuid,
            origin: "register",
          }),
          headers: { "content-type": "application/json" },
          method: "POST",
          redirect: "follow",
          credentials: "include",
        }
      );

      if (samlBridgeResponse.status >= HTTP_BAD_REQUEST) {
        trackEvent("Error Displayed (Geico Registration)", {
          accountEmail: email,
          errorCode: samlBridgeResponse.status,
          errorMessage: `${samlBridgeResponse.statusText} – type:${
            samlBridgeResponse.type || ""
          }`,
          policyNumber,
          zipCode,
        });
        throw new Error(locale.registrationPage.redirectError);
      } else {
        setStatus({
          type: "success",
          message: locale.registrationPage.accountSuccessfullyRegistered,
        });
        const body = await samlBridgeResponse.text();
        trackEvent("GEICO: Registration", {
          "Destination URL": body,
        });
        window.location.assign(body);
      }

      setSubmitting(false);
    } catch (err) {
      logError(`Ceremony registration: (${err.name}: ${err.message})`);
      setStatus({ type: "error", message: err.message });
      setSubmitting(false);
    }
  };

  const alertHandlers = status && (
    <div className={styles.alerts}>
      {status.type === "error" ? (
        <Alert
          appearance="danger"
          title=""
          description={DOMPurify.sanitize(formatError(status.message))}
        />
      ) : (
        <Alert appearance="success" title="" description={status.message} />
      )}
    </div>
  );

  return (
    <div className={styles.ceremonyRegister}>
      <Heading size="md" className={styles.formHeadline}>
        {locale.registrationPage.geicoRegister}
        {userFirstName ? `, ${userFirstName}` : ""}!
      </Heading>

      <Body className={styles.infoHeadline}>
        {locale.registrationPage.registerDescription}
      </Body>

      <Heading size="xs" className={styles.capitalizedHeader}>
        {locale.registrationPage.choosePolicy}
      </Heading>

      {alertHandlers}

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={registrationSchema}
        validate={validatePasswordFormik}
      >
        <CeremonyRegistrationForm />
      </Formik>

      <FormFooterLinks>
        <Body small>{locale.registrationPage.signup(DSLink)}</Body>
        <Body small>{locale.registrationPage.login("/", MSSLink)}</Body>
      </FormFooterLinks>
    </div>
  );
};
