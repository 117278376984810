import { useEffect } from "react";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Link as DSLink,
  Heading,
  MarketingIcon,
} from "@icg360/design-system";

import { HeaderLogo } from "components/shared/logo-link";
import { PageFooter } from "components/shared/page-footer";
import { trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const ThankYou = () => {
  useEffect(() => {
    trackEvent("shinglesForm_success_load");
  }, []);

  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <Container className={styles.headerWithLogin}>
          <MediaQuery minWidth={992}>
            {(matches) => (
              <>
                <HeaderLogo logo={matches ? "default" : "mobile"} />
                <Button
                  onClick={() => {
                    trackEvent("shinglesForm_success_logInClicked");
                    navigate("/");
                  }}
                  appearance="secondary"
                  size={matches ? "default" : "xs"}
                >
                  Log in
                </Button>
              </>
            )}
          </MediaQuery>
        </Container>
      </div>
      <Container className={styles.content}>
        <div className={styles.thankYouWrapper}>
          <div className={styles.shieldIcon}>
            <MarketingIcon name="ShieldHouse" size="lg" />
          </div>
          <Heading size="lg">
            {
              "Thank you for signing up for the Shingle Resealing Pilot Program!"
            }
          </Heading>
          <div>
            We greatly appreciate your interest in the Shingle Resealing Pilot
            Program! If you have any questions or concerns, please feel free to
            contact us at{" "}
            <DSLink
              href="mailto:homeservices@sagesure.com"
              onClick={() =>
                trackEvent("shinglesForm_success_contactEmailClicked")
              }
            >
              homeservices@sagesure.com
            </DSLink>
            .{" "}
          </div>
          <Heading size="md">{"What's next?"}</Heading>
          <div>
            {
              "We'll keep you updated on the program's progress and be in touch if your home meets the selection criteria for a check-up."
            }
          </div>
          <Heading size="md">
            {"Haven’t activated your online account yet?"}
          </Heading>
          <div>
            Stay in touch with your policy, your payment, and more offerings at
            SageSure by logging in to your online account.
          </div>
          <Button
            fluid
            onClick={() => {
              trackEvent("shinglesForm_success_activateAccountClicked");
              navigate("/register");
            }}
          >
            Activate my online account
          </Button>
        </div>
      </Container>
      <PageFooter />
    </>
  );
};

export default ThankYou;
