// Converts URL search params string into an object
export const urlSearchParamsObject = (
  search?: string
): Record<string, string> => {
  const result = {};
  const searchParams = new URLSearchParams(search || window.location.search);
  searchParams.forEach((value, key) => (result[key] = value));
  return result;
};

// The ?to=URL param can be used to redirect users; this returns any
// params in that redirect URL if the user needs to log in first.
// ie. ?to=URL?foo=x&bar=y will return the ?foo=x&bar=y&to=URL
// If there's no ?to param, the input params will be returned.
export const separateRedirectParams = (searchParams?: string): string => {
  const search = searchParams || window.location.search;
  const { to, ...rest } = urlSearchParamsObject(search);
  let resultParams = search;
  if (to) {
    const splitParams = to.split("?");
    if (splitParams.length > 1) {
      resultParams = `?${splitParams[1]}`;
      Object.entries(rest).forEach(([key, value]) => {
        resultParams += `&${key}=${value}`;
      });
    }
    resultParams += `&to=${splitParams[0]}`;
  }

  return resultParams;
};
