import { useContext, useEffect, useState } from "react";

import { addToast } from "@icg360/design-system";

import {
  AuthAppContext,
  AuthAppDispatchContext,
} from "components/root/auth-app-provider";
import { trackEvent } from "utils";

export const useRemovePolicy = () => {
  const { policies } = useContext(AuthAppContext);
  const { refetchPolicies } = useContext(AuthAppDispatchContext);
  const [showRemovePolicyModal, setShowRemovePolicyModal] = useState(false);
  const [removePolicyInfo, setRemovePolicyInfo] = useState();

  useEffect(() => {
    if (showRemovePolicyModal) {
      const { policyState: policyStatus } = removePolicyInfo ?? {
        policyState: "",
      };
      trackEvent("Remove policy modal displayed (profile)", { policyStatus });
    }
  }, [showRemovePolicyModal, removePolicyInfo]);

  const handlers = {
    openRemovePolicyModal: (policyInfo) => {
      trackEvent("Remove Policy Started", {
        listOfPolicy: policies?.map((p) => p.policyId),
      });

      setRemovePolicyInfo(policyInfo);
      setShowRemovePolicyModal(true);
    },
    closeRemovePolicyModal: () => setShowRemovePolicyModal(false),
    handleRemovePolicySucceeded: async (oldPolicyId) => {
      refetchPolicies();

      const { policyState: policyStatus } = removePolicyInfo ?? {
        policyState: "",
      };

      setShowRemovePolicyModal(false);
      addToast("Policy has been successfully removed from your account.", {
        icon: true,
      });

      trackEvent("Remove Policy Success Message Displayed", {
        oldPolicyId,
        policyStatus,
      });
    },
  };

  return {
    removePolicyState: {
      showRemovePolicyModal,
      removePolicyInfo,
    },
    removePolicyHandlers: handlers,
  };
};
