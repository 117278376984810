export const MODALS = {
  ADD_POLICY: "addPolicy",
  INVALID_POLICY: "invalidPolicyModal",
  ENROLLED_IN_EASYPAY_MODAL: "enrolledInEasyPayModal",
  EASYPAY_ENROLLMENT_MODAL: "easyPayEnrollmentModal",

  PAPERLESS_MODAL: "paperlessModal",
};

export const modalCookies = {
  [MODALS.EASYPAY_ENROLLMENT_MODAL]: "mss-easypay-modal-dismissed",
  [MODALS.PAPERLESS_MODAL]: "mss-paperless-modal-dismissed",
};
