import { useContext, useEffect } from "react";

import { BaseLayout } from "components/base-layout";
import { FetchingError } from "components/common/fetching-error";
import { Stack } from "components/common/stack";
import { ClaimActivity, SubmitClaim } from "components/renderers";
import { ClaimHelpSection } from "components/renderers/claim-help-section/claim-help-section";
import { AuthAppContext } from "components/root/auth-app-provider";
import { MainLayout } from "components/shared/main-layout";
import { scrollToTop, trackEvent } from "utils";

import THIRD_PARTY_CLAIM_TRACKERS from "./constants";
import { useClaimsData } from "./hooks";

const Claims = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { error, loading, claimsList, carrierInfo, claimsConstants } =
    useClaimsData(selectedPolicyId);

  useEffect(() => {
    trackEvent("Claims");
    scrollToTop();
  }, []);

  if (error) {
    return (
      <BaseLayout>
        <MainLayout sidebar={<ClaimHelpSection />}>
          <FetchingError page="Claims" />
        </MainLayout>
      </BaseLayout>
    );
  }

  const claimTrackedInternally = !THIRD_PARTY_CLAIM_TRACKERS.includes(
    carrierInfo?.id ?? ""
  );

  const main = (
    <Stack>
      <SubmitClaim
        userClaimsList={claimsList}
        carrierName={carrierInfo?.name ?? ""}
      />
      <ClaimActivity
        userClaimsList={claimsList}
        userClaimsConstants={claimsConstants}
        claimTrackedInternally={claimTrackedInternally}
      />
    </Stack>
  );

  return (
    <BaseLayout
      availableNotifications={[
        "Leakbot",
        "HomeServices",
        "CancellationPayment",
      ]}
    >
      <MainLayout sidebar={<ClaimHelpSection />} mainLoading={loading}>
        {main}
      </MainLayout>
    </BaseLayout>
  );
};

export default Claims;
