import { useContext, useEffect } from "react";

import { Card, Heading, MarketingIcon } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import SignatureSection from "pages/overview/welcome-banner/signature-section";
import { UnderwritingSection } from "pages/overview/welcome-banner/underwriting-section";
import { trackEvent } from "utils";

import { BillingSection } from "./billing-section";
import styles from "./welcome-banner.module.scss";

const WelcomeBanner = () => {
  const { userInfo, selectedPolicyId, policy } = useContext(AuthAppContext);

  useEffect(() => {
    trackEvent("welcomeTaskCard_load");
  }, []);

  return (
    <Card className={styles.welcomeCard}>
      <div className={styles.welcomeTitle}>
        <div className={styles.welcomeIcon}>
          <MarketingIcon name={policy.isCommercial ? "Shop" : "FullHouse"} />
        </div>
        <div>
          <Heading size="md">
            Hi{userInfo?.firstName ? `, ${userInfo.firstName}` : ""}
          </Heading>
          <div className={styles.policyNumber}>
            Policy number: {selectedPolicyId}
          </div>
        </div>
      </div>
      <div className={styles.welcomeSections}>
        <BillingSection />
        <SignatureSection />
        <UnderwritingSection />
      </div>
    </Card>
  );
};

export { WelcomeBanner };
