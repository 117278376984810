import { useContext } from "react";

import { Alert, Heading, Span, Tooltip, UiIcon } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { ENROLLMENT_STATUS, PAYMENT_METHOD_CODE } from "consts";
import { BillingPageDataQuery } from "gql/__generated__/hooks";
import { formatCurrency, getPaymentMethodDetails } from "utils";

import styles from "./payment-plan-sidebar.module.scss";

const TWO_PAYMENTS = 2;
const FOUR_PAYMENTS = 4;
const TEN_PAYMENTS = 10;

type PaymentPlanSidebarProps = {
  userBillingEmailAddress?: string | null;
  policyPaymentPlans?: BillingPageDataQuery["policyPaymentPlans"];
};

const PaymentPlanPolicyHolderPayer = ({
  userBillingEmailAddress,
  policyPaymentPlans,
}: PaymentPlanSidebarProps) => {
  let isEasyPayPending,
    paymentFrequencyLabel,
    paymentMethod,
    paymentMethodLabel,
    usingEasyPay,
    usingDirectBill;
  const { userBilling, userDetails } = useContext(AuthAppContext);
  const { accounting } = userBilling ?? {};
  const { easyPayAccount, paymentPlan } = accounting ?? { paymentPlan: {} };
  const { planType } = paymentPlan ?? {};

  const easyPayEnrollmentStatus =
    userDetails?.insuredPreferences?.easyPayEnrollmentStatus;

  if (planType) {
    ({ paymentMethod, paymentMethodLabel, paymentFrequencyLabel } =
      getPaymentMethodDetails(planType.toLowerCase()));

    isEasyPayPending =
      !paymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY) &&
      easyPayEnrollmentStatus === ENROLLMENT_STATUS.PENDING;

    usingEasyPay = paymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
    usingDirectBill = paymentMethod.includes(PAYMENT_METHOD_CODE.DIRECTBILL);
  }

  const [currentPlan] =
    policyPaymentPlans?.filter(({ planId }) => {
      const enhancedPlanId = getPaymentMethodDetails(planId?.toLowerCase());
      const enhancedPlanType = getPaymentMethodDetails(planType?.toLowerCase());
      return (
        enhancedPlanId.paymentFrequency === enhancedPlanType.paymentFrequency &&
        enhancedPlanId.paymentMethod === enhancedPlanType.paymentMethod
      );
    }) ?? [];

  const { downPayment, installmentAmount } = currentPlan ?? {};
  const numberOfPayments = Number(currentPlan?.numberOfPayments ?? "");

  const { lastFourDigits, paymentMethod: easyPayMethod } = easyPayAccount ?? {};
  const cardName = easyPayMethod || "Card";
  const cardNumber = lastFourDigits ? ` ending in ${lastFourDigits}` : "";
  const installmentAmountDisplay = Number.isNaN(installmentAmount)
    ? installmentAmount
    : formatCurrency(installmentAmount, true) +
      (numberOfPayments === TWO_PAYMENTS ? "" : " each");

  const firstPayment = Number.isNaN(downPayment)
    ? downPayment
    : formatCurrency(downPayment, true);

  return (
    <div className={styles.contentWrapper}>
      <Heading size="xs" className={styles.paymentFrequency}>
        {paymentFrequencyLabel}{" "}
        {usingDirectBill ? (
          <Tooltip content="Payment amounts include any applicable service fees">
            <UiIcon name="Question" size="sm" />
          </Tooltip>
        ) : null}
      </Heading>

      <div className={styles.inline}>
        <Span className={styles.label}>Payment 1</Span>
        <Tooltip content={firstPayment ?? ""}>
          <Span bold>{firstPayment}</Span>
        </Tooltip>
      </div>

      {numberOfPayments !== 1 && (
        <div className={styles.inline}>
          <Span className={styles.label}>
            {numberOfPayments === TWO_PAYMENTS && "Payment 2"}
            {numberOfPayments === FOUR_PAYMENTS && "Payments 2-4"}
            {numberOfPayments === TEN_PAYMENTS && "Payments 2-10"}
          </Span>
          <Tooltip content={installmentAmountDisplay ?? ""}>
            <Span bold>{installmentAmountDisplay}</Span>
          </Tooltip>
        </div>
      )}

      <div className={styles.inline}>
        <Span className={styles.label}>
          Payment method{" "}
          {usingEasyPay && (
            <Tooltip content="If you are enrolled in EasyPay with a credit card, the payment processor will charge a 2.99% processing fee on payments.">
              <UiIcon name="Question" size="sm" />
            </Tooltip>
          )}
        </Span>

        <Tooltip content={paymentMethodLabel}>
          <Span bold>{paymentMethodLabel}</Span>
        </Tooltip>
      </div>

      {usingEasyPay && (
        <div className={styles.inline}>
          <Span className={styles.label}>Payment details</Span>
          <Tooltip content={`${cardName} ${cardNumber}`}>
            <Span bold>
              {cardName}
              {cardNumber}
            </Span>
          </Tooltip>
        </div>
      )}

      <div className={styles.inline}>
        <Span className={styles.label}>Billing contact</Span>
        <Tooltip content={userBillingEmailAddress ?? ""}>
          <Span bold>{userBillingEmailAddress}</Span>
        </Tooltip>
      </div>

      {isEasyPayPending && (
        <Alert
          appearance="warning"
          title=""
          description={
            <>
              Your EasyPay enrollment is pending: the change will take effect in
              no more than two business days. Contact{" "}
              <MSSLink to="/my/support">SageSure Support</MSSLink> if you have
              any questions.
            </>
          }
        />
      )}
    </div>
  );
};

export default PaymentPlanPolicyHolderPayer;
