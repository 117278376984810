import { Formik } from "formik";
import cloneDeep from "lodash.clonedeep";
import { object, ref, string } from "yup";

import {
  UserBillingAddressQuery,
  useUpdatePaperlessEmailMutation,
} from "gql/__generated__/hooks";
import { userBillingAddressQuery } from "gql/queries";
import { en as locale } from "locale";
import EditEmailForm from "pages/settings/common/edit-email-form";
import { logError, trackEvent } from "utils";

const initialValues = {
  email: "",
  confirmEmail: "",
};

const schema = object().shape({
  email: string().required("Email required.").email("Enter a valid email."),
  confirmEmail: string()
    .oneOf([ref("email"), undefined], "Email address must match.")
    .required("Email address must match."),
});

type EditBillingEmailModalProps = {
  policyId: string;
  show: boolean;
  handleClose: () => void;
  onEditBillingSuccess: () => void;
};

const EditBillingEmailModal = ({
  policyId,
  show,
  handleClose,
  onEditBillingSuccess,
}: EditBillingEmailModalProps) => {
  const [updatePaperlessEmail] = useUpdatePaperlessEmailMutation();

  const handleSubmit = async (
    { email },
    { setSubmitting, setStatus, resetForm }
  ) => {
    try {
      setSubmitting(true);
      const updated = await updatePaperlessEmail({
        variables: { opInsuredEmailAddressBilling: email, policyID: policyId },
        update: (store, { data: successful }) => {
          if (successful) {
            const readData = cloneDeep(
              store.readQuery<UserBillingAddressQuery>({
                query: userBillingAddressQuery,
                variables: { policyID: policyId },
              })
            );

            if (readData) {
              readData.userBillingAddress = email;

              store.writeQuery({
                query: userBillingAddressQuery,
                variables: { policyID: policyId },
                data: readData,
              });
            }
          }
        },
      });

      if (updated) {
        trackEvent("Email address updated", { paperlessType: "billing" });
        resetForm();
        onEditBillingSuccess();
      }

      resetForm();
      setSubmitting(false);
    } catch (err) {
      setStatus(locale.settingsPage.billingEmailUpdateError);
      setSubmitting(false);
      logError(`Edit billing email: ${err.message}`);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <EditEmailForm
        handleClose={handleClose}
        show={show}
        heading="Update billing email address"
      />
    </Formik>
  );
};

export default EditBillingEmailModal;
