import { useState } from "react";

import { Alert, Badge, Button } from "@icg360/design-system";

import { useConvertEnvelopeIdToSigningLinkMutation } from "gql/__generated__/hooks";
import { logError, trackEvent } from "utils";

import styles from "./document-sign-row.module.scss";

const DocumentSignRow = ({ documentType, documentStatus, envelopeId }) => {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [convertEnvelope, { error }] =
    useConvertEnvelopeIdToSigningLinkMutation({
      context: {
        clientName: "keystone-api",
      },
    });

  const handleError = (error) => {
    // TODO: Show toast notification instead of the alert PHE-257
    setShowErrorAlert(true);
    trackEvent(`documents_selfServiceCenter_signError`, {
      status: documentStatus,
      error,
      documentType,
    });
  };

  const linkToDocument = async () => {
    setLoadingLink(true);
    setShowErrorAlert(false);

    trackEvent(`SelfServiceCenter - Sign now clicked`, {
      status: documentStatus,
      documentType,
    });

    try {
      const { data } = await convertEnvelope({
        variables: {
          envelopeId,
        },
      });
      if (data?.convertEnvelopeIdToSigningLink) {
        window.open(data.convertEnvelopeIdToSigningLink);
        setLoadingLink(false);
      }
      if (error) {
        handleError(error);
      }
    } catch (e) {
      logError(`Self service center - link to document: ${e.message}`);
      handleError(e);
    }
  };

  return (
    <>
      {showErrorAlert ? (
        <div className={styles.errorAlertWrapper}>
          <Alert
            title="Something went wrong"
            appearance="danger"
            description="Try again in a few minutes or contact customer support if the problem persists."
            onDismiss={() => setShowErrorAlert(false)}
            dismissable
          />
        </div>
      ) : null}
      <div className={styles.wrapper}>
        <div>
          <div className={styles.title}>
            <div className={styles.documentName}>{documentType}</div>
            <Badge size="compact" appearance="warning">
              Incomplete
            </Badge>
          </div>
          <div>
            Sign required documents to prevent delays in policy activation.
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            size="sm"
            onClick={linkToDocument}
            disabled={loadingLink}
            loading={loadingLink}
          >
            Sign
          </Button>
        </div>
      </div>
    </>
  );
};

export { DocumentSignRow };
