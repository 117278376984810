import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { AuthApp } from "components/auth-app";
import { HomeServicesProvider } from "components/home-services/home-services-provider";
import { CoverageContextProvider } from "pages/overview/extended-coverages/hooks";
import { UpdatePaymentContextProvider } from "pages/payment-plan-update/update-payment-context";
import { trackEvent } from "utils";

import { AuthAppProvider } from "./auth-app-provider";

const AuthAppWrapper = () => {
  const location = useLocation();
  useEffect(() => {
    trackEvent("page_view", { path: location.pathname });
  }, [location]);
  return (
    <AuthAppProvider>
      <HomeServicesProvider>
        <UpdatePaymentContextProvider>
          <CoverageContextProvider>
            <HelmetProvider>
              <AuthApp />
            </HelmetProvider>
          </CoverageContextProvider>
        </UpdatePaymentContextProvider>
      </HomeServicesProvider>
    </AuthAppProvider>
  );
};

export default AuthAppWrapper;
