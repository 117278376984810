import moment from "moment";
import { useContext } from "react";

import { Alert, Card, Heading } from "@icg360/design-system";

import { Inline } from "components/common/inline";
import { PaymentSection } from "components/payment-section";
import { AuthAppContext } from "components/root/auth-app-provider";
import { formatCurrency, formatDate } from "utils";

import styles from "./balance.module.scss";

const Balance = () => {
  const { userBilling } = useContext(AuthAppContext);
  const { dueDate, amountDue, remainingBalance } =
    userBilling?.accounting ?? {};
  let dueDateConv = dueDate;

  let isOverduePayment = false;

  if (dueDateConv === "0") {
    dueDateConv = "";
  } else {
    if (dueDateConv !== "No Upcoming Payment") {
      dueDateConv = formatDate(dueDateConv);

      isOverduePayment = moment(new Date()).isAfter(
        moment(dueDateConv, "MMM DD, YYYY")
      );
    }
  }
  return (
    <Card>
      <section className={styles.topInfo}>
        <div>
          <Heading size="xs" className={styles.infoLabel}>
            Amount Due
          </Heading>
          <span className={styles.infoValue} data-testid="amount-due">
            {formatCurrency(amountDue, true)}
          </span>
        </div>
        <div className={styles.paymentDueDate}>
          <Heading size="xs" className={styles.infoLabel}>
            Payment Due Date
          </Heading>
          <span className={styles.infoValue}>{dueDateConv}</span>
        </div>
        <div>
          <PaymentSection />
        </div>
      </section>
      {isOverduePayment && (
        <div className={styles.overdueBanner}>
          <Alert
            appearance="danger"
            title="Payment Past Due"
            description="Please submit payment immediately to avoid the cancellation of your policy."
          />
        </div>
      )}
      <section className={styles.bottomInfo}>
        <Inline gap="xs">
          <Heading size="xs" className={styles.infoLabel}>
            Balance Remaining
          </Heading>
          <span data-testid="balance-remaining">
            {formatCurrency(remainingBalance, true)}
          </span>
        </Inline>
      </section>
    </Card>
  );
};

export default Balance;
