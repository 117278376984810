import moment from "moment";

import { PaymentController } from "@icg360/payment-toolkit-v2";

import { CONFIG } from "config";
import { CommonPolicyDataQuery } from "gql/__generated__/hooks";
import { formatPhone, isFormattedPhone } from "utils";

import { getBillingAddressRaw } from "./render-helpers";

// This is just based on what we're passing in right now...
// Ideally, we'd be getting these types from payment-toolkit-v2
type MakePaymentParams = {
  minAmountDue: number;
  accountBalance: number;
  insightPolicyId: string;
  paymentApplicationClient: string;
  policyId: string;
  email: string;
  phone: string;
  quoteId: string;
  isScheduledPayEnabled: boolean;
  finalPayDate: string;
};

export type PayControl = {
  _payment_ready: boolean;
  _savecard_ready: boolean;
  _sessionId: string;
  isReady: () => boolean;
  addEventListener: (string, onEvent: (event) => void) => void;
  removeEventListener: (string, onEvent: (event) => void) => void;
  makePayment: (makePaymentParams: MakePaymentParams) => void;
};

export type PaymentConfig = {
  accountBalance: number;
  agencyLocationCode?: string | null;
  billingCity: string;
  billingState: string;
  billingStreet: string;
  billingZip: string;
  email: string;
  finalPayDate: string; // date?
  firstName: string;
  insightPolicyId: string;
  isScheduledPayEnabled: boolean;
  lastName: string;
  minAmountDue: number;
  paymentApplicationClient: string;
  phone: string;
  policyId: string;
  quoteId: string;
};

const config = {
  paymentsEndpoint: CONFIG.PAYMENT_API_HREF,
  production: CONFIG.APP_ENV === "production",
};

export let payControl: PayControl;

const waitForJquery = setInterval(() => {
  if (window.jQuery) {
    payControl = new PaymentController(config);
    clearInterval(waitForJquery);
  }
}, 50);

export const getPaymentConfig = (
  userBilling: CommonPolicyDataQuery["userBilling"],
  userDetails: CommonPolicyDataQuery["userDetails"],
  userInfo: CommonPolicyDataQuery["userBasicInfo"],
  userInsuranceRep: CommonPolicyDataQuery["userInsuranceRep"]
): PaymentConfig => {
  const agentPhone = userInsuranceRep?.agentPhone || "";
  const { accounting, mortgagees } = userBilling ?? {};
  const { email, firstName, lastName } = userInfo ?? {};
  const { currentTerm, quoteNumber, keystonePolicyId, mailingAddress } =
    userDetails ?? {};
  const { dueDate, minimumPayment, totalBalance, paymentPlan } =
    accounting ?? {};
  const insuranceRepPhone = isFormattedPhone(agentPhone)
    ? agentPhone
    : formatPhone(agentPhone);

  const [firstMortgagee] = mortgagees ?? [];
  const billingAddressRaw = getBillingAddressRaw(
    paymentPlan?.planType,
    firstMortgagee,
    mailingAddress
  );

  const tomorrow = moment().utc().add(1, "days");
  const dueDateDisplay =
    dueDate === "No Upcoming Payment"
      ? moment().utc().add(30, "days")
      : moment(Number(dueDate)).utc().add(3, "days");
  const finalPayDate =
    dueDateDisplay < tomorrow
      ? tomorrow.format("YYYY-MM-DD")
      : dueDateDisplay.format("YYYY-MM-DD");

  const minAmountDue = Math.max(1, Number(minimumPayment));

  return {
    accountBalance: Number(totalBalance),
    agencyLocationCode: currentTerm?.agencyLocationCode,
    billingCity: billingAddressRaw.billingCity,
    billingState: billingAddressRaw.billingState,
    billingStreet: billingAddressRaw.billingStreet,
    billingZip: billingAddressRaw.billingZip,
    email: email ?? "",
    finalPayDate,
    firstName: firstName ?? "",
    insightPolicyId: keystonePolicyId ?? "",
    isScheduledPayEnabled: true,
    lastName: lastName ?? "",
    minAmountDue,
    paymentApplicationClient: "2000",
    phone: insuranceRepPhone,
    policyId: currentTerm?.policyNumber ?? "",
    quoteId: quoteNumber ?? "",
  };
};
