import PropTypes from "prop-types";
import { useEffect } from "react";

import ClaimTracker from "@icg360/claim-tracker";

import { Stack } from "components/common/stack";
import { trackEvent } from "utils";

function trackDetailsClick(expanded, computedClaim) {
  trackEvent("View Claims Details Clicked", {
    claimID: computedClaim.CarrierClaim
      ? computedClaim.CarrierClaim.Number
      : computedClaim.FNOLSubmission.SubmissionNumber,
    claimStatus: computedClaim.lastCompletedMilestone.name,
    claimOpenStatus: computedClaim.Status,
    clickType: expanded ? "expand" : "collapse",
    claimSubmissionDate: computedClaim.submissionDate,
  });
}

const sortClaimsBySubmissionDate = (claims) =>
  claims.sort(
    (claimA, claimB) => claimB.submissionDate - claimA.submissionDate
  );

const ClaimsTrackerList = ({ claims, claimTrackedInternally }) => {
  useEffect(() => {
    trackEvent("Claims Tracker: Displayed");
  }, []);

  const { activeClaims, pastClaims } = claims.reduce(
    (acc, origClaim) => {
      const claim = { ...origClaim };
      claim.submissionDate = claim.CarrierClaim
        ? new Date(claim.CarrierClaim.DateSubmitted)
        : new Date(claim.FNOLSubmission.DateReceived);

      if (claim.Status === "Open") {
        acc.activeClaims.push(claim);
      } else {
        acc.pastClaims.push(claim);
      }
      return acc;
    },
    {
      activeClaims: [],
      pastClaims: [],
    }
  );

  const sortedClaims = [
    ...sortClaimsBySubmissionDate([...activeClaims]),
    ...sortClaimsBySubmissionDate([...pastClaims]),
  ];

  return (
    <>
      <Stack>
        {sortedClaims.map((claim) => {
          const claimNumber = claim.CarrierClaim
            ? claim.CarrierClaim.Number
            : claim.FNOLSubmission.SubmissionNumber;

          return (
            <ClaimTracker
              claim={claim}
              onDetailsToggle={trackDetailsClick}
              key={claimNumber}
              id={claimNumber}
              claimTrackedInternally={claimTrackedInternally}
            />
          );
        })}
      </Stack>
    </>
  );
};

ClaimsTrackerList.propTypes = {
  claims: PropTypes.array,
  claimTrackedInternally: PropTypes.bool,
};

export default ClaimsTrackerList;
