import { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./mss-transition.module.scss";

type MssTransitionProps = PropsWithChildren<{
  show: boolean;
  timeout: number;
  unmountOnExit: boolean;
}>;

export const MSSTransition = ({
  show,
  timeout,
  unmountOnExit,
  children,
}: MssTransitionProps) => (
  <CSSTransition
    in={show}
    timeout={timeout}
    unmountOnExit={unmountOnExit}
    className={styles.transition}
    classNames="list-item"
  >
    {children}
  </CSSTransition>
);
