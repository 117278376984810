import { CONFIG } from "config";

export const chatSettings = {
  uuid: CONFIG.CHAT_UUID,
  tenant: "c2FnZXN1cmUwMQ",
  channel: CONFIG.CHAT_CHANNEL,
  channelUuid: CONFIG.CHAT_CHANNEL_UUID,
  ipcManager: CONFIG.IPC_MANAGER_URL,
  domain: "https://vcc-na7-cf.8x8.com",
  maintenanceDomain: "https://vcc-na7b.8x8.com",
  path: "/.",
  buttonContainerId: "chat-8x8-container",
  align: "right",
};
