import { useContext, useEffect, useState } from "react";

import { Alert, Button, Checkbox, Heading, Span } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { HTTP_LOGIN_TIMEOUT } from "consts";
import { en as locale } from "locale";
import {
  formatLeakBotPayload,
  formatPhoneNumber,
} from "pages/leakbot/leakbot-utils";
import { authHeaders, logError, trackEvent, useFlags } from "utils";

import styles from "./leakbot.module.scss";

const LeakbotReview = ({
  onBack,
  name,
  policyId,
  address,
  email,
  phone,
  agreeSetup,
  setAgreeSetup,
  onSubmitSuccess,
}) => {
  const { universalLogin } = useFlags();
  const { selectedPolicyId, userDetails, userInfo } =
    useContext(AuthAppContext);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (errorMessage) {
      trackEvent("LeakBot validation error");
    }
  }, [errorMessage]);

  const onSubmit = async () => {
    setErrorMessage(agreeSetup ? "" : locale.leakbot.errorTerms);

    if (agreeSetup) {
      const payload = formatLeakBotPayload({
        policyId: selectedPolicyId,
        userDetails,
        userInfo,
      });
      payload.email = email;
      payload.phone = phone.slice(0, 10);

      trackEvent("LeakBot - Submit order button clicked");

      try {
        const leakbotSubmitResponse = await fetch(
          `${CONFIG.KEYSTONE_PROXY_HREF}/api/leakbot/enrollment`,
          {
            body: JSON.stringify(payload),
            headers: {
              Accept: "application/json",
              "content-type": "application/json",
              ...(universalLogin ? await authHeaders() : {}),
            },
            method: "POST",
            credentials: "include",
          }
        ).then((resp) => {
          return resp.json();
        });

        if (leakbotSubmitResponse.status >= HTTP_LOGIN_TIMEOUT) {
          setErrorMessage(locale.leakbot.errorGeneral);
          trackEvent("LeakBot submission error");
        } else {
          trackEvent("LeakBot - Ordered");
          onSubmitSuccess();
        }
      } catch (error) {
        setErrorMessage(locale.leakbot.errorGeneral);
        trackEvent("LeakBot submission error");
        logError(`LeakBot submission: ${error.message}`);
      }
    }
  };

  return (
    <div className={styles.lbNonCardLayout}>
      <div className={styles.lbNonCardMainContent}>
        <Heading size="lg">{locale.leakbot.textReviewTitle}</Heading>
        <div>
          <Span bold>Name</Span>
          <p>{name}</p>
        </div>
        <div>
          <Span bold>Policy number</Span>
          <p>{policyId}</p>
        </div>
        <div>
          <Span bold>Shipping address</Span>
          <p>{address}</p>
        </div>
        <div>
          <Span bold>Email</Span>
          <p>{email}</p>
        </div>
        <div>
          <Span bold>Phone number</Span>
          <p>{formatPhoneNumber(phone)}</p>
        </div>
        {errorMessage && <Alert title={errorMessage} appearance="danger" />}
        <div>
          <Span bold>Terms and Conditions</Span>
          <div>
            <Checkbox
              isSelected={agreeSetup}
              onChange={() => {
                setErrorMessage("");
                setAgreeSetup(!agreeSetup);
              }}
            >
              <Span bold>{locale.leakbot.termsA}</Span>
            </Checkbox>
          </div>
          <div className={styles.lbCheckboxLabel}>
            <p>{locale.leakbot.termsB}</p>
            <br />
            <p>{locale.leakbot.termsC}</p>
          </div>
        </div>
        <div className={styles.lbNav}>
          <Button appearance="tertiary" onClick={onBack}>
            Go back
          </Button>
          <Button onClick={onSubmit} data-testid="LeakbotStartBtn">
            Submit
          </Button>
        </div>
      </div>
      <div>
        <img
          src="/assets/images/leakbot-price-card.png"
          alt="a cost breakdown showing that leakbot is free"
        />
      </div>
    </div>
  );
};

export default LeakbotReview;
