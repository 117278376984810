import { useContext } from "react";

import { Card } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useOptionalCoveragesQuery } from "gql/__generated__/hooks";
import { en as locale } from "locale";

import CoverageCard from "./coverage-card";
import styles from "./extended-coverages.module.scss";
import { useCoverages } from "./hooks";

const ExtendedCoverages = () => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const { data, loading } = useOptionalCoveragesQuery({
    variables: {
      policyID: selectedPolicyId,
    },
    context: {
      clientName: "keystone-api",
    },
  });

  const { coverages } = data?.getEligibleCoverages ?? {};

  const coveragesToShow = useCoverages(coverages);

  return !loading && coveragesToShow?.length ? (
    <Card title={locale.extendedCoverages.title} className={styles.ctaCard}>
      <div className={styles.extendedCoverages}>
        <p>
          Consider these additional coverages that protect you from risks not
          covered by your standard homeowners insurance policy.
        </p>
        {coveragesToShow.map((coverage, i) => (
          <CoverageCard
            key={i}
            coverage={coverage}
            listOfCoverages={coveragesToShow}
          />
        ))}
      </div>
    </Card>
  ) : null;
};

export default ExtendedCoverages;
