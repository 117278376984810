import { Link as DSLink } from "@icg360/design-system";

export enum coverages {
  IDENTITY = "IDENTITY_FRAUD",
  MECHANICAL = "MECHANICAL_BREAKDOWN",
  SERVICE = "SERVICE_LINE",
}

export const coveragePremiums = {
  [coverages.IDENTITY]: "25",
  [coverages.MECHANICAL]: "25 - 50",
  [coverages.SERVICE]: "25 - 40",
};

type CoverageDescription = {
  short: string;
  expanded: (onClick: () => void) => JSX.Element;
};

type CoverageCopy = {
  name: string;
  short: string;
  description: CoverageDescription;
};

export const extendedCoveragesCopy: {
  [coverages.IDENTITY]: CoverageCopy;
  [coverages.MECHANICAL]: CoverageCopy;
  [coverages.SERVICE]: CoverageCopy;
} = {
  [coverages.IDENTITY]: {
    name: "Identity fraud expense coverage",
    short: "Identity fraud expense",
    description: {
      short:
        "Identity theft can wreak havoc on your life. This coverage helps you recover from identity theft and pays out-of-pocket costs.",
      expanded: (onClick) => (
        <>
          Identity fraud expense coverage repays the out-of-pocket costs for
          restoring your credit and any lost income due to missed work. It also
          includes specialized support throughout the recovery process. Everyone
          in your household is covered.{" "}
          <DSLink
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
            href="https://sagesure.widencollective.com/assets/share/asset/fn5xy1ndg1"
          >
            Download fact sheet
          </DSLink>
        </>
      ),
    },
  },
  [coverages.MECHANICAL]: {
    name: "Equipment breakdown coverage",
    short: "Equipment breakdown",
    description: {
      short:
        "Coverage for equipment like appliances, heating and cooling systems, and home electronics.",
      expanded: (onClick) => (
        <>
          Equipment breakdown coverage helps you repair or replace equipment
          like appliances, heating and cooling systems, and home electronics
          when needed.{" "}
          <DSLink
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
            href="https://sagesure.widencollective.com/assets/share/asset/91ws48mezy"
          >
            Download fact sheet
          </DSLink>
        </>
      ),
    },
  },
  [coverages.SERVICE]: {
    name: "Service line coverage",
    short: "Service line",
    description: {
      short:
        "You’re responsible for your home’s service lines. Get coverage for repairing damaged utility services like sewer and electrical, if it’s related to a covered claim.",
      expanded: (onClick) => (
        <>
          Service line coverage protects from damage to underground wiring and
          piping that connects your home to utility services like sewer or
          electrical. It also covers additional costs incurred due to loss of
          those services.{" "}
          <DSLink
            rel="noopener noreferrer"
            target="_blank"
            onClick={onClick}
            href="https://sagesure.widencollective.com/assets/share/asset/v3z33ytviw"
          >
            Download fact sheet
          </DSLink>
        </>
      ),
    },
  },
};
