import { useEffect } from "react";

import { Link as DSLink, Heading } from "@icg360/design-system";

import { trackEvent, useFlags } from "utils";

import styles from "./fetching-error.module.scss";

type FetchingErrorProps = {
  page?: string;
};

export const FetchingError = ({ page }: FetchingErrorProps) => {
  const { supportPhoneNumber } = useFlags();

  useEffect(() => {
    const message = page
      ? `Error Displayed (${page})`
      : "Error Displayed (General)";
    trackEvent(message, { page: page || "general" });
  }, [page]);

  return (
    <div className={styles.error}>
      <Heading size="md">Hmm, something isn&apos;t right</Heading>
      <span>
        You can try refreshing this page. Contact{" "}
        <DSLink href="mailto:customercare@sagesure.com">
          customercare@sagesure.com
        </DSLink>{" "}
        or call us at {supportPhoneNumber} if it doesn&apos;t start working
        again and you need our help.
      </span>
    </div>
  );
};
