import { useEffect, useState } from "react";

import { scrollToTop, trackEvent } from "utils";

export const useFAQList = ({ displayedFAQs, openQuestion }) => {
  const faqExpandedInit = {};
  displayedFAQs.forEach((item) => {
    faqExpandedInit[item.id] = item.id === openQuestion;
  });

  const [faqShown, setFaqShown] = useState(openQuestion);
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    scrollToTop();
  }, [faqShown]);

  const trackFAQEvent = (faqId) => {
    const mixpanelFAQ = displayedFAQs.find((item) => item.id === faqId);
    trackEvent("FAQ Clicked", {
      displayStatus: faqShown ? "closed" : "expanded",
      questionCategory: mixpanelFAQ.category,
      questionID: faqId,
      questionName: mixpanelFAQ.question,
    });
  };

  // Used for mobile:
  const toggleExpandedSection = (open, section) => {
    setExpandedSections({ [section]: open, ...expandedSections });
  };

  const toggleQuestion = (faqId: string, showList: boolean) => {
    !showList && trackFAQEvent(faqId);
    setFaqShown(showList ? null : faqId);
  };

  return { toggleExpandedSection, toggleQuestion, expandedSections, faqShown };
};
