import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { ENROLLMENT_STATUS } from "consts";

import { PaperlessCardEnrolled } from "./paperless-card-enrolled";
import { PaperlessCardUnenrolled } from "./paperless-card-unenrolled";

type PaperlessCardProps = {
  pageName: string;
};

/**
 * Show an enrolled or unenrolled card if the policy is active and not commercial.
 */
export const PaperlessCard = ({ pageName }: PaperlessCardProps) => {
  const { userDetails } = useContext(AuthAppContext);

  if (
    userDetails?.policyStatus !== "ACTIVEPOLICY" ||
    userDetails?.isCommercial
  ) {
    return null;
  }

  const enrolled =
    userDetails?.insuredPreferences?.paperlessDocumentEnrollmentStatus ===
    ENROLLMENT_STATUS.ENROLLED;

  return enrolled ? (
    <PaperlessCardEnrolled />
  ) : (
    <PaperlessCardUnenrolled
      pageName={pageName}
      enrolledBills={
        userDetails?.insuredPreferences?.paperlessBillingEnrollmentStatus ||
        ENROLLMENT_STATUS.NOT_ENROLLED
      }
      enrolledDocs={
        userDetails?.insuredPreferences?.paperlessDocumentEnrollmentStatus ||
        ENROLLMENT_STATUS.NOT_ENROLLED
      }
    />
  );
};
