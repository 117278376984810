import { ReactNode } from "react";

import { Badge, Button, Card, Heading, Span } from "@icg360/design-system";

import styles from "./offer-card.module.scss";

type OfferCardProps = {
  title: string;
  imgUrl: string;
  imgAlt: string;
  productName: string;
  buttonText: string | null;
  buttonAction: () => void;
  faqText: string;
  faqLink: ReactNode;
  enrolled?: boolean;
};

const OfferCard = ({
  title,
  imgUrl,
  imgAlt,
  productName,
  buttonText,
  buttonAction,
  faqText,
  faqLink,
  enrolled,
}: OfferCardProps) => {
  return (
    <Card className={styles.cardDS}>
      <div className={styles.title}>
        <Heading size="sm">{title}</Heading>
        {enrolled ? <Badge appearance="success">Enrolled</Badge> : null}
      </div>
      <div className={styles.offerImgWrapper}>
        <img className={styles.offerImg} src={imgUrl} alt={imgAlt} />
        <Span size="xs" color="quiet">
          {productName.toUpperCase()}
        </Span>
      </div>
      {enrolled ? (
        <div className={styles.faqLinkWrapper}>
          <div>{faqText}</div>
          <div>{faqLink}</div>
        </div>
      ) : (
        <div className={styles.buttonWrapper}>
          <Button onClick={buttonAction}>{buttonText}</Button>
        </div>
      )}
    </Card>
  );
};

export default OfferCard;
