import { Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { SpinnerLoader } from "components/loader/loader";
import { trackEvent } from "utils";

export const Main = () => {
  const location = useLocation();
  useEffect(() => {
    trackEvent("page_view", { path: location.pathname });
  }, [location]);

  return (
    <HelmetProvider>
      <Suspense fallback={<SpinnerLoader />}>
        <div>
          <Outlet />
        </div>
      </Suspense>
    </HelmetProvider>
  );
};
