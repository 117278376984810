import { type KeystoneOfferEligibility } from "src/utils/keystone-api-mapping";

import { FeaturedOfferShingleReseal } from "pages/overview/featured-offers/featured-offer-shingle-reseal";

import { FeaturedOfferTing } from "./featured-offer-ting";

type FeaturedOffersProps = {
  offers: KeystoneOfferEligibility[];
};

export const FeaturedOffers = ({ offers }: FeaturedOffersProps) => {
  const hasTingOffer = !!offers.find(
    (offer) => offer.offeringInternalName === "ting-offer"
  );
  const shingleResealOffer = offers.find(
    (offer) => offer.offeringInternalName === "shingle-opportunity"
  );
  if (shingleResealOffer) {
    return (
      <FeaturedOfferShingleReseal offeringId={shingleResealOffer.offeringId} />
    );
  } else if (hasTingOffer) {
    return <FeaturedOfferTing />;
  } else {
    return null;
  }
};
