import { useState } from "react";

import { useFaqs } from "hooks/use-faqs";
import { trackEvent } from "utils";

export const pageCategoryMap = {
  overview: "general",
  claims: "claims",
  billing: "payments",
  documents: "documents",
  property: "property",
};

export const useFaqSidebar = (page: string) => {
  const [answerShown, setAnswerShown] = useState(null);
  const faqs = useFaqs({ category: pageCategoryMap[page] });

  const toggleQuestion = (faqId) => {
    if (faqId) {
      const mixpanelFAQ = faqs.find((item) => item.id === faqId);
      trackEvent("FAQ Clicked", {
        displayStatus: answerShown ? "closed" : "expanded",
        questionCategory: mixpanelFAQ?.category,
        questionID: faqId,
        questionName: mixpanelFAQ?.question,
      });
    }

    setAnswerShown(faqId);
  };

  return { faqs, answerShown, toggleQuestion };
};
