import { chatSettings } from "consts";

import { logError } from "./logger";

export type LiveChatStatus = "live" | "offline" | "uninitialized";

export const CHAT_STATUS_MAP: Record<string, LiveChatStatus> = {
  "button_9925144835f3ad1841adce6.31761337": "live",
  "button_20049313275f3ad2c1bfe013.64729797": "offline",
};

export const getLiveChatStatus = (event) => {
  if (
    event.origin === chatSettings.domain ||
    event.origin === chatSettings.maintenanceDomain
  ) {
    try {
      const { topic, data } = JSON.parse(event.data);
      if (topic === "show:button" && data) {
        return CHAT_STATUS_MAP[data] ?? "uninitialized";
      }
    } catch (e) {
      logError(`Failed to parse live chat status: ${e.message}`);
      return "uninitialized";
    }
  }
  return null;
};
