import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import { Alert, Link as DSLink } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { acceptPaymentOnCancelledPolicy, useFlags } from "utils";

import styles from "./cancellation-payment-notice.module.scss";

const CancellationPaymentNotice = () => {
  const { userDetails, userBilling } = useContext(AuthAppContext);
  const [showCancellationPaymentNotice, setShowPaymentCancellationNotice] =
    useState(false);
  const [minimumPaymentMet, setMinimumPaymentMet] = useState(false);
  const { supportPhoneNumber } = useFlags();

  useEffect(() => {
    if (userBilling && userDetails) {
      setMinimumPaymentMet(
        Number(userBilling?.accounting?.minimumPayment) === 0
      );

      setShowPaymentCancellationNotice(
        acceptPaymentOnCancelledPolicy(
          userDetails?.policyStatus,
          userDetails?.policyStateAttributes?.reasonCode?.value,
          userDetails?.currentTerm?.effectiveDatePolicyTerm,
          1 // Show notice even if they have made payment
        )
      );
    }
  }, [userBilling, userDetails]);

  const policyExpiredNotice = (
    <>
      This policy has expired. To accept the renewal policy offer that was sent
      to you, please make a payment now. After receipt of payment, your policy
      will be activated within one business day. If you have questions, please
      contact your insurance representative or SageSure Customer Care at{" "}
      <DSLink href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</DSLink>{" "}
      or{" "}
      <DSLink href="mailto:customercare@sagesure.com">
        customercare@sagesure.com
      </DSLink>
      .
    </>
  );
  const policyStatusUpdate = (
    <>
      Thank you for your payment. Your policy will be reactivated within one
      business day. If you have questions, please contact your insurance
      representative or SageSure Customer Care at at{" "}
      <DSLink href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</DSLink>{" "}
      or{" "}
      <DSLink href="mailto:customercare@sagesure.com">
        customercare@sagesure.com
      </DSLink>
      .
    </>
  );

  return showCancellationPaymentNotice ? (
    <Container>
      <Alert
        appearance={minimumPaymentMet ? "success" : "danger"}
        className={styles.alert}
        title=""
        description={
          minimumPaymentMet ? policyStatusUpdate : policyExpiredNotice
        }
      />
    </Container>
  ) : null;
};

export default CancellationPaymentNotice;
