import { Navigate, useParams } from "react-router-dom";

import { TingOffer } from "./ting";

export const OfferLanding = () => {
  const { offerId } = useParams();

  return (
    <>{offerId === "ting" ? <TingOffer /> : <Navigate to="/my/overview" />}</>
  );
};
