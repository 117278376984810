import { Field, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";

import {
  Alert,
  Button,
  Link as DSLink,
  Heading,
  MarketingIcon,
} from "@icg360/design-system";

import {
  DataDefinition,
  DataList,
  DataRow,
  DataTerm,
} from "components/common/data-row";
import { SpinnerLoader } from "components/loader/loader";
import { AuthAppContext } from "components/root/auth-app-provider";
import {
  CheckboxField,
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { PAYMENT_PLANS } from "consts";
import { useReviewQuery } from "gql/__generated__/hooks";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";
import {
  capitalize,
  isEasyPayEnrolled,
  isPaperlessDocumentEligible,
  scrollToTop,
  useFlags,
} from "utils";

import { BankInfoFields } from "../bank-info";
import { CardInfoFields } from "../card-info";
import { useSelectedPaymentPlan } from "./hooks";
import styles from "./review.module.scss";

const PROCESSING_DAY_LABEL = {
  "1stMonth": "1st of the month",
  "8thMonth": "8th of the month",
  "15thMonth": "15th of the month",
  "22ndMonth": "22nd of the month",
};

type ReviewProps = {
  setStep: (step: EnrollmentStep) => void;
};

const Review = ({ setStep }: ReviewProps) => {
  const { easyPayFullPayOnly, supportPhoneNumber } = useFlags();
  const [showAgreementRequired, setShowAgreementRequired] = useState(false);

  const { selectedPolicyId, userBilling, userDetails } =
    useContext(AuthAppContext);
  const updatingExistingEnrollment = isEasyPayEnrolled(userBilling);
  const showPaperlessDocuments = isPaperlessDocumentEligible(userDetails);

  const { loading, data: reviewData } = useReviewQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const { policyPaymentPlans, easypayProcessingFees } = reviewData ?? {};

  const {
    values,
    values: { paymentFrequency, easypayMethod, paymentMethod },
    isSubmitting,
    dirty,
    status,
    isValid,
  } = useFormikContext<EasyPayFormValues>();

  useEffect(() => {
    if (status === "failed") {
      scrollToTop();
    }
  }, [status]);

  useEffect(() => {
    setShowAgreementRequired(false);
  }, [values]);

  const [{ editing, handlePaymentPlanSubmit, setEditing }] =
    useSelectedPaymentPlan({
      currentPaymentMethod: easypayMethod || paymentMethod,
      paymentFrequency,
      policyPaymentPlans,
      easypayProcessingFees,
      updatingExistingEnrollment,
      loading,
    });

  const submitOnClick = () => {
    if (!isValid || !dirty) {
      setShowAgreementRequired(true);
    } else {
      handlePaymentPlanSubmit();
    }
  };

  if (loading) {
    return <SpinnerLoader />;
  }

  if (!policyPaymentPlans) {
    return (
      <Alert
        appearance="danger"
        title="Oops, something went wrong!"
        description="An error occurred while fetching this data"
      />
    );
  }

  const usingCard = easypayMethod.includes("cc");

  const displayedPlan = PAYMENT_PLANS.find(
    ({ name }) => paymentFrequency === name
  );
  const { count, downPayment } = displayedPlan ?? {};

  return (
    <div data-testid="payment-plan-review">
      {status === "failed" ? (
        <Alert
          appearance="danger"
          title="Oops, something went wrong!"
          description={`Please try again. If the issue persists, contact customer service at ${supportPhoneNumber}`}
          className={styles.easypayError}
        />
      ) : null}
      <Heading size="lg">
        Please review and confirm your payment details
      </Heading>
      <div className={styles.reviewContentWrapper}>
        <section className={styles.reviewSection}>
          <DataRow>
            <DataTerm>Payment plan</DataTerm>
            <DataDefinition>
              {`${count} payment${count !== "1" ? "s" : ""}`}
              {usingCard &&
                (count === "1"
                  ? " with a 2.99% processing fee"
                  : " with 2.99% processing fees")}
              {downPayment !== "0" && `, ${downPayment}% initial payment`}
            </DataDefinition>
          </DataRow>
          {!easyPayFullPayOnly ? (
            <Button
              appearance="tertiary"
              size="sm"
              onClick={() => setStep("choosePlan")}
              disabled={editing !== ""}
            >
              Edit
            </Button>
          ) : null}
        </section>
        <section className={styles.reviewSection}>
          <DataRow>
            <DataTerm>Processing day</DataTerm>
            <DataDefinition>
              {PROCESSING_DAY_LABEL[values.draftDate]}
            </DataDefinition>
          </DataRow>
          <Button
            appearance="tertiary"
            size="sm"
            onClick={() => setStep("chooseDay")}
            disabled={editing !== ""}
          >
            Edit
          </Button>
        </section>
        <section className={styles.reviewSection}>
          <DataRow>
            <DataTerm>Payment method</DataTerm>
            <DataDefinition>
              {usingCard ? "Credit / debit card" : "Bank account (ACH)"}
            </DataDefinition>
          </DataRow>
          <Button
            appearance="tertiary"
            size="sm"
            onClick={() => setStep("chooseMethod")}
            disabled={editing !== ""}
          >
            Edit
          </Button>
        </section>
        <section className={styles.reviewSection}>
          {usingCard ? (
            editing === "cardInfo" ? (
              <div className={styles.editFields}>
                <CardInfoFields />
              </div>
            ) : (
              <DataList>
                <DataRow>
                  <DataTerm>Name on card</DataTerm>
                  <DataDefinition>{values.cc.cardholderName}</DataDefinition>
                </DataRow>
                <DataRow>
                  <DataTerm>Card number</DataTerm>
                  <DataDefinition className="fs-mask">
                    {values.cc.cardNumber}
                  </DataDefinition>
                </DataRow>
                <DataRow>
                  <DataTerm>Expiration date</DataTerm>
                  <DataDefinition>
                    {values.cc.cardExpirationDate}
                  </DataDefinition>
                </DataRow>
                <DataRow>
                  <DataTerm>Zip code</DataTerm>
                  <DataDefinition>{values.cc.cardZipCode}</DataDefinition>
                </DataRow>
              </DataList>
            )
          ) : editing === "bankInfo" ? (
            <div className={styles.editFields}>
              <BankInfoFields />
            </div>
          ) : (
            <DataList>
              <DataRow>
                <DataTerm>Account type</DataTerm>
                <DataDefinition>
                  {capitalize(values.ach.easypayMethod)}
                </DataDefinition>
              </DataRow>
              <DataRow>
                <DataTerm>Name on account</DataTerm>
                <DataDefinition>{values.ach.bankStatementName}</DataDefinition>
              </DataRow>
              <DataRow>
                <DataTerm>Name of bank</DataTerm>
                <DataDefinition>
                  {values.ach.financialInstitutionName}
                </DataDefinition>
              </DataRow>
              <DataRow>
                <DataTerm>Routing number</DataTerm>
                <DataDefinition>{values.ach.routingNumber}</DataDefinition>
              </DataRow>
              <DataRow>
                <DataTerm>Account number</DataTerm>
                <DataDefinition className="fs-mask">
                  {values.ach.accountNumber}
                </DataDefinition>
              </DataRow>
            </DataList>
          )}
          {editing === "cardInfo" || editing === "bankInfo" ? (
            <Button
              appearance="tertiary"
              size="sm"
              onClick={() => setEditing("")}
            >
              Save
            </Button>
          ) : (
            <Button
              appearance="tertiary"
              size="sm"
              onClick={() => {
                usingCard ? setEditing("cardInfo") : setEditing("bankInfo");
              }}
            >
              Edit
            </Button>
          )}
        </section>

        {showPaperlessDocuments && (
          <section className={styles.paperlessSection}>
            <Heading size="sm" className={styles.sectionHeading}>
              <MarketingIcon name="Paperless" size="sm" />
              Paperless policy documents
            </Heading>

            <p className={styles.sectionBody}>
              When you enroll in paperless documents, we&apos;ll donate $1 to
              the Arbor Day Foundation to plant trees in neighborhoods impacted
              by natural disasters.
            </p>

            <Field
              id="enrollDocuments"
              component={RadioButtonGroup}
              fieldChildren={() => (
                <div
                  className={`${styles.paperlessFields} ${styles.sectionBody}`}
                >
                  <Field
                    name="enrollDocuments"
                    id="yes"
                    label="Yes, send all documents to me by email."
                    component={RadioButtonField}
                    checked={values.enrollDocuments === "yes"}
                    className={styles.radio}
                  />
                  {values.enrollDocuments === "yes" && (
                    <p>
                      By clicking {"'"}Yes{"'"} above you are instantly
                      enrolling in paperless and confirming that you&apos;ve
                      read, agree to and accept SageSure&apos;s{" "}
                      <DSLink href="https://www.sagesure.com/PaperlessConsent/">
                        Disclosure and Consent policies.
                      </DSLink>
                    </p>
                  )}
                  <Field
                    name="enrollDocuments"
                    gs
                    id="no"
                    label="No, continue sending policy documents to me by mail."
                    component={RadioButtonField}
                    checked={values.enrollDocuments === "no"}
                    className={styles.radio}
                  />
                </div>
              )}
            />
          </section>
        )}
        <section className={styles.acknowledgeSection}>
          <Heading size="sm" className={styles.sectionHeading}>
            <MarketingIcon name="Document" size="sm" />
            Terms and conditions
          </Heading>
          <div className={styles.termsCheckbox}>
            <Field
              component={CheckboxField}
              name={`${usingCard ? "cc" : "ach"}.authorize`}
              label="I agree to the terms & conditions. *"
            />
          </div>
          <p className={styles.sectionBody}>
            {usingCard ? (
              <>
                <p>
                  I authorize SageSure Insurance Managers, LLC and One, Inc. to
                  perform scheduled payments from the card identified above.
                  SageSure Insurance Managers, LCC users Onc, Inc. as their
                  third-party provider.
                </p>
                <p>
                  If I choose to pay with a credit card or debit card, the total
                  amount to be charged to the card will be the policy payment
                  amount plus a 2.99% processing fee. Customers paying with ACH
                  transfer are not charged a processing fee.
                </p>
                <p>
                  This authorization is to remain in full force and effect until
                  SageSure Insurance Managers, LLC receives a written request to
                  terminate three business days or more before the next
                  scheduled transfer date.
                </p>
              </>
            ) : (
              <>
                <p>
                  I authorize SageSure Insurance Managers, LLC to perform
                  scheduled electronic fund transfer debits from the account
                  identified above.
                </p>
                <p>
                  This authorization is to remain in full force and effect until
                  SageSure Insurance Managers, LLC receives a written request to
                  terminate three business days or more before the next
                  scheduled transfer date.
                </p>
              </>
            )}
          </p>
          {showAgreementRequired ? (
            <Alert
              appearance="warning"
              title=""
              description="Please agree to the terms and conditions to continue"
              className={styles.requiredAlert}
            />
          ) : null}
        </section>

        <div className={styles.navigation}>
          <Button
            appearance="tertiary"
            onClick={() => setStep(usingCard ? "cardInfo" : "bankInfo")}
            data-testid="easypay-review-back-btn"
          >
            Go back
          </Button>

          <Button
            type="submit"
            onClick={submitOnClick}
            data-testid="easypay-submit-btn"
            loading={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Review;
