import { useContext } from "react";

import { Alert } from "@icg360/design-system";

import { CoverageContext } from "pages/overview/extended-coverages/hooks";

import styles from "./coverage-notification.module.scss";

const CoverageNotification = () => {
  const { coveragePage } = useContext(CoverageContext);

  return (
    <div className={styles.container}>
      <Alert
        appearance={coveragePage.type}
        title={coveragePage.title ?? ""}
        description={coveragePage.description}
        className={styles.coverageSuccess}
      />
    </div>
  );
};

export default CoverageNotification;
