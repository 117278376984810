export const getMortgageeClass = (index) => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    case 3:
      return "Quaternary";
    default:
      return `${index}th`;
  }
};
