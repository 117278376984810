import { useEffect, useState } from "react";

import { CONFIG } from "config";
import { logError } from "utils";

const THUMBTACK_PROS_ENDPOINT = `${CONFIG.THUMBTACK_API_HREF}/partners/discoverylite/pros`;

type ProInfo = {
  service_id: string;
  thumbtack_url: string;
  image_url: string;
  business_name: string;
  rating: string;
  num_reviews: number;
  pills: string[];
};

export function useThumbtackPros(
  zipCode: string,
  category: string
): { results: ProInfo[]; loading: boolean; error: boolean } {
  const [pros, setPros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (!category || !zipCode || zipCode.length < 5) {
      return;
    }
    const params = new URLSearchParams();
    params.append("utm_source", "cma-sagesure");
    params.append("zip_code", zipCode);
    params.append("category", category.toLowerCase());
    (async () => {
      try {
        setPros([]);
        setLoading(true);
        setError(false);
        const res = await fetch(
          `${THUMBTACK_PROS_ENDPOINT}?${params.toString()}`,
          {
            headers: {
              Authorization: `Basic ${CONFIG.THUMBTACK_AUTH}`,
            },
          }
        );
        setLoading(false);
        if (!res.ok) {
          setError(true);
          return;
        }
        const data = await res.json();
        setPros(data.results ?? []);
      } catch (err) {
        setLoading(false);
        setError(true);
        logError(`Use Thumbtack pros: ${err.message}`);
      }
    })();
  }, [category, zipCode]);
  return { results: pros, loading, error };
}
