import cx from "classnames";

import styles from "./form-footer-links.module.scss";

type FormFooterLinksProps = {
  centered?: boolean;
  className?: string;
  children?: React.ReactNode;
};

export const FormFooterLinks = ({
  centered,
  className,
  children,
  ...props
}: FormFooterLinksProps) => {
  return (
    <div
      className={cx(styles.formFooter, centered && styles.centered, className)}
      {...props}
    >
      {children}
    </div>
  );
};
