import classnames from "classnames";
import { createContext, useCallback, useContext, useState } from "react";

import { Heading, UiIcon } from "@icg360/design-system";

import { MainWithNoSideBarLayout } from "components/shared/main-with-no-sidebar-layout";
import Arrow from "images/icon-gallery-modal-arrow.svg";

import styles from "./gallery-modal.module.scss";

const GalleryContext = createContext();

const GalleryModal = ({ items, index, onClose }) => {
  let [currIndex, setCurrIndex] = useState(index);
  let { url, title } = items[currIndex];
  return (
    <div className={styles.galleryModal}>
      <MainWithNoSideBarLayout
        main={
          <div className={styles.frame}>
            <header className={styles.header}>
              <Heading size="md">{title}</Heading>
              <button
                className={styles.closeButton}
                onClick={onClose}
                data-testid="gallery-close-btn"
              >
                <UiIcon name="Close" />
              </button>
            </header>
            <section className={styles.content}>
              <div className={styles.imageContainer}>
                <img src={url} className={styles.image} alt={title} />
              </div>
            </section>
            <footer className={styles.controls}>
              <button
                className={classnames(styles.skipButton, styles.left)}
                disabled={currIndex === 0}
                onClick={() => setCurrIndex(currIndex - 1)}
                data-testid="gallery-left-btn"
              >
                <Arrow className={classnames(styles.arrow, styles.left)} />
              </button>
              <div className={styles.count}>
                {currIndex + 1}/{items.length}
              </div>
              <button
                className={styles.skipButton}
                disabled={currIndex === items.length - 1}
                onClick={() => setCurrIndex(currIndex + 1)}
                data-testid="gallery-right-btn"
              >
                <Arrow className={styles.arrow} />
              </button>
            </footer>
          </div>
        }
      />
    </div>
  );
};

export function useGalleryContext() {
  const ctx = useContext(GalleryContext);
  if (!ctx) {
    throw new Error(
      "useGalleryContext must be used within GalleryModalProvider"
    );
  }
  return ctx;
}

export const GalleryModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState({ items: [], index: 0 });
  const openGallery = useCallback((items, index) => {
    setContent({ items, index });
    setShow(true);
  });
  return (
    <GalleryContext.Provider value={{ openGallery }}>
      {show ? (
        <GalleryModal
          items={content.items}
          index={content.index}
          onClose={() => setShow(false)}
        />
      ) : (
        children
      )}
    </GalleryContext.Provider>
  );
};
