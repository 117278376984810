import { useMutation } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { addToast } from "@icg360/design-system";

import { AuthAppDispatchContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { MODALS } from "consts";
import { DecodeData } from "gql/__generated__/hooks";
import { decodeData as decodeDataMutation } from "gql/mutations";
import {
  KeystoneApiClient,
  isError,
  trackEvent,
  urlSearchParamsObject,
  useFlags,
} from "utils";

const { decodeData: restDecodeData } = new KeystoneApiClient(
  CONFIG.KEYSTONE_API_HREF
);

export const useAddPolicyDataFromEmailLink = () => {
  const { retireProxy, universalLogin } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const { addPopup } = useContext(AuthAppDispatchContext);
  const [decodeData] = useMutation(decodeDataMutation);
  const parsedQS = urlSearchParamsObject(location.search);
  const [addPolicyData, setAddPolicyData] = useState<{
    policyNumber: string;
    zipCode: string;
  }>();

  const decodeUserData = useCallback(
    async (encodedData: string): Promise<DecodeData> => {
      let responseData;
      if (retireProxy && universalLogin) {
        const res = await restDecodeData(encodedData);
        if (!isError(res)) responseData = res;
      } else {
        const fetchResult = await decodeData({
          variables: { data: encodedData },
        });
        responseData = fetchResult?.data?.decodeData;
      }

      return responseData;
    },
    [decodeData, retireProxy, universalLogin]
  );

  useEffect(() => {
    if (parsedQS.modal) {
      addPopup(MODALS.ADD_POLICY, true);

      if (parsedQS.data) {
        decodeUserData(parsedQS.data).then((data) => {
          const { policyId: policyNumber, zipcode: zipCode } = data;
          setAddPolicyData({
            policyNumber: policyNumber ?? "",
            zipCode: zipCode ?? "",
          });
        });
      }
      navigate(location.pathname);
    }
  }, []);

  return [addPolicyData];
};

export const useAddPolicy = (addPolicyData) => {
  const navigate = useNavigate();
  const { removePopup, refetchPolicies } = useContext(AuthAppDispatchContext);
  const [showAddPolicyModal, setShowAddPolicyModal] = useState(false);

  useEffect(() => {
    if (addPolicyData) {
      setShowAddPolicyModal(true);
    }
  }, [addPolicyData]);

  useEffect(() => {
    if (showAddPolicyModal) {
      if (addPolicyData) {
        trackEvent(
          "Add Policy Modal Displayed (email enrollment)",
          addPolicyData
        );
      } else {
        trackEvent("Add Policy Modal Displayed");
      }
    }
  }, [showAddPolicyModal, addPolicyData]);

  const openAddPolicyModal = () => {
    setShowAddPolicyModal(true);
    trackEvent("Add Policy Started");
  };

  const closeAddPolicyModal = () => {
    trackEvent("Add Policy Modal Closed");
    setShowAddPolicyModal(false);

    //Updates modal queue if add policy modal was opened as priority from email redirect
    if (addPolicyData) {
      removePopup(MODALS.ADD_POLICY);
    }
  };

  const handleAddPolicySucceeded = async ({ newPolicyId, zipCode, origin }) => {
    await refetchPolicies();

    setShowAddPolicyModal(false);

    addToast("Policy successfully added.", {
      icon: true,
      actionName: "View policy",
      action: () => navigate(`/my/overview?policyNumber=${newPolicyId}`),
    });

    trackEvent("Policy Added", {
      policyNumber: newPolicyId,
      zipCode: zipCode,
      addPolicyOrigin: origin,
    });
  };

  return {
    showAddPolicyModal,
    addPolicyData,
    openAddPolicyModal,
    closeAddPolicyModal,
    handleAddPolicySucceeded,
  };
};
