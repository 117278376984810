export const formatPhone = (phoneNumber: string): string => {
  if (!phoneNumber || typeof phoneNumber !== "string") {
    return "";
  }

  const arr = [
    phoneNumber.slice(0, 3),
    phoneNumber.slice(3, 6),
    phoneNumber.slice(6, 10),
  ];

  return arr.reduce((str, curr, idx) => {
    switch (idx) {
      case 0:
        return `${str}(${curr})`;
      case 1:
        return `${str} ${curr}`;
      case 2:
        return `${str}-${curr}`;
      default:
        return `${str}`;
    }
  }, "");
};
