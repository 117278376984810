import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import { addPolicySchema } from "consts";
import { addPolicyMutation } from "gql/mutations";
import { trackEvent } from "utils";

import { AddPolicyForm } from "./add-policy-form";

export type AddPolicyFormSchema = {
  policyNumber: string;
  zipCode: string;
};

type AddPolicyModalProps = {
  data: { policyNumber?: string; zipCode?: string };
  show: boolean;
  handleClose: () => void;
  onAddPolicySuccess: (newPolicy: {
    newPolicyId?: string;
    zipCode?: string;
    origin?: string;
  }) => void;
};

export const AddPolicyModal = ({
  data,
  show,
  handleClose,
  onAddPolicySuccess,
}: AddPolicyModalProps) => {
  const [addPolicy] = useMutation(addPolicyMutation);
  const { userInfo } = useContext(AuthAppContext);

  const handleSubmit = async (
    { policyNumber, zipCode },
    { resetForm, setStatus, setSubmitting }
  ) => {
    trackEvent("Add Policy Button Clicked");
    const {
      data: { addPolicy: response },
    } = await addPolicy({
      variables: {
        email: userInfo?.email,
        policyId: policyNumber,
        zipCode: zipCode,
      },
    });

    if (response.success) {
      await onAddPolicySuccess({
        newPolicyId: policyNumber,
        zipCode,
        origin: data ? "Add Policy Email Enrollment" : "Direct",
      });
      resetForm();
    } else {
      const errorMessage = response.errors[0];
      setStatus(errorMessage);

      trackEvent("Error Displayed (Add Policy)", {
        errorCode: response.errorCode,
        errorMessage,
      });
    }
    setSubmitting(false);
  };

  const initialValues: AddPolicyFormSchema = {
    policyNumber: data?.policyNumber ?? "",
    zipCode: data?.zipCode ?? "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={addPolicySchema}
    >
      <AddPolicyForm handleClose={handleClose} show={show} />
    </Formik>
  );
};
