import cx from "classnames";
import { LinkProps, Link as ReactRouterLink } from "react-router-dom";

import styles from "./mss-link.module.scss";

type MSSLinkProps = LinkProps & { newTab?: boolean };

export const MSSLink = ({
  children,
  className = "",
  newTab = false,
  to,
  ...props
}: MSSLinkProps) => {
  return (
    <ReactRouterLink
      className={cx(styles.link, className)}
      to={to}
      target={newTab ? "_blank" : undefined}
      rel={newTab ? "noopener" : undefined}
      {...props}
    >
      {children}
    </ReactRouterLink>
  );
};
