import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import {
  DataDefinition,
  DataList,
  DataRow,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { userClaimsConstantsProp, userDetailsProp } from "consts";
import { en as locale } from "locale";
import { capitalize, formatCurrency, formatDate } from "utils";

import styles from "./claim-details.module.scss";

const CLAIM_STATUSES = {
  Closed: "Closed",
  Open: "Open",
  ReOpen: "Reopened",
};

const ClaimDetails = ({ item, userDetails, userClaimsConstants }) => {
  const propertyAddress = userDetails?.propertyAddress ?? {};
  const { claim, submission } = item;

  const claimRenderer = () => (
    <Row>
      <Col md={6}>
        <div
          className={styles.claimTitle}
        >{`${locale.dataTable.claim} #${claim.ClaimNumber}`}</div>
        <DataList gap="xs">
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.dateOfLoss}
            </DataTerm>
            <DataDefinition>
              {claim.dateOfLossObject.format("MM/DD/YYYY")}
            </DataDefinition>
          </DataRow>
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.claimStatus}
            </DataTerm>
            <DataDefinition>{CLAIM_STATUSES[claim.Status]}</DataDefinition>
          </DataRow>
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.lossType}
            </DataTerm>
            <DataDefinition>{claim.LossCauseDescription}</DataDefinition>
          </DataRow>
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.description}
            </DataTerm>
            <DataDefinition>{claim.ClaimDescription}</DataDefinition>
          </DataRow>
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.claimsAdjuster}
            </DataTerm>
            <DataDefinition>{claim.IndependentAdjusterName}</DataDefinition>
          </DataRow>
          <DataRow flex="row">
            <DataTerm className={styles.claimDetailTerm}>
              {locale.formFields.claimsReview.phone}
            </DataTerm>
            <DataDefinition>
              {claim.IndependentAdjusterPhoneNumber}
            </DataDefinition>
          </DataRow>
        </DataList>
      </Col>
      <Col md={6} className={styles.claimPayments}>
        <div className={styles.claimTitle}>
          {locale.formFields.claimsReview.payments}
        </div>
        <DataList>
          {claim.payments &&
            claim.payments.map((payment, index) => (
              <DataRow flex="row" spaceBetween key={index}>
                <DataTerm>
                  <span className={styles.claimPaymentDate}>
                    {payment.paymentDate
                      ? formatDate(payment.paymentDate, "MMM DD, YYYY")
                      : "--"}
                  </span>
                </DataTerm>
                <DataDefinition>
                  {payment.paymentAmount
                    ? formatCurrency(payment.paymentAmount)
                    : "--"}
                </DataDefinition>
              </DataRow>
            ))}{" "}
        </DataList>
      </Col>
    </Row>
  );

  const submissionRenderer = () => {
    const policyholderName =
      userClaimsConstants?.PolicyHolderDetails?.name ?? "";
    const policyholderPhoneNumber =
      userClaimsConstants?.PolicyHolderDetails?.phoneNumber ?? "";

    return (
      <>
        <div
          className={styles.claimTitle}
        >{`${locale.dataTable.submission} #${submission.SubmissionNumber}`}</div>
        <DataList gap="xs">
          <InlineDataRow>
            <DataTerm>{locale.formFields.claimsReview.dateOfLoss}:</DataTerm>
            <DataDefinition>
              {submission.dateOfLossObject.hours() === 0 &&
              submission.dateOfLossObject.minutes() === 0
                ? submission.dateOfLossObject.format("MM/DD/YYYY")
                : submission.dateOfLossObject.format("MM/DD/YYYY [at] hh:mm A")}
            </DataDefinition>
          </InlineDataRow>
          <InlineDataRow>
            <DataTerm>{locale.formFields.claimsReview.location}:</DataTerm>
            {submission.LossAtInsuredProperty ? (
              <DataDefinition>
                <span>{propertyAddress.street1} </span>
                {propertyAddress.street2 && (
                  <span>{propertyAddress.street2}, </span>
                )}
                <span>
                  {propertyAddress.city}, {propertyAddress.state}{" "}
                  {propertyAddress.zip}
                </span>
              </DataDefinition>
            ) : (
              <DataDefinition>
                <span>{submission.LocationOfLoss.Address} </span>
                <span>{submission.LocationOfLoss.AddressLine2}, </span>
                <span>
                  {submission.LocationOfLoss.City},{" "}
                  {submission.LocationOfLoss.State}{" "}
                  {submission.LocationOfLoss.Zip}
                </span>
              </DataDefinition>
            )}
          </InlineDataRow>
          <InlineDataRow>
            <DataTerm>{locale.formFields.claimsReview.typeOfLoss}:</DataTerm>
            <DataDefinition>{submission.TypeOfLoss}</DataDefinition>
          </InlineDataRow>
          <InlineDataRow>
            <DataTerm>
              {locale.formFields.claimsReview.lossDescription}:
            </DataTerm>
            <span>{submission.LossDescription}</span>
          </InlineDataRow>
          <InlineDataRow>
            <DataTerm>
              {locale.formFields.claimsReview.claimSubmittedBy}:
            </DataTerm>
            {submission.ClaimSubmittedByPolicyholder ? (
              <DataDefinition>
                <span>
                  {`${policyholderName}, ${locale.formFields.claimsReview.policyHolder} `}
                </span>
                <ul className={styles.subList}>
                  <li>
                    <span>{locale.formFields.claimsReview.phone}: </span>
                    <span> {policyholderPhoneNumber}</span>
                  </li>
                </ul>
              </DataDefinition>
            ) : (
              <DataDefinition>
                <span>
                  {`${submission.ClaimSubmittedBy.Name}, ${capitalize(
                    submission.ClaimSubmittedBy.Relationship
                  )} `}
                </span>
                <ul className={styles.subList}>
                  <li>
                    <span>
                      {submission.ClaimSubmittedBy.PrimaryPhoneNumberType}:{" "}
                    </span>
                    <span>
                      {submission.ClaimSubmittedBy.PrimaryPhoneNumber}{" "}
                    </span>
                  </li>

                  {submission.ClaimSubmittedBy.AlternatePhoneNumber && (
                    <li>
                      <span>
                        {submission.ClaimSubmittedBy.AlternatePhoneNumberType}:{" "}
                      </span>
                      <span>
                        {submission.ClaimSubmittedBy.AlternatePhoneNumber}
                      </span>
                    </li>
                  )}
                </ul>
              </DataDefinition>
            )}
          </InlineDataRow>
          <InlineDataRow>
            <DataTerm>
              {locale.formFields.claimsReview.contactInformation}:
            </DataTerm>
            <DataDefinition>
              <span>
                {`${submission.ContactInfo.Name}, ${capitalize(
                  submission.ContactInfo.Relationship
                )} `}
              </span>
              <ul className={styles.subList}>
                <li>
                  <span>{submission.ContactInfo.PrimaryPhoneNumberType}:</span>
                  <span> {submission.ContactInfo.PrimaryPhoneNumber} </span>
                </li>

                {submission.ContactInfo.AlternatePhoneNumber && (
                  <li>
                    <span>
                      {submission.ContactInfo.AlternatePhoneNumberType}:{" "}
                    </span>
                    <span>{submission.ContactInfo.AlternatePhoneNumber} </span>
                  </li>
                )}
                {submission.ContactInfo.WantsToReceiveSMSUpdates && (
                  <li>
                    <span>
                      {locale.formFields.claimsForm.WantsToReceiveSMSUpdates}{" "}
                    </span>
                  </li>
                )}
                <li>
                  <span>{locale.formFields.claimsReview.email}: </span>
                  <span>{submission.ContactInfo.Email}</span>
                </li>
              </ul>
            </DataDefinition>
          </InlineDataRow>
        </DataList>
      </>
    );
  };

  return (
    <>
      {claim && claimRenderer()}
      {submission && submissionRenderer()}
    </>
  );
};

ClaimDetails.propTypes = {
  item: PropTypes.object,
  userDetails: PropTypes.shape(userDetailsProp),
  userClaimsConstants: PropTypes.shape(userClaimsConstantsProp),
};

export default ClaimDetails;
