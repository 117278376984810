import { Heading, Spinner } from "@icg360/design-system";

import * as styles from "./logging-out.module.scss";

const LoggingOut = () => {
  return (
    <div className={styles.loggingOutWrapper}>
      <Spinner />
      <Heading size="lg">Logging you out...</Heading>
    </div>
  );
};

export default LoggingOut;
