import { ReactElement, useState } from "react";
import { Container } from "react-bootstrap";
import MediaQuery from "react-responsive";

import { ContactUsModal } from "components/login/contact-us/contact-us-modal";
import { HeaderLogo } from "components/shared/logo-link";
import NavMenu from "components/shared/nav-menu";
import { trackEvent } from "utils";

import styles from "./unauth-header.module.scss";

type UnauthHeaderProps = {
  includeNav?: boolean;
  rightElement?: ReactElement;
};

export const UnauthHeader = ({
  includeNav,
  rightElement,
}: UnauthHeaderProps) => {
  const [showModal, setShowModal] = useState(false);

  const navItems = [
    {
      title: "Make a payment",
      to: "/payment",
      onClick: () => trackEvent("landingV2_nav_makePaymentClicked"),
    },
    {
      title: "Claims",
      to: "/claims-help",
      onClick: () => trackEvent("landingV2_nav_claimsClicked"),
    },
    {
      title: "Contact us",
      to: "",
      onClick: () => {
        trackEvent("landingV2_nav_contactClicked");
        setShowModal(true);
      },
    },
  ];

  return (
    <div className={styles.headerWrapper}>
      <Container className={styles.headerContainer}>
        <div className={styles.primaryHeader}>
          <MediaQuery minWidth={580}>
            {(matches) => <HeaderLogo logo={matches ? "default" : "icon"} />}
          </MediaQuery>
          {includeNav ? (
            <NavMenu navItems={navItems} className={styles.navMenuWrapper} />
          ) : null}
        </div>
        <div className={styles.rightElement}>{rightElement}</div>
      </Container>
      <ContactUsModal
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
};
