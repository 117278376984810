import moment from "moment";

export const daysUntil = (futureDate: string | Date): number => {
  const futureDateMoment = moment(futureDate).startOf("day");
  const todayMoment = moment().startOf("day");
  if (!futureDateMoment.isValid() || futureDateMoment.isBefore(todayMoment)) {
    return 0;
  }
  return futureDateMoment.diff(todayMoment, "days");
};
