import { useMutation } from "@apollo/client";
import moment from "moment";
import { useContext } from "react";

import { PaymentButton } from "components/payment-button";
import { AuthAppContext } from "components/root/auth-app-provider";
import { PAYMENT_METHOD_CODE, paymentPlans } from "consts";
import { resetPolicyMutation } from "gql/mutations";
import { en as locale } from "locale";
import {
  PaymentConfig,
  acceptPaymentOnCancelledPolicy,
  formatPhone,
  getBillingAddressRaw,
  getPaymentMethodDetails,
  isFormattedPhone,
  isPaymentDue,
  logError,
  trackEvent,
} from "utils";

const PaymentSection = () => {
  const {
    selectedPolicyId,
    userDetails,
    userBilling,
    userInfo,
    userInsuranceRep,
    userCarrierInfo,
  } = useContext(AuthAppContext);
  const [resetPolicy] = useMutation(resetPolicyMutation);

  const { accounting, mortgagees } = userBilling ?? {};
  const {
    dueDate,
    minimumPayment,
    paymentPlan,
    remainingBalance,
    totalBalance,
  } = accounting ?? {};

  const {
    currentTerm,
    mailingAddress,
    keystonePolicyId,
    quoteNumber,
    isCommercial,
    policyStatus,
    policyStateAttributes,
  } = userDetails ?? {};
  const {
    policyNumber,
    agencyLocationCode,
    effectiveDatePolicyTerm,
    totalPremium,
  } = currentTerm ?? {};

  const agentPhone = userInsuranceRep?.agentPhone ?? "";

  const insuranceRepPhone = isFormattedPhone(agentPhone)
    ? agentPhone
    : formatPhone(agentPhone);

  const [firstMortgagee] = mortgagees ?? [];
  const billingAddressRaw = getBillingAddressRaw(
    paymentPlan?.planType,
    firstMortgagee,
    mailingAddress
  );

  const { paymentMethod: currentPaymentMethod } = getPaymentMethodDetails(
    paymentPlan?.planType?.toLowerCase()
  );

  const isEasypay = currentPaymentMethod.includes(PAYMENT_METHOD_CODE.EASYPAY);
  const isInvoicePay = currentPaymentMethod.includes(
    PAYMENT_METHOD_CODE.INVOICE
  );

  const isTenPay = paymentPlans[paymentPlan?.planType?.toLowerCase()].includes(
    paymentPlans.tenpay
  );

  const hasSurplusFee = userCarrierInfo?.id === "SURE";

  if (isCommercial && isTenPay) {
    return <p>{locale.info.bop10PayInfo}</p>;
  }

  const onPaymentComplete = (response) => {
    trackEvent("Submit Payment", response);
    resetPolicy({ variables: { policyID: selectedPolicyId } });
  };

  const minDueInt = Number(minimumPayment);

  const paymentDueOnActivePolicy =
    isPaymentDue(remainingBalance, totalBalance) && !isInvoicePay && !isEasypay;

  const paymentDueOnCancelledPolicy = acceptPaymentOnCancelledPolicy(
    policyStatus,
    policyStateAttributes?.reasonCode?.value ?? "",
    effectiveDatePolicyTerm,
    minDueInt
  );

  const noPaymentMessage = (
    <p>
      {isEasypay
        ? locale.info.automaticallyWithdrawn
        : isInvoicePay
        ? hasSurplusFee
          ? locale.info.grandTotal
          : locale.info.premium
        : locale.info.noPaymentDue}
    </p>
  );

  const tomorrow = moment().utc().add(1, "days");
  const paymentDueDate =
    dueDate === "No Upcoming Payment"
      ? moment().utc().add(30, "days")
      : moment(Number(dueDate)).utc().add(3, "days");
  const finalPayDate =
    paymentDueDate < tomorrow
      ? tomorrow.format("YYYY-MM-DD")
      : paymentDueDate.format("YYYY-MM-DD");
  const accountBalance = Number(
    paymentDueOnCancelledPolicy ? totalPremium : totalBalance
  );
  const minAmountDue = Math.max(1, Number(minimumPayment));

  const payConfig: PaymentConfig = {
    accountBalance,
    agencyLocationCode,
    finalPayDate,
    minAmountDue,
    billingCity: billingAddressRaw.billingCity,
    billingState: billingAddressRaw.billingState,
    billingStreet: billingAddressRaw.billingStreet,
    billingZip: billingAddressRaw.billingZip,
    email: userInfo?.email ?? "",
    firstName: userInfo?.firstName ?? "",
    insightPolicyId: keystonePolicyId ?? "",
    isScheduledPayEnabled: true,
    lastName: userInfo?.lastName ?? "",
    paymentApplicationClient: "2000",
    phone: insuranceRepPhone,
    policyId: policyNumber ?? "",
    quoteId: quoteNumber ?? "",
  };

  return paymentDueOnCancelledPolicy || paymentDueOnActivePolicy ? (
    <div data-testid="pay-btn">
      <PaymentButton
        payment={payConfig}
        onPaymentError={(error) => {
          const errorMessage = error?.message ?? "Unknown Error";
          logError(`Payment Error: (Payment Section): ${errorMessage}`);
          trackEvent("Payment Error", { errorMessage });
        }}
        onPaymentComplete={(response) => onPaymentComplete(response)}
        onPaymentStart={() => trackEvent("Start Making Payment")}
        onScheduledPaymentCreated={() =>
          trackEvent("Scheduled Payment Created")
        }
      >
        Make a payment
      </PaymentButton>
    </div>
  ) : (
    noPaymentMessage
  );
};

export default PaymentSection;
