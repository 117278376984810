import { UiIconNames } from "@icg360/design-system";

import { en as locale } from "locale";

type NavElement = {
  to: string;
  title: string;
  iconName: UiIconNames;
};

export const navElements: NavElement[] = [
  {
    to: "/my/overview",
    title: locale.titles.overview,
    iconName: "Overview",
  },
  {
    to: "/my/billing",
    title: locale.titles.billing,
    iconName: "Money",
  },
  {
    to: "/my/documents",
    title: locale.titles.documents,
    iconName: "Document",
  },
  {
    to: "/my/claims",
    title: locale.titles.claims,
    iconName: "Claims",
  },
  {
    to: "/my/property",
    title: locale.titles.property,
    iconName: "Home",
  },
  {
    to: "/my/support",
    title: locale.titles.support,
    iconName: "Support",
  },
];
