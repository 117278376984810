import { useContext, useState } from "react";

import { MarketingIcon, Span } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { en as locale } from "locale";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";

import PaymentItem from "./payment-item";
import styles from "./payment.module.scss";

const Payment = () => {
  const [showEasyPayBanner, setEasyPayBanner] = useState(false);
  const { policies } = useContext(AuthAppContext);

  const banner = showEasyPayBanner ? <EasyPayBanner /> : null;

  const policyItems = policies?.map((policy) => (
    <PaymentItem
      key={policy?.policyId}
      policy={policy}
      setEasyPayBanner={setEasyPayBanner}
    />
  ));

  return (
    <SettingsPanelLayout
      title="Payment"
      banner={banner}
      policyItems={policyItems}
    />
  );
};

export default Payment;

const EasyPayBanner = () => (
  <div className={styles.easyPayContainer}>
    <div>
      <MarketingIcon name="Money" />
    </div>
    <Span>{locale.settingsPage.easyPayCTA}</Span>
  </div>
);
