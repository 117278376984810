import cx from "classnames";
import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";

import styles from "./nav-menu.module.scss";

type NavItem = {
  to: string;
  title: string;
  onClick?: MouseEventHandler;
};

type NavMenuProps = {
  navItems: NavItem[];
  className?: string;
};

const NavMenu = ({ navItems, className }: NavMenuProps) => {
  return (
    <nav className={cx(styles.menuItems, className)}>
      {navItems.map((element, index) => (
        <NavLink
          key={index}
          to={element.to}
          className={({ isActive }) => {
            return cx(styles.menuItem, {
              [styles.menuItemSelected]: isActive && element.to,
            });
          }}
          onClick={element.onClick}
          data-testid={`nav-menu-item-${element.title
            .toLowerCase()
            .replaceAll(" ", "-")}`}
        >
          {element.title}
        </NavLink>
      ))}
    </nav>
  );
};

export default NavMenu;
