/* eslint-disable */
import { CONFIG } from "config";

const GOOGLE_ANALYTICS_ID = "G-H3PQ7WRDC6";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  const numParts = 2;
  if (parts.length === numParts) {
    return parts.pop().split(";").shift();
  }
  return null;
}
const SHOULD_TRACK = getCookie("DoNotTrack") !== "true";

// Marketing is using Google Analytics, so don't remove this
// Global site tag (gtag.js) - Google Analytics
if (SHOULD_TRACK && CONFIG.APP_ENV === "production") {
  const script = document.createElement("script");
  script.setAttribute("async", "true");
  script.setAttribute(
    "src",
    `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`
  );
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  gtag("js", new Date());
  gtag("config", GOOGLE_ANALYTICS_ID);
}

function gtag() {
  window.dataLayer.push(arguments);
}

// QUALTRICS Contact Frequency Rules for Intercepts
let emailAddress = "";
const usernameCookie = getCookie("mss-username");

//Encoding pattern seems to have changed
//Adding try/catch to make sure legacy implementation doesn't break
if (usernameCookie) {
  try {
    emailAddress = atob(usernameCookie.slice(0, -3) + "=");
  } catch (e) {
    emailAddress = atob(decodeURIComponent(usernameCookie));
  }
}
if (typeof window.QSI === "undefined") {
  window.QSI = {};
  window.QSI.config = {
    externalReference: emailAddress,
  };
}

// BEGIN QUALTRICS WEBSITE FEEDBACK SNIPPET
(function () {
  var g = function (e, h, f, g) {
    this.get = function (a) {
      for (
        var a = a + "=", c = document.cookie.split(";"), b = 0, e = c.length;
        b < e;
        b++
      ) {
        for (var d = c[b]; " " == d.charAt(0); ) d = d.substring(1, d.length);
        if (0 == d.indexOf(a)) return d.substring(a.length, d.length);
      }
      return null;
    };
    this.set = function (a, c) {
      var b = "",
        b = new Date();
      b.setTime(b.getTime() + 6048e5);
      b = "; expires=" + b.toGMTString();
      document.cookie = a + "=" + c + b + "; path=/; ";
    };
    this.check = function () {
      var a = this.get(f);
      if (a) a = a.split(":");
      else if (100 != e)
        "v" == h && (e = Math.random() >= e / 100 ? 0 : 100),
          (a = [h, e, 0]),
          this.set(f, a.join(":"));
      else return !0;
      var c = a[1];
      if (100 == c) return !0;
      switch (a[0]) {
        case "v":
          return !1;
        case "r":
          return (
            (c = a[2] % Math.floor(100 / c)),
            a[2]++,
            this.set(f, a.join(":")),
            !c
          );
      }
      return !0;
    };
    this.go = function () {
      if (this.check()) {
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.src = g;
        document.body && document.body.appendChild(a);
      }
    };
    this.start = function () {
      var t = this;
      "complete" !== document.readyState
        ? window.addEventListener
          ? window.addEventListener(
              "load",
              function () {
                t.go();
              },
              !1
            )
          : window.attachEvent &&
            window.attachEvent("onload", function () {
              t.go();
            })
        : t.go();
    };
  };
  try {
    new g(
      100,
      "r",
      "QSI_S_ZN_4SFAITUAxK8EvIy",
      "https://zn4sfaituaxk8eviy-sagesure.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_4SFAITUAxK8EvIy"
    ).start();
  } catch (i) {}
})();

// Mixpanel
if (SHOULD_TRACK) {
  (function (e, a) {
    if (!a.__SV) {
      var b = window;
      try {
        var c,
          l,
          i,
          j = b.location,
          g = j.hash;
        c = function (a, b) {
          return (l = a.match(RegExp(b + "=([^&]*)"))) ? l[1] : null;
        };
        g &&
          c(g, "state") &&
          ((i = JSON.parse(decodeURIComponent(c(g, "state")))),
          "mpeditor" === i.action &&
            (b.sessionStorage.setItem("_mpcehash", g),
            history.replaceState(
              i.desiredHash || "",
              e.title,
              j.pathname + j.search
            )));
      } catch (m) {}
      var k, h;
      window.mixpanel = a;
      a._i = [];
      a.init = function (b, c, f) {
        function e(b, a) {
          var c = a.split(".");
          2 == c.length && ((b = b[c[0]]), (a = c[1]));
          b[a] = function () {
            b.push([a].concat(Array.prototype.slice.call(arguments, 0)));
          };
        }
        var d = a;
        "undefined" !== typeof f ? (d = a[f] = []) : (f = "mixpanel");
        d.people = d.people || [];
        d.toString = function (b) {
          var a = "mixpanel";
          "mixpanel" !== f && (a += "." + f);
          b || (a += " (stub)");
          return a;
        };
        d.people.toString = function () {
          return d.toString(1) + ".people (stub)";
        };
        k =
          "disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(
            " "
          );
        for (h = 0; h < k.length; h++) e(d, k[h]);
        a._i.push([b, c, f]);
      };
      a.__SV = 1.2;
      b = e.createElement("script");
      b.type = "text/javascript";
      b.async = !0;
      b.src =
        "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL
          ? MIXPANEL_CUSTOM_LIB_URL
          : "file:" === e.location.protocol &&
            "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)
          ? "https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js"
          : "//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";
      c = e.getElementsByTagName("script")[0];
      c.parentNode.insertBefore(b, c);
    }
  })(document, window.mixpanel || []);
  window.mixpanel.init(CONFIG.MIXPANEL_TOKEN, {
    cross_subdomain_cookie: false,
    persistence: "localStorage",
  });

  // Track all link and button clicks
  const appContainer = document.querySelector("#app");
  appContainer?.addEventListener("click", (e) => {
    if (e.target.tagName === "BUTTON") {
      const id = e.target.dataset.testid ?? e.target.innerText;
      window.mixpanel.track("user_click", { id, element: "button" });
    }

    if (e.target.tagName === "A") {
      const href = e.target.getAttribute("href");
      const url = new URL(href, window.location.origin);
      if (url.host !== window.location.host) {
        window.mixpanel.track("external_link", { url: href });
      } else {
        const id = e.target.dataset.testid ?? e.target.innerText;
        window.mixpanel.track("user_click", { id, element: "anchor" });
      }
    }
  });

  // Track page reloads
  window.addEventListener("load", () => {
    const isReload =
      window.performance
        .getEntriesByType("navigation")
        .filter((entry) => entry.type === "reload").length > 0;
    if (isReload) {
      window.mixpanel.track("page_reload");
    }
  });
}
