import { useCallback, useContext, useEffect } from "react";

import { AuthAppDispatchContext } from "components/root/auth-app-provider";
import { MODALS } from "consts";
import {
  getPaymentMethodDetails,
  isEasyPayEligible,
  isEasyPayEnrolled,
  modalCookieExists,
  scrollToTop,
  shouldDisplayPaperlessOptionsModal,
  trackEvent,
} from "utils";

export const usePaperlessEnrollmentModalOnUnmount = (userDetails) => {
  const { addPopup } = useContext(AuthAppDispatchContext);

  useEffect(() => {
    return () => {
      if (
        modalCookieExists(MODALS.EASYPAY_ENROLLMENT_MODAL) &&
        !modalCookieExists(MODALS.PAPERLESS_MODAL) &&
        shouldDisplayPaperlessOptionsModal(userDetails)
      ) {
        addPopup(MODALS.PAPERLESS_MODAL);
      }
    };
  }, [addPopup, userDetails]);
};

export const useCurrentPaymentMethod = (userBilling, userDetails) => {
  const enrolledInEasyPay = isEasyPayEnrolled(userBilling);

  const eligibleForEasyPayUpdate =
    isEasyPayEligible(userBilling, userDetails) || enrolledInEasyPay;

  const { paymentMethodSummaryLabel, easypayMethod, paymentFrequency } =
    getPaymentMethodDetails(
      userBilling?.accounting.paymentPlan.planType.toLowerCase()
    ) ?? {};

  const trackEasyPayEvent = useCallback(
    (eventName, optionalProperties = {}) =>
      trackEvent(eventName, {
        paymentMethod: paymentMethodSummaryLabel,
        easypayMethod,
        paymentFrequency,
        ...optionalProperties,
      }),
    [easypayMethod, paymentFrequency, paymentMethodSummaryLabel]
  );

  useEffect(() => {
    scrollToTop();
    trackEasyPayEvent("EasyPay Edit Form Loaded");
  }, []);

  return { eligibleForEasyPayUpdate, trackEasyPayEvent };
};
