import { Formik } from "formik";
import { useContext } from "react";
import { object, string } from "yup";

import { addToast } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { validatePasswordFormik } from "consts";
import {
  useAuthenticateUserMutation,
  useUpdateInsuredInfoMutation,
} from "gql/__generated__/hooks";
import { logError, trackEvent } from "utils";

import ChangePasswordModalForm from "./change-password-modal-form";
import styles from "./change-password-modal.module.scss";

const schema = object().shape({
  currentPassword: string().required("Current password required."),
});

const changePasswordAuthError = (
  <>
    The password you entered does not match our records. Try again, or{" "}
    <MSSLink to="/request-reset" className={styles.alertLink}>
      reset your password
    </MSSLink>{" "}
    via email.
  </>
);
const changePasswordError = (
  <>
    An error occurred. Please try again, or{" "}
    <MSSLink to="/request-reset" className={styles.alertLink}>
      reset your password
    </MSSLink>{" "}
    via email.
  </>
);

const initialValues = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
};

export type ChangePasswordModalSchema = typeof initialValues;

type ChangePasswordModalProps = {
  show: boolean;
  handleClose: () => void;
  userEmail?: string;
  userName?: string;
};

const ChangePasswordModal = ({
  show,
  handleClose,
  userEmail,
  userName,
}: ChangePasswordModalProps) => {
  const { selectedPolicyId } = useContext(AuthAppContext);
  const [authenticateUserPassword] = useAuthenticateUserMutation();
  const [updatePassword] = useUpdateInsuredInfoMutation({
    context: {
      clientName: "keystone-api",
    },
  });

  const handleSubmit = async (
    { currentPassword, password },
    { setSubmitting, setStatus, resetForm, setFieldValue, setFieldTouched }
  ) => {
    try {
      setSubmitting(true);
      setStatus(null);

      const { data: authenticateUserPasswordData } =
        await authenticateUserPassword({
          variables: {
            username: userEmail || userName || "",
            password: currentPassword,
          },
        });

      if (!authenticateUserPasswordData?.authenticateUser.success) {
        trackEvent("Error Displayed (Change Password)", {
          errors: authenticateUserPasswordData?.authenticateUser.errors,
        });
        setStatus(changePasswordAuthError);
        setSubmitting(false);
        setFieldValue("currentPassword", "");
        setFieldTouched("currentPassword", false);
        return;
      }

      const username = userEmail ? "" : userName;

      const { data: updatePasswordData, errors: updatePasswordErrors } =
        await updatePassword({
          variables: {
            policyID: selectedPolicyId,
            emailAddress: userEmail,
            username,
            userUpdates: {
              password,
            },
          },
        });

      if (updatePasswordData?.updateInsuredInfo) {
        trackEvent("Update password - reset complete");
        addToast("Password has been successfully updated.", { icon: true });
        handleClose();
        resetForm();
      } else {
        setStatus(changePasswordError);
        trackEvent("Error Displayed (Change Password)", {
          errors: updatePasswordErrors,
        });
      }

      setSubmitting(false);
    } catch (err) {
      setStatus(changePasswordError);
      trackEvent("Error Displayed (Change Password)", {
        error: err,
      });
      setSubmitting(false);
      logError(`Change password: ${err.message}`);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validatePasswordFormik}
      validationSchema={schema}
      enableReinitialize
    >
      <ChangePasswordModalForm show={show} handleClose={handleClose} />
    </Formik>
  );
};

export default ChangePasswordModal;
