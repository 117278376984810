import { useReducer } from "react";

import { useFlags } from "utils";

export const actionTypes = {
  coverages: "COVERAGES",
  review: "REVIEW",
};

export function stepsReducer(state, action) {
  switch (action.type) {
    case actionTypes.coverages: {
      return { step: actionTypes.coverages };
    }
    case actionTypes.review: {
      return { step: actionTypes.review };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}

export function useSteps({ reducer = stepsReducer } = {}) {
  const [{ step }, dispatch] = useReducer(reducer, {
    step: actionTypes.coverages,
  });

  const setCoverageStep = () => dispatch({ type: "COVERAGES" });
  const setReviewStep = () => dispatch({ type: "REVIEW" });

  return { step, setCoverageStep, setReviewStep };
}

export type CoverageError = {
  set: boolean;
  type: "minimum" | "prorated" | "unset" | "default";
  title: string;
  description: string;
};

export const useError = () => {
  const { supportPhoneNumber } = useFlags();
  const errorReducer = (state, action): CoverageError => {
    switch (action.type) {
      case "minimum": {
        return {
          set: true,
          type: "minimum",
          title: "Your cart is empty.",
          description: "Please select coverages to continue.",
        };
      }

      case "prorated": {
        return {
          set: true,
          type: "prorated",
          title: "Something went wrong.",
          description: `We are not able to show prices for the coverages you selected. Please try again or contact customer service at ${supportPhoneNumber}.`,
        };
      }

      case "unset": {
        return {
          set: false,
          type: "unset",
          title: "",
          description: "",
        };
      }

      default: {
        return {
          set: true,
          type: "default",
          title: "Something went wrong.",
          description: `If the issue persists, contact customer service at ${supportPhoneNumber}.`,
        };
      }
    }
  };

  const [error, dispatchError] = useReducer(errorReducer, {
    set: false,
    type: "unset",
    title: "",
    description: "",
  });

  return { error, dispatchError };
};
