import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import { useContext } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { addToast } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { AuthAppContext } from "components/root/auth-app-provider";
import { type PropertyProfileLoaderData } from "components/root/router";
import { PROPERTY_ROOF_CODE_MAP, updateRoofSchema } from "consts";
import {
  useSendPropertyProfileUpdateNotificationMutation,
  useUserPropertyProfileDataQuery,
} from "gql/__generated__/hooks";
import { logError, trackEvent, useFlags } from "utils";

export type RoofFormValues = {
  constructionYearRoof: string;
  roofCoveringType: string;
};

const UpdateRoof = () => {
  const navigate = useNavigate();
  const { universalLogin, retireProxy } = useFlags();
  const loaderData = useLoaderData() as PropertyProfileLoaderData;

  const { selectedPolicyId, userInfo } = useContext(AuthAppContext);
  const { data: propertyProfileData } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });

  const { roofCoveringType, constructionYearRoof } =
    universalLogin && retireProxy
      ? loaderData?.propertyProfile ?? {}
      : propertyProfileData?.userPropertyProfileData ?? {};

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();

  const initialValues: RoofFormValues = {
    constructionYearRoof: "",
    roofCoveringType: roofCoveringType
      ? PROPERTY_ROOF_CODE_MAP[roofCoveringType]
      : null,
  };

  const handleSubmit = async (
    { constructionYearRoof, roofCoveringType },
    { setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      trackEvent("Property roof - Updates submitted", {
        submittedRoofAge: constructionYearRoof,
        submittedRoofMaterial: roofCoveringType,
      });
      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates: {
            roofCoveringType,
            constructionYearRoof,
          },
        },
        context: {
          clientName: "keystone-api",
        },
      });
      if (data) {
        trackEvent("Property roof - Update completed");
        addToast(
          "Your update has been submitted. We will send you an email with next steps.",
          { icon: true, duration: 10000 }
        );
        navigate("/my/property");
      }
    } catch (error) {
      logError(`Update roof: ${error.message}`);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property roof - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };
  const validate = (values) => {
    try {
      validateYupSchema(values, updateRoofSchema(constructionYearRoof), true);
    } catch (error) {
      return { ...yupToFormErrors(error) };
    }
  };
  return (
    <BaseLayout hideNavigation>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        <Outlet />
      </Formik>
    </BaseLayout>
  );
};

export default UpdateRoof;
