import { Button, Heading, List } from "@icg360/design-system";

import styles from "./leakbot.module.scss";

const LeakbotHome = ({ onNext, onBack }) => (
  <>
    <div className={styles.lbNonCardLayout}>
      <div className={styles.lbNonCardMainContent}>
        <Heading size="lg">Protect your home with LeakBot.</Heading>
        <p>
          SageSure is proud to provide you with a{" "}
          <b>free LeakBot smart water detector</b> which spots leaks early
          before they become bigger problems. As a trusted partner in protecting
          one of your biggest investments, SageSure is exploring new
          technologies that can help prevent damage to your home. This pilot
          program is available in your area for a limited time.
        </p>
        <Heading size="sm">What is Leakbot?</Heading>
        <p>
          LeakBot is a device that clips to your main water pipe and monitors
          water activity throughout your home. As soon as LeakBot spots a
          problem, you’ll be notified in your LeakBot app. Your app will also
          provide instructions to help fix the issue or connect you with an
          expert plumber for service.
        </p>
        <Heading size="sm">LeakBot is fast and easy to set up.</Heading>
        <List>
          <li>Five-minute setup - no tools or plumbing knowledge required.</li>
          <li>
            Download the free LeakBot app for automatic status updates and
            notifications.
          </li>
          <li>
            Includes a <b>complimentary plumber visit</b> if your LeakBot
            detects a problem that you can’t fix.
          </li>
        </List>
        <div className={styles.lbNav}>
          <Button
            appearance="tertiary"
            onClick={onBack}
            data-testid="LeakbotBackBtn"
          >
            Go back
          </Button>
          <Button onClick={onNext} data-testid="LeakbotStartBtn">
            Get my LeakBot
          </Button>
        </div>
      </div>
      <div>
        <img
          src="/assets/images/leakbot-price-card.png"
          alt="a cost breakdown showing that leakbot is free"
        />
      </div>
    </div>
  </>
);

export default LeakbotHome;
