import { Card } from "@icg360/design-system";

import { ClaimsPageDataQuery } from "gql/__generated__/hooks";

import ClaimsList from "./claims-list";
import ClaimsTrackerList from "./claims-tracker-list";

type ClaimActivityProps = {
  userClaimsList: ClaimsPageDataQuery["userClaimsList"];
  userClaimsConstants: ClaimsPageDataQuery["userClaimsConstants"];
  claimTrackedInternally: boolean;
};

const ClaimActivity = ({
  userClaimsList,
  userClaimsConstants,
  claimTrackedInternally,
}: ClaimActivityProps) => {
  const renderClaimsView = () => {
    // New Claims using @icg360/claim-tracker
    if (userClaimsList?.ClaimsTrackerInfo) {
      return (
        <ClaimsTrackerList
          claims={userClaimsList.ClaimsTrackerInfo}
          claimTrackedInternally={claimTrackedInternally}
        />
      );
    }

    // Old claims using MSS specific ClaimsList
    if (userClaimsList?.ClaimsInfo || userClaimsList?.SubmissionsInfo) {
      return (
        <ClaimsList
          userClaimsList={userClaimsList}
          userClaimsConstants={userClaimsConstants}
        />
      );
    }
  };

  if (
    userClaimsList?.ClaimsTrackerInfo ||
    userClaimsList?.ClaimsInfo ||
    userClaimsList?.SubmissionsInfo
  ) {
    return <Card title="Activity">{renderClaimsView()}</Card>;
  }

  return null;
};

export default ClaimActivity;
