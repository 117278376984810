import { capitalizeEachWord } from "./";

export type Address = {
  street1?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
};

// If these values are actually missing, it won't display well, but it won't die...
export const formatAddress = (
  addressObject?: Address | null,
  initialCaps = false
): string => {
  if (!addressObject) {
    return "";
  }
  const { state, zip } = addressObject;
  let formattedStreet1 = addressObject?.street1 ?? "";
  let formattedStreet2 = addressObject?.street2 ?? "";
  let formattedCity = addressObject?.city ?? "";

  if (initialCaps) {
    formattedStreet1 = capitalizeEachWord(formattedStreet1 ?? undefined);
    formattedStreet2 = formattedStreet2 && capitalizeEachWord(formattedStreet2);
    formattedCity = capitalizeEachWord(formattedCity ?? undefined);
  }

  return `${formattedStreet1}, ${
    formattedStreet2?.trim() && `${formattedStreet2}, `
  }${formattedCity}, ${state} ${zip}`;
};

type FormattedTwoLineAddress = {
  lineOne: string;
  lineTwo: string;
};

export const formatTwoLineAddress = (
  addressObject: Address,
  initialCaps = false
): FormattedTwoLineAddress => {
  const { state, zip } = addressObject;
  let formattedStreet1 = addressObject?.street1 ?? "";
  let formattedStreet2 = addressObject?.street2 ?? "";
  let formattedCity = addressObject?.city ?? "";

  if (initialCaps) {
    formattedStreet1 = capitalizeEachWord(formattedStreet1 ?? undefined);
    formattedStreet2 = formattedStreet2 && capitalizeEachWord(formattedStreet2);
    formattedCity = capitalizeEachWord(formattedCity ?? undefined);
  }

  return {
    lineOne: `${formattedStreet1}${
      formattedStreet2?.trim() && `, ${formattedStreet2}`
    }`,
    lineTwo: `${formattedCity}, ${state} ${zip}`,
  };
};
