import { Span, UiIcon } from "@icg360/design-system";

import { RegularContentLoader } from "components/loader";
import { useSecurityDataQuery } from "gql/__generated__/hooks";
import GoogleIcon from "images/icon-google.svg";
import { en as locale } from "locale";
import SettingsPanelLayout from "pages/settings/common/settings-panel-layout";
import { authUser } from "utils";

import ChangePassword from "./change-password";
import styles from "./security.module.scss";

const Security = () => {
  const user = authUser();
  const { data: SecurityData, loading } = useSecurityDataQuery({
    variables: {
      email: user?.email ?? "",
    },
  });

  if (loading || !user) return <RegularContentLoader />;

  const { settingsPage } = locale;
  const { userBasicInfo: userInfo } = SecurityData ?? {};
  const showGoogleEnabled = userInfo?.authProvider === "GOOGLE";

  return (
    <SettingsPanelLayout title="Security">
      {showGoogleEnabled ? (
        <div className={styles.googleInfo}>
          <div className={styles.googleRow}>
            <div className={styles.icon}>
              <GoogleIcon />
            </div>
            <Span>{settingsPage.googleEnabled}</Span>
          </div>
          <div className={styles.googleRow}>
            <div className={styles.icon}>
              <UiIcon name="Lock" />
            </div>
            <Span>{settingsPage.googleUpdate}</Span>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.dataRow}>
            <Span bold>{settingsPage.userID}</Span>
            <Span>{userInfo?.email || userInfo?.userName}</Span>
          </div>
          <div className={styles.dataRow}>
            <Span bold>{settingsPage.password}</Span>
            <Span className={styles.password}>
              <ChangePassword
                userEmail={userInfo?.email ?? ""}
                userName={userInfo?.userName ?? undefined}
              />
            </Span>
          </div>
        </>
      )}
    </SettingsPanelLayout>
  );
};

export default Security;
