export const capitalize = (str: string): string => {
  // We're replacing another redundant util with this one.
  // The other util had this check, so we're including it here for safety
  if (!str) {
    return str;
  }

  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const capitalizeEachWord = (string = ""): string => {
  return string
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
};
