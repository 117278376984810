import { FieldProps } from "formik";
import get from "lodash.get";
import { useRef } from "react";

import { Checkbox, CheckboxProps } from "@icg360/design-system";

import { scrollToError } from "utils";

type CheckboxFieldDsProps = Omit<
  CheckboxProps,
  "name" | "isSelected" | "onChange" | "onBlur" | "isError" | "errorMessage"
> & {
  field: FieldProps["field"];
  form: FieldProps["form"];
  label: string;
};

export const CheckboxField = ({
  field: { name, value, onBlur },
  form: { errors, isSubmitting, touched, setFieldValue },
  label,
  ...props
}: CheckboxFieldDsProps) => {
  const inputRef = useRef(null);
  const errorsField = get(errors, name);
  const hasError = Boolean(errorsField && get(touched, name));

  scrollToError(name, inputRef, hasError, isSubmitting, errors);

  return (
    <Checkbox
      name={name}
      isSelected={value}
      onChange={(isChecked) => setFieldValue(name, isChecked)}
      onBlur={onBlur}
      isError={hasError}
      errorMessage={errorsField}
      {...props}
    >
      {label}
    </Checkbox>
  );
};
