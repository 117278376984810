import { Field, useFormikContext } from "formik";

import { Button, Heading, Tooltip, UiIcon } from "@icg360/design-system";

import {
  InputField,
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import CheckImage from "images/check-tooltip.svg";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form/easypay-enroll-form";
import { EasyPayFormValues } from "pages/payment-plan-update/payment-plan-update";

import styles from "./bank-info.module.scss";

type BankInfoProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const BankInfo = ({ setStep }: BankInfoProps) => {
  const { touched, errors } = useFormikContext<EasyPayFormValues>();
  const bankInfoTouched =
    touched?.ach?.bankStatementName &&
    touched?.ach?.financialInstitutionName &&
    touched?.ach?.routingNumber &&
    touched?.ach?.accountNumber;

  const bankInfoHasErrors =
    errors?.ach?.bankStatementName ||
    errors?.ach?.financialInstitutionName ||
    errors?.ach?.routingNumber ||
    errors?.ach?.accountNumber;

  const bankInfoIsValid = bankInfoTouched && !bankInfoHasErrors;

  return (
    <div className={styles.wrapper}>
      <Heading size="lg">Enter your bank information</Heading>
      <BankInfoFields />

      <div className={styles.navigation}>
        <Button
          appearance="tertiary"
          onClick={() => setStep("chooseMethod")}
          data-testid="easypay-bank-info-back-btn"
        >
          Go back
        </Button>
        <Button
          onClick={() => setStep("review")}
          disabled={!bankInfoIsValid}
          data-testid="easypay-bank-info-continue-btn"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export const BankInfoFields = () => {
  const { values } = useFormikContext<EasyPayFormValues>();
  const CheckTooltip = () => (
    <Tooltip
      content={
        <>
          <span>
            You can find your bank account and routing numbers on your
            bank&apos;s website or app, or find them on a check as shown below:
          </span>
          <CheckImage />
        </>
      }
    >
      <span className={styles.tooltipIcon}>
        <UiIcon name="Information" size="sm" />
      </span>
    </Tooltip>
  );

  return (
    <>
      <div className={styles.accountType}>
        <label htmlFor={"easypayMethod"} className={styles.label}>
          Account Type
        </label>

        <Field
          id="easypayMethod"
          component={RadioButtonGroup}
          fieldChildren={() => (
            <>
              <Field
                name="ach.easypayMethod"
                label="Checking"
                id="checking"
                component={RadioButtonField}
                checked={values.ach.easypayMethod === "checking"}
                className={styles.radio}
              />
              <Field
                name="ach.easypayMethod"
                label="Savings"
                id="savings"
                component={RadioButtonField}
                checked={values.ach.easypayMethod === "savings"}
                className={styles.radio}
              />
            </>
          )}
        />
      </div>
      <Field
        name={"ach.bankStatementName"}
        title="Name on account"
        component={InputField}
      />
      <Field
        name={"ach.financialInstitutionName"}
        title="Name of bank"
        component={InputField}
      />
      <Field
        name={"ach.routingNumber"}
        title={
          <div className={styles.labelWithTooltip}>
            Routing number
            <CheckTooltip />
          </div>
        }
        component={InputField}
      />
      <Field
        name={"ach.accountNumber"}
        title={
          <div className={styles.labelWithTooltip}>
            Account number
            <CheckTooltip />
          </div>
        }
        component={InputField}
      />
    </>
  );
};
