import { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { Card, Link as DSLink, Heading, UiIcon } from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataList,
  DataRow,
  DataTerm,
} from "components/common/data-row";
import { MSSLink } from "components/common/link";
import { AuthAppContext } from "components/root/auth-app-provider";
import { CONFIG } from "config";
import { bopWindHailMap } from "consts";
import { formatCurrency, trackEvent } from "utils";

import styles from "./coverage.module.scss";

const isNilOrZero = (val) => [null, undefined, "0"].includes(val);

const Coverage = () => {
  const { userDetails, userDocuments } = useContext(AuthAppContext);
  const { latest } = userDocuments ?? {};
  const { isCommercial, propertyAddress, additionalAddresses, currentTerm } =
    userDetails ?? {
      currentTerm: {},
      additionalAddresses: [],
    };
  const {
    coverageA: roof,
    coverageB: otherStructures,
    coverageC: personalProperty,
    coverageD: lossOfUse,
    coverageE,
    coverageEPerOccurrence,
    coverageEAggregate,
    coverageF,
    coverageL,
    coverageM,
    allOtherPerilsDeductible: allOtherPerils,
    propertyDamageLiabilityDeductiblePerClaim: propertyPerClaim,
    propertyDamageLiabilityDeductiblePerOccurrence: propertyPerOcc,
    termLocationData,
  } = currentTerm ?? {};
  let personalLiability = coverageE;
  let medicalPayments = coverageF;

  if (isNilOrZero(coverageE) && isNilOrZero(coverageF)) {
    personalLiability = coverageL;
    medicalPayments = coverageM;
  }

  const locationData = termLocationData?.find((loc) => loc?.location === 1);
  let building = "",
    businessPersonalProperty = "",
    allOtherPerilsDeductibleDollarAmount = "",
    hurricane = "",
    windHail = "";

  if (isCommercial && locationData) {
    building = locationData.coverageA ?? "";
    businessPersonalProperty = locationData.coverageC ?? "";
    hurricane = locationData.hurricaneDeductible ?? "";
    allOtherPerilsDeductibleDollarAmount =
      locationData.allOtherPerilsDeductibleDollarAmount ?? "";
    windHail = bopWindHailMap[locationData.windHailDeductible] || "N/A";
  }

  let propertyDamageDeductible = "N/A";
  let labelInfo = "";

  if (propertyPerClaim) {
    propertyDamageDeductible = formatCurrency(
      (parseInt(propertyPerClaim, 10) * 100).toString()
    );
    labelInfo = " (per claim)";
  } else if (propertyPerOcc) {
    propertyDamageDeductible = formatCurrency(
      (parseInt(propertyPerOcc, 10) * 100).toString()
    );
    labelInfo = " (per occurrence)";
  }

  const formatLiability = (occ, agg) =>
    `${formatCurrency(occ)} occ / ${formatCurrency(agg)} agg`;

  // TODO: Create a hurricaneMap in consts/deductible-keys and use that instead of division.
  const formatPercentage = (nb) => `${nb / 100}%`;

  const renderAddress = (addressObject) => {
    const { street1, street2, city, state, zip } = addressObject ?? {};
    return `${street1}, ${street2 && `${street2},`} ${city}, ${state} ${zip}`;
  };

  const policyDeclarationRoute = latest?.declaration?.route ?? "";

  const PolicyDeclarationsLink = () => (
    <DSLink
      href={`${CONFIG.KEYSTONE_PROXY_HREF}${policyDeclarationRoute}`}
      onClick={() => {
        trackEvent("Declaration Page Link Clicked", {
          sectionName: "Coverages",
        });
      }}
      target="_blank"
      rel="noreferrer noopener"
    >
      Policy Declarations
      <span className={styles.downloadIcon}>
        <UiIcon name="Download" size="sm" />
      </span>
    </DSLink>
  );

  const MyDocumentsLink = () => (
    <MSSLink to="/my/documents">
      Policy Declarations
      <span className={styles.downloadIcon}>
        <UiIcon name="Download" size="sm" />
      </span>
    </MSSLink>
  );

  const DeclarationsFooter = () => (
    <footer>
      <Body className={styles.declarationLink}>
        {additionalAddresses?.length
          ? "Information above is shown for the first location. For coverage information for other locations please see "
          : "For more details on your current coverages please view the "}
        {/* Note: policyDeclarationRoute should almost always be defined on prod */}
        {policyDeclarationRoute ? (
          <PolicyDeclarationsLink />
        ) : (
          <MyDocumentsLink />
        )}
      </Body>
    </footer>
  );

  const CommercialCoverage = () => (
    <>
      <Row className={styles.contentRow}>
        <Heading size="xs" className={styles.sectionHeader}>
          {`Coverages for ${renderAddress(propertyAddress)}`}
        </Heading>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Property Coverage Limits
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Building</DataTerm>
                <DataDefinition>{formatCurrency(building)}</DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Business Personal Property</DataTerm>
                <DataDefinition>
                  {formatCurrency(businessPersonalProperty)}
                </DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Deductibles
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>All Other Perils</DataTerm>
                <DataDefinition>
                  {formatCurrency(allOtherPerilsDeductibleDollarAmount)}
                </DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Hurricane</DataTerm>
                <DataDefinition>{formatPercentage(hurricane)}</DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Wind/Hail</DataTerm>
                <DataDefinition>{windHail}</DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
      </Row>
      <Row className={styles.contentRow}>
        <Heading size="xs" className={styles.sectionHeader}>
          Policy coverages
        </Heading>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Liability coverage limits
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Liability</DataTerm>
                <DataDefinition>
                  {formatLiability(coverageEPerOccurrence, coverageEAggregate)}
                </DataDefinition>
              </DataRow>
              <DataRow flex="row" spaceBetween>
                <DataTerm>Medical expense</DataTerm>
                <DataDefinition>
                  {formatCurrency(medicalPayments)}
                </DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
        <Col md={6} xs={12}>
          <section>
            <Heading size="xs" className={styles.sectionTitle}>
              Deductibles
            </Heading>
            <DataList>
              <DataRow flex="row" spaceBetween>
                <DataTerm>
                  Property damage
                  {labelInfo}
                </DataTerm>
                <DataDefinition>{propertyDamageDeductible}</DataDefinition>
              </DataRow>
            </DataList>
          </section>
        </Col>
      </Row>
    </>
  );

  const Coverage = () => (
    <Row>
      <Col md={6} xs={12}>
        <section>
          <Heading size="xs" className={styles.sectionTitle}>
            Section I - Property Coverage Limits
          </Heading>
          <DataList>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Dwelling</DataTerm>
              <DataDefinition>{formatCurrency(roof)}</DataDefinition>
            </DataRow>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Other structures</DataTerm>
              <DataDefinition>{formatCurrency(otherStructures)}</DataDefinition>
            </DataRow>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Personal property</DataTerm>
              <DataDefinition>
                {formatCurrency(personalProperty)}
              </DataDefinition>
            </DataRow>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Loss of use</DataTerm>
              <DataDefinition>{formatCurrency(lossOfUse)}</DataDefinition>
            </DataRow>
          </DataList>
        </section>
      </Col>
      <Col md={6} xs={12}>
        <section className={styles.stackedCoverageSection}>
          <Heading size="xs" className={styles.sectionTitle}>
            Section II - Liability Coverage Limits
          </Heading>
          <DataList>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Personal liability</DataTerm>
              <DataDefinition>
                {formatCurrency(personalLiability)}
              </DataDefinition>
            </DataRow>
            <DataRow flex="row" spaceBetween>
              <DataTerm>Medical payments</DataTerm>
              <DataDefinition>{formatCurrency(medicalPayments)}</DataDefinition>
            </DataRow>
          </DataList>
        </section>
        <section className={styles.stackedCoverageSection}>
          <Heading size="xs" className={styles.sectionTitle}>
            Deductibles
          </Heading>
          <DataRow flex="row" spaceBetween>
            <DataTerm>All Other Perils</DataTerm>
            <DataDefinition>{allOtherPerils}</DataDefinition>
          </DataRow>
        </section>
      </Col>
    </Row>
  );

  return (
    <Card title="Coverages">
      {isCommercial ? <CommercialCoverage /> : <Coverage />}
      <DeclarationsFooter />
    </Card>
  );
};

export default Coverage;
