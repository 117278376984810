import PropTypes from "prop-types";
import { FormLabel as ControlLabel } from "react-bootstrap";

import styles from "./form-label.module.scss";

export const RadioButtonLabel = ({ name, children }) => (
  <ControlLabel htmlFor={name} className={styles.radioButtonLabel}>
    {children}
  </ControlLabel>
);

RadioButtonLabel.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node,
};
