import { FieldProps } from "formik";
import get from "lodash.get";
import { ReactNode, useRef } from "react";

import { Item, Select, SelectProps } from "@icg360/design-system";

import { scrollToError } from "utils";

type SelectFieldProps = Omit<
  SelectProps<string | number>,
  | "name"
  | "items"
  | "label"
  | "isError"
  | "errorMessage"
  | "selectedKey"
  | "onSelectionChange"
> & {
  field: FieldProps["field"];
  form: FieldProps["form"];
  title: ReactNode;
  fullWidth?: boolean;
  options: {
    value: string;
    label: string;
  }[];
};
export const SelectField = ({
  field: { name, value },
  form: { errors, touched, isSubmitting, setFieldValue },
  title,
  fluid,
  fullWidth,
  options,
  ...props
}: SelectFieldProps) => {
  const inputRef = useRef(null);

  const errorsField = get(errors, name);
  const hasError = !!(errorsField && get(touched, name));
  scrollToError(name, inputRef, hasError, isSubmitting, errors);

  return (
    <Select
      items={options}
      label={title}
      isError={hasError}
      errorMessage={errorsField}
      selectedKey={value}
      onSelectionChange={(key) => setFieldValue(name, key.toString())}
      fluid={fullWidth || fluid}
      {...props}
    >
      {(item) => <Item key={item.value}>{item.label}</Item>}
    </Select>
  );
};
