export const PAYMENT_PLANS = [
  {
    count: "1",
    downPayment: "0",
    name: "FullPay",
  },
  {
    count: "2",
    downPayment: "60",
    name: "TwoPay",
  },
  {
    count: "4",
    downPayment: "40",
    name: "FourPay",
  },
  {
    count: "10",
    downPayment: "20",
    name: "TenPay",
  },
];

export const PAYMENT_PLANS_INFO = [
  {
    id: 1,
    frequencyMethod: 1,
    frequency: "FullPay",
    frequencyLabel: "Full Payment",
    directbill: {
      firstPayment: 1029.0,
      serviceFees: 0.0,
    },
    invoice: {
      firstPayment: "--",
      otherPayments: "--",
      serviceFees: "--",
    },
    easypaycc: {
      firstPayment: 1029.0,
      serviceFees: 0.0,
    },
    easypay: {
      firstPayment: 0.0,
      serviceFees: 0.0,
    },
  },
  {
    id: 2,
    frequencyMethod: 2,
    frequency: "TwoPay",
    frequencyLabel: "Two Payments",
    directbill: {
      firstPayment: 624.0,
      otherPayments: 419.0,
      serviceFees: 14.0,
    },
    invoice: {
      firstPayment: "--",
      otherPayments: "--",
      serviceFees: "--",
    },
    easypaycc: {
      firstPayment: 624.0,
      otherPayments: 419.0,
      serviceFees: 14.0,
    },
    easypay: {
      firstPayment: 0.0,
      otherPayments: 0.0,
      serviceFees: 0.0,
    },
  },
  {
    id: 3,
    frequencyMethod: 4,
    frequency: "FourPay",
    frequencyLabel: "Four Payments",
    directbill: {
      firstPayment: 0.0,
      otherPayments: 0.67,
      serviceFees: 0.0,
    },
    invoice: {
      firstPayment: "--",
      otherPayments: "--",
      serviceFees: "--",
    },
    easypaycc: {
      firstPayment: 419.0,
      otherPayments: 212.67,
      serviceFees: 28.0,
    },
    easypay: {
      firstPayment: 0.0,
      otherPayments: 0.0,
      serviceFees: 0.0,
    },
  },
  {
    id: 4,
    frequencyMethod: 10,
    frequency: "TenPay",
    frequencyLabel: "Ten Payments",
    directbill: {
      firstPayment: 213.0,
      otherPayments: 98.44,
      serviceFees: 70.0,
    },
    invoice: {
      firstPayment: "--",
      otherPayments: "--",
      serviceFees: "--",
    },
    easypaycc: {
      firstPayment: 213.0,
      otherPayments: 98.44,
      serviceFees: 70.0,
    },
    easypay: {
      firstPayment: 0.0,
      otherPayments: 0.0,
      serviceFees: 0.0,
    },
  },
];

export const paymentPlans = {
  invoice: "Mortgagee Billing",
  fullpay: "Full Pay",
  twopay: "Two Pay",
  fourpay: "Four Pay",
  tenpay: "Ten Pay",
  tenpayinvoice: "Ten Pay Direct",
  twopayeasypay: "Two Pay EasyPay",
  fourpayeasypay: "Four Pay EasyPay",
  tenpayeasypay: "Ten Pay EasyPay",
  fullpayeasypay: "Full Pay EasyPay",
  fulleasypayach: "Full Pay EasyPay ACH",
  twoeasypayach: "Two Pay EasyPay ACH",
  foureasypayach: "Four Pay EasyPay ACH",
  teneasypayach: "Ten Pay EasyPay ACH",
  fullpayeasypayach: "Full Pay EasyPay ACH",
  twopayeasypayach: "Two Pay EasyPay ACH",
  fourpayeasypayach: "Four Pay EasyPay ACH",
  tenpayeasypayach: "Ten Pay EasyPay ACH",
  fulleasypaycc: "Full Pay EasyPay Credit Card",
  twoeasypaycc: "Two Pay EasyPay Credit Card",
  foureasypaycc: "Four Pay EasyPay Credit Card",
  teneasypaycc: "Ten Pay EasyPay Credit Card",
  fullpayeasypaycc: "Full Pay EasyPay Credit Card",
  twopayeasypaycc: "Two Pay EasyPay Credit Card",
  fourpayeasypaycc: "Four Pay EasyPay Credit Card",
  tenpayeasypaycc: "Ten Pay EasyPay Credit Card",
};

export const PAYMENT_METHODS = {
  invoice: "Mortgagee Escrow",
  directbill: "Direct Bill",
  easypaycc: "EasyPay (Debit/Credit)",
  easypay: "EasyPay ACH",
};

export const PAYMENT_METHOD_DETAILS = {
  invoice: {
    paymentFrequency: "",
    paymentFrequencyLabel: "",
    paymentMethod: "invoice",
    easypayMethod: "",
    paymentMethodName: "Mortgagee Billing",
    paymentMethodLabel: "Mortgagee Billing",
    paymentMethodSummaryLabel: "Mortgagee Billing",
  },
  fullpay: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "directbill",
    easypayMethod: "",
    paymentMethodName: "Direct Bill",
    paymentMethodLabel: "Direct Bill",
    paymentMethodSummaryLabel: "Direct Bill",
  },
  twopay: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "directbill",
    easypayMethod: "",
    paymentMethodName: "Direct Bill",
    paymentMethodLabel: "Direct Bill",
    paymentMethodSummaryLabel: "Direct Bill",
  },
  fourpay: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "directbill",
    easypayMethod: "",
    paymentMethodName: "Direct Bill",
    paymentMethodLabel: "Direct Bill",
    paymentMethodSummaryLabel: "Direct Bill",
  },
  tenpay: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "directbill",
    easypayMethod: "",
    paymentMethodName: "Direct Bill",
    paymentMethodLabel: "Direct Bill",
    paymentMethodSummaryLabel: "Direct Bill",
  },
  tenpayinvoice: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "directbill",
    easypayMethod: "",
    paymentMethodName: "Direct Bill",
    paymentMethodLabel: "Direct Bill",
    paymentMethodSummaryLabel: "Direct Bill",
  },
  fullpayeasypay: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "easypay",
    easypayMethod: "",
    paymentMethodName: "EasyPay",
    paymentMethodLabel: "EasyPay",
    paymentMethodSummaryLabel: "EasyPay",
  },
  twopayeasypay: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "easypay",
    easypayMethod: "",
    paymentMethodName: "EasyPay",
    paymentMethodLabel: "EasyPay",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fourpayeasypay: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "easypay",
    easypayMethod: "",
    paymentMethodName: "EasyPay",
    paymentMethodLabel: "EasyPay",
    paymentMethodSummaryLabel: "EasyPay",
  },
  tenpayeasypay: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "easypay",
    easypayMethod: "",
    paymentMethodName: "EasyPay",
    paymentMethodLabel: "EasyPay",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fullpayeasypayach: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  twopayeasypayach: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fourpayeasypayach: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  tenpayeasypayach: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fullpayeasypaycc: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  twopayeasypaycc: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fourpayeasypaycc: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  tenpayeasypaycc: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fulleasypayach: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  twoeasypayach: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  foureasypayach: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  teneasypayach: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "easypay",
    easypayMethod: "ach",
    paymentMethodName: "EasyPay ACH",
    paymentMethodLabel: "EasyPay ACH",
    paymentMethodSummaryLabel: "EasyPay",
  },
  fulleasypaycc: {
    paymentFrequency: "FullPay",
    paymentFrequencyLabel: "Full Payment",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  twoeasypaycc: {
    paymentFrequency: "TwoPay",
    paymentFrequencyLabel: "Two Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  foureasypaycc: {
    paymentFrequency: "FourPay",
    paymentFrequencyLabel: "Four Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
  teneasypaycc: {
    paymentFrequency: "TenPay",
    paymentFrequencyLabel: "Ten Payments",
    paymentMethod: "easypaycc",
    easypayMethod: "debit-credit",
    paymentMethodName: "EasyPay CC",
    paymentMethodLabel: "EasyPay Credit Card",
    paymentMethodSummaryLabel: "EasyPay",
  },
};

export const PAYMENT_METHOD_CODE = {
  INVOICE: "invoice",
  EASYPAY: "easypay",
  EASYPAYCC: "easypaycc",
  DIRECTBILL: "directbill",
};
