export const bopWindHailMap = {
  100: "1%",
  200: "2%",
  300: "3%",
  400: "4%",
  500: "5%",
  1000: "10%",
  2.5: "$250",
  5: "$500",
  10: "$1,000",
  25: "$2,500",
  50: "$5,000",
  75: "$7,500",
  101: "$10,000",
};
