import cx from "classnames";
import { Fragment } from "react";
import { Container } from "react-bootstrap";

import { Link as DSLink } from "@icg360/design-system";

import LiveChatButton from "components/live-chat-button";
import SagesureLogoFooter from "images/sagesure-logo-footer.svg";
import { en as locale } from "locale";
import { trackEvent } from "utils";

import styles from "./page-footer.module.scss";

const footerLinks = [
  {
    link: "https://www.sagesure.com/about/",
    text: locale.sitemap.about,
    id: "aboutBtn",
  },
  {
    link: "https://www.sagesure.com/contact-us/",
    text: locale.sitemap.contact,
    id: "contactBtn",
    trackEvent: true,
    event: "Contact Us",
  },
  {
    link: "https://www.sagesure.com/faq/",
    text: locale.sitemap.faqs,
    id: "faqBtn",
  },
  {
    link: "/terms-of-use",
    text: locale.sitemap.terms,
    id: "termsBtn",
  },
  {
    link: "/privacy-policy",
    text: locale.sitemap.privacy,
    id: "privacyBtn",
  },
];

type PageFooterProps = {
  className?: string;
};

const PageFooter = ({ className }: PageFooterProps) => {
  return (
    <footer className={cx(styles.footerWrapper, className)}>
      <div className={styles.container}>
        <Container>
          <div className={styles.flexWrapper}>
            <div className={styles.msslogo}>
              <SagesureLogoFooter />
            </div>
            <div className={styles.content}>
              <span className={styles.text}>{locale.info.copyright()}</span>
              <span className={styles.bar}>|</span>
              <ul className={styles.sitemap}>
                {footerLinks.map((footerLink, index) => (
                  <Fragment key={index}>
                    <li>
                      <DSLink
                        href={footerLink.link}
                        id={footerLink.id}
                        onClick={
                          footerLink.event
                            ? () => trackEvent(footerLink.event)
                            : undefined
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        key={index}
                      >
                        {footerLink.text}
                      </DSLink>
                    </li>
                    {index !== footerLinks.length - 1 ? "|" : " "}
                  </Fragment>
                ))}
              </ul>
            </div>
            <div className={styles.chat}>
              <LiveChatButton location="PageFooter" />
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default PageFooter;
