import { FormikErrors } from "formik";
import { RefObject } from "react";

import { flattenObject } from "utils";

export function scrollToElement(element: RefObject<HTMLInputElement>) {
  element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
}

const getFirstError = (errors: FormikErrors<unknown>) => {
  const [randomError] = Object.keys(errors); // There's not really any guarantee of the order here, so...
  return randomError;
};

export function scrollToError(
  names: string | string[],
  inputRef: RefObject<HTMLInputElement>,
  hasError: boolean,
  isSubmitting: boolean,
  errors: FormikErrors<unknown>
) {
  if (hasError && isSubmitting) {
    const allErrors = flattenObject(errors);
    const fieldNames = typeof names === "string" ? [names] : names;
    const firstErrorName = getFirstError(allErrors);

    if (fieldNames.indexOf(firstErrorName) > -1) {
      scrollToElement(inputRef);
    }
  }
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return;
}

export function scrollToYPosition(y: number) {
  window.scrollTo({
    top: y,
    behavior: "smooth",
  });
  return;
}

// From https://stackoverflow.com/a/51689657
const DEFAULT_TRANSITION_TIME = 500;
export function scrollToSmoothly(pos: number, time = DEFAULT_TRANSITION_TIME) {
  const currentPos = window.pageYOffset;
  let start: number | null = null;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time);
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
}
