import { Heading, MarketingIcon } from "@icg360/design-system";

import { Stack } from "components/common/stack";
import { ExternalHeader } from "components/external-header";
import { LogoImageLink } from "components/shared/logo-link";
import { en as locale } from "locale";

import styles from "./registration-confirmation.module.scss";

const {
  registrationConfirmationPage: { title, subtitle },
} = locale;

const RegistrationConfirmation = () => (
  <>
    <ExternalHeader returnUrlParsed />
    <div className={styles.topSection}>
      <LogoImageLink className={styles.logo} />
    </div>
    <div className={styles.bottomSection}>
      <Stack className={styles.content} gap="xs">
        <Heading size="lg" className={styles.title}>
          {title}
        </Heading>
        <div className={styles.circle}>
          <MarketingIcon name="Envelope" size="md" />
        </div>
        <Heading size="md" className={styles.subtitle}>
          {subtitle}
        </Heading>
      </Stack>
    </div>
  </>
);

export default RegistrationConfirmation;
