import { MouseEvent, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Badge, Button, Card, Heading } from "@icg360/design-system";

import { Body } from "components/common/body";
import { HomeServicesContext } from "components/home-services/home-services-provider";
import { trackEvent } from "utils";

import { CONTENT_MSG } from "./constants";
import styles from "./home-services.module.scss";
import { useBadgeCookie } from "./hooks";

const PropertyMessages = () => {
  const navigate = useNavigate();
  const { homeServicesMessages } = useContext(HomeServicesContext);
  const { checkBadgeCookie } = useBadgeCookie();

  const messages =
    homeServicesMessages?.filter((msg) => msg.type === CONTENT_MSG) ?? [];

  const onPropertyCareButtonClick = (
    event: MouseEvent<HTMLButtonElement>,
    to: string
  ) => {
    trackEvent("Property Care - Property Care Button Clicked", {
      homeServices: "propertyCare",
      buttonText: event.currentTarget.innerText,
    });
    navigate(to);
  };

  return messages.length > 0 ? (
    <section style={{ marginBottom: 16 }}>
      {messages.map((msg) => (
        <Card key={msg.type}>
          {!checkBadgeCookie(msg.id) && (
            <div className={styles.careBadgeWrap}>
              <Badge appearance="success">New</Badge>
            </div>
          )}
          <Heading size="md">{msg.content.title}</Heading>
          <Body className={styles.propertyCareSection}>
            {msg.content.description}
          </Body>
          <Button
            onClick={(e) =>
              onPropertyCareButtonClick(e, `/my/property/services/${msg.id}`)
            }
          >
            View {msg.content.title.toLowerCase()}
          </Button>
        </Card>
      ))}
    </section>
  ) : null;
};

export default PropertyMessages;
