import moment from "moment";

import { DATE_PLACEHOLDER } from "consts";

export function formatDate(date, formatStr = "MMM DD, YYYY") {
  const isDate = isValidDate(date);

  let dateVal = date;

  let isTransformedDate;

  if (!isDate) {
    dateVal = new Date(Number(date));
    isTransformedDate = isValidDate(dateVal);

    if (!isTransformedDate) {
      dateVal = new Date(date);
      isTransformedDate = isValidDate(dateVal);

      if (!isTransformedDate) {
        return DATE_PLACEHOLDER;
      }
    }
  }

  const formattedDate = moment.utc(dateVal).format(formatStr);

  return formattedDate;
}

function isValidDate(dateVal) {
  return (
    Object.prototype.toString.call(dateVal) === "[object Date]" &&
    !isNaN(dateVal.getTime())
  );
}
