import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Outlet, useLocation } from "react-router-dom";

import { AuthAppContext } from "components/root/auth-app-provider";
import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import { usePolicyHolderPhone } from "pages/settings/contact/contact-item/hooks";
import { useEligibility } from "pages/shingle-reseal/hooks";

export type ShingleResealContextState = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phone?: string;
  terms?: string;
  offerId?: string;
  from?: string;
} | null;

export const ShingleContext = createContext<{
  state: ShingleResealContextState;
  setState: Dispatch<SetStateAction<ShingleResealContextState>>;
}>({ state: null, setState: () => null });

export const ShingleResealChecker = () => {
  const { policy } = useContext(AuthAppContext);
  const { setFields, state } =
    useMultiStepFormState<ShingleResealContextState>(ShingleContext);
  const { state: locationState } = useLocation();
  const { policyHolderPhone, phoneLoading } = usePolicyHolderPhone(
    policy.policyId
  );
  const { loading, eligibilityData } = useEligibility();

  useEffect(() => {
    eligibilityData?.additionalDetails.map((detail) => {
      if (detail.assetTypeCode === "TERMS_OF_USE") {
        setFields({
          terms: detail.assetTextContent,
        });
      }
    });
  }, [eligibilityData?.additionalDetails, setFields]);

  useEffect(() => {
    if (eligibilityData?.offerId) {
      setFields({ offerId: eligibilityData.offerId ?? "" });
    }
  }, [eligibilityData?.offerId, setFields]);

  // This allows us to redirect back to where the user came from (perks or overview)
  useEffect(() => {
    if (locationState?.from) {
      setFields({ from: locationState.from });
    } else if (!state?.from) {
      setFields({ from: "/my/overview" });
    }
  }, [locationState?.from, setFields, state?.from]);

  useEffect(() => {
    if (policyHolderPhone && !state?.phone) {
      setFields({ phone: policyHolderPhone ?? "" });
    }
  }, [policyHolderPhone, setFields, state?.phone]);

  if (loading || phoneLoading) {
    return null;
  }

  return <Outlet />;
};

export const ShingleResealFlow = () => {
  const [state, setState] = useState<ShingleResealContextState>(null);
  return (
    <ShingleContext.Provider value={{ state, setState }}>
      <ShingleResealChecker />
    </ShingleContext.Provider>
  );
};
