import cx from "classnames";

import { Link as DSLink, Heading, MarketingIcon } from "@icg360/design-system";

import { GENERAL_SUPPORT } from "consts";

import styles from "./alt-payment-banner.module.scss";

const AltPaymentBanner = () => (
  <div className={styles.spacer}>
    <div className={styles.banner}>
      <Heading size="lg" className={styles.mainHeader}>
        More ways to pay
      </Heading>
      <div className={cx(styles.phoneSection, styles.section)}>
        <div className={styles.bannerIcon}>
          <MarketingIcon name="Phone" />
        </div>
        <div className={styles.sectionContent}>
          <Heading size="md">Pay by phone</Heading>
          <div>
            Call{" "}
            <DSLink href={`tel:${GENERAL_SUPPORT.replace(/\D/g, "")}`}>
              {GENERAL_SUPPORT}
            </DSLink>{" "}
            to make a payment by phone.
          </div>
        </div>
      </div>
      <div className={cx(styles.mailSection, styles.section)}>
        <div className={styles.bannerIcon}>
          <MarketingIcon name="Envelope" />
        </div>
        <div className={styles.sectionContent}>
          <Heading size="md">Pay by mail</Heading>
          <div>Please put your policy number on your check.</div>
          <div className={styles.mailAddressesWrapper}>
            <div>
              <div className={styles.mailAddressHeader}>Overnight payments</div>
              <div>BB&T</div>
              <div>Attn: Lockbox # - 896671</div>
              <div>5130 Parkway Plaza Blvd</div>
              <div>Charlotte, NC 28217-1964</div>
            </div>
            <div>
              <div className={styles.mailAddressHeader}>Standard payments</div>
              <div>SageSure Insurance Managers</div>
              <div>P.O.Box 896671</div>
              <div>Charlotte, NC 28289-6671</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AltPaymentBanner;
