import { Form, useFormikContext } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Button,
  Card,
  Heading,
  MarketingIcon,
} from "@icg360/design-system";

import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { PROPERTY_ROOF_UPDATE_TYPE } from "consts";
import { en as locale } from "locale";
import { RoofFormValues } from "pages/property-profile/update-roof/index";

import styles from "./update-roof.module.scss";

const ReviewRoof = () => {
  const navigate = useNavigate();
  const {
    isSubmitting,
    status,
    values: { constructionYearRoof, roofCoveringType },
  } = useFormikContext<RoofFormValues>();

  useEffect(() => {
    if (!constructionYearRoof || !roofCoveringType) {
      navigate("/my/property/roof");
    }
  }, [constructionYearRoof, navigate, roofCoveringType]);

  if (!constructionYearRoof || !roofCoveringType) {
    return null;
  }

  return (
    <>
      <ProgressBar progress={90} />
      <MainLayout
        sidebar={
          <PropertyProfileSidebar
            headline={locale.updateRoof.documentation}
            body={locale.updateRoof.documentationDescription}
          />
        }
      >
        <Card className={styles.reviewCard}>
          <Form>
            <Stack gap="md">
              <Heading size="lg">{locale.propertyProfile.pleaseReview}</Heading>

              {status?.type === "error" && (
                <Alert
                  appearance="danger"
                  title=""
                  description={locale.propertyProfile.error}
                />
              )}
              <div className={styles.iconHeaderReview}>
                <div className={styles.houseIcon}>
                  <MarketingIcon name="FullHouse" size="sm" />
                </div>
                <Heading size="sm">{locale.propertyPage.roof}</Heading>
              </div>
              <div>{locale.updateRoof.roofDescription}</div>

              <InlineDataRow className={styles.dataRow}>
                <DataTerm>{locale.updateRoof.yearInstalled}</DataTerm>
                <DataDefinition>{constructionYearRoof}</DataDefinition>
              </InlineDataRow>

              <InlineDataRow className={styles.dataRow}>
                <DataTerm>{locale.updateRoof.roofMaterial}:</DataTerm>
                <DataDefinition>
                  {PROPERTY_ROOF_UPDATE_TYPE[roofCoveringType].label}
                </DataDefinition>
              </InlineDataRow>

              <div className={styles.navigation}>
                <Button
                  appearance="tertiary"
                  onClick={() => navigate("/my/property/roof")}
                >
                  {locale.easypayEnroll.goBack}
                </Button>
                <Button
                  appearance="primary"
                  size="default"
                  type="submit"
                  disabled={isSubmitting}
                  data-testid="roof-submit-btn"
                  loading={isSubmitting}
                >
                  Submit updates
                </Button>
              </div>
            </Stack>
          </Form>
        </Card>
      </MainLayout>
    </>
  );
};

export default ReviewRoof;
