import { Heading } from "@icg360/design-system";

import Login from "components/login";
import UnauthLayout from "components/shared/unauth-layout";

export const SubmitClaimHelp = () => {
  return (
    <UnauthLayout>
      <Heading size="lg">
        We care about the privacy and security of your claim
      </Heading>
      <div>
        Log in to look up your coverage, submit a new claim, or check on the
        status of an existing claim.
      </div>
      <Login />
    </UnauthLayout>
  );
};
