import { useCallback, useEffect, useState } from "react";

import { POOL_DATA, PROPERTY_POOL_TYPE, PROPERTY_PROFILE } from "consts";
import { scrollToTop, trackEvent } from "utils";

export const usePropertyProfilePage = (userPropertyProfileData) => {
  const [updateForm, setUpdateForm] = useState("");
  const [propertyUpdated, setPropertyUpdated] = useState(false);

  const getPropertyDataValue = useCallback(
    (term) => {
      let val;
      if (userPropertyProfileData) {
        if (PROPERTY_PROFILE[term]?.valueMap) {
          val =
            PROPERTY_PROFILE[term].valueMap[userPropertyProfileData[term] ?? 1];
        } else {
          val = userPropertyProfileData[term];
        }
      }
      return val;
    },
    [userPropertyProfileData]
  );

  useEffect(() => {
    if (userPropertyProfileData) {
      trackEvent("Property", {
        roofAge: getPropertyDataValue("constructionYearRoof"),
        burglarAlarm: getPropertyDataValue("burglarAlarm"),
        fireAlarm: getPropertyDataValue("fireAlarm"),
        pool: getPropertyDataValue("poolType"),
        poolFence: getPropertyDataValue("poolFence"),
        trampoline: getPropertyDataValue("trampoline"),
      });
      scrollToTop();
    }
  }, [getPropertyDataValue, userPropertyProfileData]);

  const propertyData = {},
    poolData = {};
  if (userPropertyProfileData) {
    Object.keys(PROPERTY_PROFILE).forEach((term) => {
      const value = getPropertyDataValue(term);
      propertyData[term] = {
        title: PROPERTY_PROFILE[term].title,
        value: value,
      };
    });
    POOL_DATA.forEach((term) => {
      if (term === "poolType") {
        poolData[term] = PROPERTY_POOL_TYPE[userPropertyProfileData[term]];
        // Due to a double negative being used, we have to flip the expected response
      } else if (term === "poolFenceAboveGround") {
        poolData[term] = userPropertyProfileData[term] ? "No" : "Yes";
      } else if (userPropertyProfileData[term] !== null) {
        poolData[term] = userPropertyProfileData[term] ? "Yes" : "No";
      }
    });
  }

  useEffect(() => {
    const { poolType } = poolData;
    const {
      constructionYearRoof,
      roofCoveringType,
      burglarAlarm,
      fireAlarm,
      trampoline,
    } = propertyData;

    if (updateForm === "roof") {
      trackEvent("Property roof - Update button clicked", {
        roofMaterial: roofCoveringType.value,
        roofAge: constructionYearRoof.value,
      });
    }
    if (updateForm === "security") {
      trackEvent("Property security - Update button clicked", {
        burglarAlarm,
        fireAlarm,
      });
    }
    if (updateForm === "pool") {
      trackEvent("Property pool - Update button clicked", { pool: poolType });
    }
    if (updateForm === "trampoline") {
      trackEvent("Property trampoline - Update button clicked", { trampoline });
    }
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateForm]);

  return {
    propertyData,
    propertyUpdated,
    poolData,
    updateForm,
    setPropertyUpdated,
    setUpdateForm,
  };
};
