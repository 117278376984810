import { useState } from "react";

import { Button, addToast } from "@icg360/design-system";

import { en as locale } from "locale";
import { trackEvent } from "utils";

import EditBillingEmailModal from "./edit-billing-email-modal";

type EditBillingEmailProps = {
  policyId: string;
};
const EditBillingEmail = ({ policyId }: EditBillingEmailProps) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const openModal = () => {
    setShowEditModal(true);
    trackEvent("Update email address modal displayed", {
      paperlessType: "billing",
    });
  };

  return (
    <>
      <Button
        appearance="tertiary"
        size="xs"
        onClick={openModal}
        data-testid="billing-email-edit-btn"
      >
        {locale.buttons.edit}
      </Button>
      <EditBillingEmailModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        policyId={policyId}
        onEditBillingSuccess={() => {
          setShowEditModal(false);
          addToast("Email address was updated successfully.", { icon: true });
        }}
      />
    </>
  );
};

export default EditBillingEmail;
