import { setMixpanelSuperProperties } from "utils";

export default function trackClaimsInfo(userClaimsList) {
  if (!userClaimsList) return;

  // Mixpanel
  const { ClaimsTrackerInfo, ClaimsInfo, SubmissionsInfo } = userClaimsList;
  const numberOfClaims = ClaimsInfo?.length ?? 0;
  const numberOfClaimSubmitted = SubmissionsInfo?.length ?? 0;
  let activeClaims = 0;
  let pastClaims;
  let submissionNumbers = [];

  // Claims and FNOL submissions are combined in ClaimsTrackerInfo results but separate keys otherwise
  if (ClaimsTrackerInfo) {
    activeClaims = ClaimsTrackerInfo.filter(
      ({ Status }) => Status === "Open"
    ).length;

    pastClaims = ClaimsTrackerInfo.length - activeClaims;
  } else {
    if (ClaimsInfo) {
      submissionNumbers = ClaimsInfo.map((claim) => claim.SubmissionNumber);

      activeClaims += ClaimsInfo.filter(
        ({ Status }) => Status === "Open"
      ).length;
    }

    if (SubmissionsInfo) {
      activeClaims += SubmissionsInfo.filter((submission) => {
        return !submissionNumbers.includes(submission.SubmissionNumber);
      }).length;
    }

    pastClaims = numberOfClaims + numberOfClaimSubmitted - activeClaims;
  }

  setMixpanelSuperProperties({
    numberOfClaims,
    numberOfClaimSubmitted,
    activeClaims,
    pastClaims,
  });

  // Qualtrics
  const claimNumbers = ClaimsTrackerInfo?.map((claim) => {
    const { CarrierClaim, FNOLSubmission } = claim;
    return CarrierClaim?.Number ?? FNOLSubmission?.SubmissionNumber;
  }).join(", ");

  // Set Qualtrics claims data
  window.SURVEY_CLAIMS_TRACKER_FLAG = !ClaimsInfo;
  window.SURVEY_CLAIMS_COUNT = ClaimsInfo?.length ?? ClaimsTrackerInfo?.length;
  window.SURVEY_USER_CLAIM_NUMBERS = claimNumbers;
}
