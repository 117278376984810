import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object as yupObject, string as yupString } from "yup";

import { Button, TelephoneMask, TextInput } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  ShingleContextState,
} from "pages/shingle-repair-pilot/index";
import ShingleRepairLayout from "pages/shingle-repair-pilot/shingle-repair-layout";
import { getShingleLandingPath } from "pages/shingle-repair-pilot/shingle-repair-pilot-utils";
import { trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const schema = yupObject()
  .shape({
    firstName: yupString().required(),
    lastName: yupString().required(),
    email: yupString()
      .required("Please enter an email address.")
      .email("Please enter a valid email address."),
    phone: yupString()
      .required("Please enter a valid phone number.")
      .matches(
        /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a valid 10 digit phone number"
      ),
  })
  .required();

type ContactFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const ContactInformation = () => {
  const navigate = useNavigate();

  const { state, setFieldsAndNavigate } =
    useMultiStepFormState<ShingleContextState>(ShingleContext);

  // phone isn't in the default values here
  // but is passed in below because of how the PhoneNumber mask works
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ContactFormFields>({
    defaultValues: {
      firstName: state?.firstName ?? "",
      lastName: state?.lastName ?? "",
      email: state?.email ?? "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    trackEvent("shinglesForm_step3_load");
  }, []);

  useEffect(() => {
    if (!state?.policyId) {
      navigate("/shingle-repair-pilot");
    }
  }, [navigate, state?.policyId]);

  if (!state?.policyId) {
    return null;
  }

  const onSubmit = (data) => {
    trackEvent("shinglesForm_step3_ContinueClicked");
    setFieldsAndNavigate("../contact-information-confirmation", { ...data });
  };

  return (
    <ShingleRepairLayout
      title="How can our partner reach you?"
      description="In the event of a hurricane near you, our partner will reach out if your property is selected. You may receive an email with additional details."
      stepNumber={3}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
        <TextInput
          label="First name"
          {...register("firstName")}
          errorMessage={errors.firstName?.message}
          isError={!!errors.firstName}
          isRequired
        />
        <TextInput
          label="Last name"
          {...register("lastName")}
          errorMessage={errors.lastName?.message}
          isError={!!errors.lastName}
          isRequired
        />
        <TextInput
          label="Email"
          {...register("email")}
          errorMessage={errors.email?.message}
          isError={!!errors.email}
          isRequired
        />
        <TelephoneMask
          label="Phone number"
          {...register("phone")}
          errorMessage={errors.phone?.message}
          isError={!!errors.phone}
          helpMessage="Your phone number will only be used if needed to coordinate your shingle reseal visit"
          isRequired
          value={state.phone.replace(/\D+/g, "")}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            appearance="tertiary"
            onClick={() => {
              trackEvent("shinglesForm_step3_CancelClicked");
              navigate(getShingleLandingPath(state));
            }}
          >
            Cancel
          </Button>
          <div className={styles.navButtonsWrapper}>
            <Button
              appearance="secondary"
              onClick={() => {
                trackEvent("shinglesForm_step3_PreviousClicked");
                navigate(-1);
              }}
            >
              Previous
            </Button>
            <Button type="submit" disabled={!isValid}>
              Continue
            </Button>
          </div>
        </div>
      </form>
    </ShingleRepairLayout>
  );
};

export default ContactInformation;
