import PropTypes from "prop-types";

import { Body } from "components/common/body";
import { en as locale } from "locale";

const ProfileItemErrorMessage = ({ className }) => (
  <div className={className}>
    <Body>{locale.help.errorOccured}</Body>
  </div>
);

ProfileItemErrorMessage.propTypes = {
  className: PropTypes.string,
};

export default ProfileItemErrorMessage;
