import { useContext } from "react";

import { Button, Heading, MarketingIcon } from "@icg360/design-system";

import { Body } from "components/common/body";
import { CTACard } from "components/common/card";
import { AuthAppDispatchContext } from "components/root/auth-app-provider";
import { MODALS } from "consts";
import { trackEvent } from "utils";

import styles from "./paperless-card.module.scss";

export type PaperlessCardUnenrolledProps = {
  pageName: string;
  enrolledDocs: string;
  enrolledBills: string;
};

export const PaperlessCardUnenrolled = ({
  pageName,
  enrolledDocs,
  enrolledBills,
}: PaperlessCardUnenrolledProps) => {
  const { addPopup } = useContext(AuthAppDispatchContext);
  const openPaperlessModal = () => {
    trackEvent("Paperless Banner Enroll Button Clicked", {
      pageName,
      paperlessDocumentEnrollment: enrolledDocs,
      paperlessBillingEnrollment: enrolledBills,
    });
    addPopup(MODALS.PAPERLESS_MODAL);
  };
  return (
    <CTACard
      title={<Heading size="md">Paperless</Heading>}
      icon={
        <div className={styles.headerIcon}>
          <MarketingIcon name="Paperless" />
        </div>
      }
    >
      <Body>
        <b>Skip the extra mail and help plant trees by going paperless.</b>
      </Body>
      <Body>
        When you sign up, we&apos;ll donate $1 to the Arbor Day Foundation to
        plant trees in neighborhoods devastated by natural disasters.
      </Body>
      <Button
        appearance="secondary"
        size="default"
        onClick={openPaperlessModal}
        data-testid="enrollInPaperlessButton"
      >
        Enroll in Paperless
      </Button>
    </CTACard>
  );
};
