import { Form, useFormikContext } from "formik";

import {
  Alert,
  Button,
  Card,
  Heading,
  MarketingIcon,
} from "@icg360/design-system";

import { Body } from "components/common/body";
import {
  DataDefinition,
  DataTerm,
  InlineDataRow,
} from "components/common/data-row";
import { Stack } from "components/common/stack";
import { en as locale } from "locale";
import { TrampolineFormValues } from "pages/property-profile/update-trampoline";

import styles from "../update-trampoline.module.scss";

type ReviewTrampolineProps = {
  setReview: (boolean) => void;
  error?: string | null;
};

export const ReviewTrampoline = ({
  setReview,
  error,
}: ReviewTrampolineProps) => {
  const {
    values: { trampoline, trampolineShockAbsorbing },
    isSubmitting,
  } = useFormikContext<TrampolineFormValues>();
  return (
    <Card className={styles.reviewCard}>
      <Form>
        <Stack gap="md">
          <Heading size="lg">{locale.propertyProfile.pleaseReview}</Heading>
          {error && <Alert appearance="danger" title="" description={error} />}
          <div className={styles.iconHeaderReview}>
            <div className={styles.trampIcon}>
              <MarketingIcon name="Trampoline" size="sm" />
            </div>
            <Heading size="sm">{locale.propertyPage.trampoline}</Heading>
          </div>

          <Body>{locale.updateTrampoline.reviewDescription}</Body>

          <InlineDataRow className={styles.dataRow}>
            <DataTerm>{locale.propertyPage.trampoline}:</DataTerm>
            <DataDefinition>{trampoline ? "Yes" : "No"}</DataDefinition>
          </InlineDataRow>
          {trampoline && (
            <InlineDataRow className={styles.dataRow}>
              <DataTerm data-testid="shock-review">
                {locale.updateTrampoline.reviewShockabsorption}:
              </DataTerm>
              <DataDefinition>
                {trampolineShockAbsorbing ? "Yes" : "No"}
              </DataDefinition>
            </InlineDataRow>
          )}
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onClick={() => setReview(false)}
              data-testid="review-go-back-btn"
            >
              {locale.easypayEnroll.goBack}
            </Button>
            <Button
              appearance="primary"
              size="default"
              type="submit"
              disabled={isSubmitting}
              data-testid="trampoline-submit-btn"
            >
              {isSubmitting
                ? locale.propertyProfile.submitting
                : locale.propertyProfile.submitUpdates}
            </Button>
          </div>
        </Stack>
      </Form>
    </Card>
  );
};
