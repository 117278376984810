import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

import { HomeServicesMessage } from "components/home-services/types";

type HomeServicesContextState = {
  homeServicesMessages: HomeServicesMessage[];
  setHomeServicesMessages: Dispatch<SetStateAction<HomeServicesMessage[]>>;
};

const HomeServicesContext = createContext<HomeServicesContextState>({
  homeServicesMessages: [],
  setHomeServicesMessages: () => null,
});

function HomeServicesProvider({ children }: { children: ReactNode }) {
  const [homeServicesMessages, setHomeServicesMessages] = useState<
    HomeServicesMessage[]
  >([]);

  return (
    <HomeServicesContext.Provider
      value={{ homeServicesMessages, setHomeServicesMessages }}
    >
      {children}
    </HomeServicesContext.Provider>
  );
}

export { HomeServicesProvider, HomeServicesContext };
