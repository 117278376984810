import Cookies, { CookieGetOptions, CookieSetOptions } from "universal-cookie";

import { CONFIG } from "config";
import { modalCookies } from "consts";

const cookies = new Cookies();

type PolicyId = string;

export type CookieNames =
  | "hide-documents-to-sign"
  | "mss-easypay-modal-dismissed"
  | "mss-paperless-modal"
  | `mss-leakbot-newly-enrolled-${PolicyId}`
  | "mysagesure.rp"
  | "mss-selected-policy"
  | "mss-paperless-modal-dismissed"
  | "mss-bookroll-poa-opened"
  | "mss-saml-bridge-return"
  | "mss-saml-bridge"
  | "mss-username"
  | "new-badge-display";

export const setCookie = (
  name: CookieNames,
  data: any, // The type in cookies is also "any"
  maxAge = 43200000
) => {
  cookies.set(name, data, {
    domain: CONFIG.COOKIE_DOMAIN,
    path: "/",
    maxAge,
  });
};

export const getCookie = (name: CookieNames, options?: CookieGetOptions) => {
  return cookies.get(name, options);
};

export const removeCookie = (name: CookieNames, options?: CookieSetOptions) => {
  cookies.remove(name, { domain: CONFIG.COOKIE_DOMAIN, ...options });
};

export const removeAllCookies = () => {
  removeCookie("mss-saml-bridge-return", { path: "/" });
  removeCookie("mss-easypay-modal-dismissed", { path: "/" });
  removeCookie("mss-paperless-modal-dismissed", { path: "/" });
  removeCookie("mss-bookroll-poa-opened", { path: "/" });
};

export const modalCookieExists = (modal) =>
  cookies.get(modalCookies[modal]) !== undefined;

export function isCookieTrue(name: CookieNames): boolean {
  const input = getCookie(name);
  if (typeof input === "boolean") {
    return input;
  }
  if (input instanceof Boolean) {
    return input.valueOf();
  }
  if (typeof input === "string" || input instanceof String) {
    return input.toLowerCase() === "true";
  }
  return false;
}
