import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading } from "@icg360/design-system";

import { AuthAppContext } from "components/root/auth-app-provider";
import { PolicySummary } from "components/shared/policy-summary";
import { EnrollmentStep } from "pages/payment-plan-update/easypay-enroll-form/easypay-enroll-form";
import { isEasyPayEnrolled, useFlags } from "utils";

import styles from "./welcome-card.module.scss";

type WelcomeCardProps = {
  setStep: (step: EnrollmentStep) => void;
};

export const WelcomeCard = ({ setStep }: WelcomeCardProps) => {
  const navigate = useNavigate();

  const { easyPayFullPayOnly } = useFlags();

  const { selectedPolicyId, userDetails, userBilling } =
    useContext(AuthAppContext);

  const enrolledInEasyPay = isEasyPayEnrolled(userBilling);

  return (
    <div className={styles.wrapper}>
      <Heading size="lg">
        {enrolledInEasyPay
          ? "You're currently enrolled in EasyPay."
          : "Welcome to EasyPay!"}
      </Heading>
      <p>
        Pay your policy premium automatically with EasyPay. EasyPay is a smart
        way to save money, time, and paper—it eliminates your installment fee,
        helps you avoid late fees, and replaces wasteful paper invoices with
        digital statements.
      </p>
      <PolicySummary
        className={styles.policy}
        address={userDetails?.propertyAddress}
        policyId={selectedPolicyId}
        isCommercial={!!userDetails?.isCommercial}
      />
      <div className={styles.navigation}>
        <Button appearance="tertiary" onClick={() => navigate("/my/overview")}>
          Go back
        </Button>

        <Button
          appearance="primary"
          size="default"
          onClick={() =>
            setStep(easyPayFullPayOnly ? "chooseDay" : "choosePlan")
          }
          data-testid="welcome-get-started-btn"
        >
          {enrolledInEasyPay ? "Edit enrollment" : "Get started"}
        </Button>
      </div>
    </div>
  );
};
