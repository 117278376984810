import { useEffect, useState } from "react";

import { Button, Tooltip } from "@icg360/design-system";

import { CONFIG } from "config";
import { logError, trackEvent } from "utils";

import styles from "./google-auth-button.module.scss";

type Props = {
  gId: string;
  text?: google.accounts.id.GsiButtonConfiguration["text"];
  width?: number;
  className?: string;
  callback: (credential: string) => Promise<void>;
};

export const GoogleSignInButton = ({
  gId,
  text,
  width = 325,
  className,
  callback,
}: Props) => {
  const [errorCreatingButton, setErrorCreatingButton] = useState(false);
  useEffect(() => {
    const handleCredentialResponse = async (response) => {
      callback && (await callback(response.credential));
    };
    const client_id = CONFIG.GOOGLE_OAUTH_CLIENT_ID;
    const google = window.google;
    if (google) {
      try {
        google.accounts.id.initialize({
          client_id,
          callback: handleCredentialResponse,
        });

        const gElement = document.getElementById(gId);
        if (!gElement) {
          throw new Error("No button element found");
        }
        google.accounts.id.renderButton(gElement, {
          // customization attributes
          size: "large",
          theme: "outline",
          type: "standard",
          text,
          width,
        });
      } catch (e) {
        trackEvent("Google Login Button Rendering Failure");
        setErrorCreatingButton(true);
        logError(`Google sign in button: ${e.message}`);
      }
    }
  }, [callback, gId, text, width]);

  return errorCreatingButton ? (
    <Tooltip
      content={
        "Unfortunately, Google login is currently unavailable. To continue, activate your account with email and password."
      }
    >
      <div className={styles.googleUnavailable}>
        <Button disabled fluid>
          Sign in with Google
        </Button>
      </div>
    </Tooltip>
  ) : (
    <div className={className}>
      <div id={gId}></div>
    </div>
  );
};
