import { FormikErrors, useFormikContext } from "formik";

import CheckIcon from "images/icon-small-check.svg";
import CrossIcon from "images/icon-small-x.svg";
import { en as locale } from "locale";

import styles from "./password-validation-list.module.scss";

type PasswordValidationListProps = {
  password: string;
  passwordConfirmation: string;
};

type PasswordValidationErrors = {
  password?: string;
  passwordConfirmation?: string;
  errors?: string[];
};

export const PasswordValidationList = ({
  password,
  passwordConfirmation,
}: PasswordValidationListProps) => {
  const { errors } = useFormikContext();
  const { errors: passwordErrors }: FormikErrors<PasswordValidationErrors> =
    errors;

  return (
    <ul className={styles.list}>
      {locale.registrationPage.passwordValidationRules.map((rule, i) => (
        <li key={i}>
          {rule}
          {(password || passwordConfirmation) &&
            (passwordErrors?.includes(rule) ? (
              <CrossIcon className={styles.crossIcon} />
            ) : (
              <CheckIcon className={styles.checkIcon} />
            ))}
        </li>
      ))}
    </ul>
  );
};
