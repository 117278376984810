import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Heading } from "@icg360/design-system";

import { useMultiStepFormState } from "hooks/use-multi-step-form-state";
import {
  ShingleContext,
  ShingleContextState,
} from "pages/shingle-repair-pilot/index";
import ShingleRepairLayout from "pages/shingle-repair-pilot/shingle-repair-layout";
import { getShingleLandingPath } from "pages/shingle-repair-pilot/shingle-repair-pilot-utils";
import { obscurePolicyId } from "pages/shingle-repair-pilot/utils";
import { formatAddress, trackEvent } from "utils";

import styles from "./shingle-repair-pilot.module.scss";

const ContactInformationConfirmation = () => {
  const navigate = useNavigate();

  const { state, formNavigate } =
    useMultiStepFormState<ShingleContextState>(ShingleContext);

  useEffect(() => {
    trackEvent("shinglesForm_step4_load");
  }, []);

  useEffect(() => {
    if (!state?.policyId) {
      navigate("/shingle-repair-pilot");
    }
  }, [navigate, state?.policyId]);

  if (!state?.policyId) {
    return null;
  }

  const { firstName, lastName, policyId, propertyAddress, email, phone } =
    state;

  return (
    <ShingleRepairLayout
      title="Please review and confirm your details"
      stepNumber={4}
    >
      <div className={styles.confirmationItem}>
        <Heading size="xs">Name</Heading>
        <div
          className={styles.confirmationValue}
        >{`${firstName} ${lastName}`}</div>
      </div>
      <div className={styles.confirmationItem}>
        <Heading size="xs">Policy number</Heading>
        <div className={styles.confirmationValue}>
          {obscurePolicyId(policyId)}
        </div>
      </div>
      <div className={styles.confirmationItem}>
        <Heading size="xs">Address</Heading>
        <div className={styles.confirmationValue}>
          {formatAddress(propertyAddress)}
        </div>
      </div>
      <div className={styles.confirmationItem}>
        <Heading size="xs">Email</Heading>
        <div className={styles.confirmationValue}>{email}</div>
      </div>
      <div className={styles.confirmationItem}>
        <Heading size="xs">Phone number</Heading>
        <div className={styles.confirmationValue}>{phone}</div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          appearance="tertiary"
          onClick={() => {
            trackEvent("shinglesForm_step4_CancelClicked");
            navigate(getShingleLandingPath(state));
          }}
        >
          Cancel
        </Button>
        <div className={styles.navButtonsWrapper}>
          <Button
            appearance="secondary"
            onClick={() => {
              trackEvent("shinglesForm_step4_PreviousClicked");
              navigate(-1);
            }}
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              trackEvent("shinglesForm_step4_ContinueClicked");
              formNavigate("../waiver");
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </ShingleRepairLayout>
  );
};

export default ContactInformationConfirmation;
