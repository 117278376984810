// Create our number formatter.
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const formatterWithDecimals = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const formatCurrency = (nb, withDecimals = false) =>
  withDecimals
    ? formatterWithDecimals.format(nb).replace(/^(\()(.*)(\))$/, "-$2")
    : formatter.format(nb).replace(/^(\()(.*)(\))$/, "-$2");
