import { useNavigate } from "react-router-dom";

import { Button } from "@icg360/design-system";

import { trackEvent } from "utils";

import styles from "./footer-banner.module.scss";

type FooterBannerProps = {
  bannerText: string;
  bannerButtonText: string;
  bannerLink: string;
  eventName?: string;
};

const FooterBanner = ({
  bannerText,
  bannerButtonText,
  bannerLink,
  eventName,
}: FooterBannerProps) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (eventName) {
      trackEvent(eventName);
    }
    navigate(bannerLink);
  };

  return (
    <div className={styles.bannerSpacer}>
      <div className={styles.banner}>
        {bannerText}
        <Button
          appearance="secondary"
          data-testid="footer-banner-btn"
          onClick={onClick}
        >
          {bannerButtonText}
        </Button>
      </div>
    </div>
  );
};

export { FooterBanner };
