import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./form-legend.module.scss";

export const FormLegend = ({ children, className }) => (
  <legend className={cx(styles.legend, className)}>{children}</legend>
);

FormLegend.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
