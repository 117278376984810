import { Card, Span } from "@icg360/design-system";

import { MSSLink, PhoneIconLink } from "components/common/link";
import { en as locale } from "locale";

import styles from "./claim-help-section.module.scss";

export const ClaimHelpSection = () => {
  return (
    <Card className={styles.contact}>
      <Span color="quiet" bold>
        Need help?
      </Span>

      <div>
        <PhoneIconLink phone="(866) 311-7243" />
        <Span size="xs" className={styles.hours}>
          24 hours / 7 days a week
        </Span>
      </div>

      <MSSLink to="/my/support" className={styles.toFaqLink}>
        {locale.buttons.viewAllFaqs}
      </MSSLink>
    </Card>
  );
};
