import { Button } from "@icg360/design-system";

import { AddPolicyModal } from "./add-policy-modal";
import { useAddPolicy, useAddPolicyDataFromEmailLink } from "./hooks";

export const AddPolicy = () => {
  const [addPolicyDataFromEmail] = useAddPolicyDataFromEmailLink();

  const {
    addPolicyData,
    openAddPolicyModal,
    closeAddPolicyModal,
    handleAddPolicySucceeded,
    showAddPolicyModal,
  } = useAddPolicy(addPolicyDataFromEmail);

  return (
    <>
      <Button
        size="sm"
        appearance="tertiary"
        leadingIcon="AddCircle"
        onClick={openAddPolicyModal}
        data-testid="add-policy-btn"
      >
        Add policy
      </Button>
      <AddPolicyModal
        show={showAddPolicyModal}
        handleClose={closeAddPolicyModal}
        onAddPolicySuccess={handleAddPolicySucceeded}
        data={addPolicyData}
      />
    </>
  );
};
