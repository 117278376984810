import { createRoot } from "react-dom/client";

import { Root } from "components/root";

import { launchDarklyClient } from "./utils/feature-flags";
import { initializeTracing } from "./utils/tracing-instrumentations";
import { traceProvider } from "./utils/tracing-provider";

initializeTracing(traceProvider);

(async () => {
  // Block render until feature flags are initialized or times out
  await launchDarklyClient;

  const container = document.getElementById("app");
  if (!container) {
    console.error("no app element");
    return;
  }

  const root = createRoot(container);
  root.render(<Root />);
})();
