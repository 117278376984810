import { useState } from "react";

import { Link as DSLink, Heading, Modal } from "@icg360/design-system";

import styles from "./update-roof.module.scss";

const RoofTypesModal = ({ open, onClose }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Modal
      open={open}
      onClose={onClose}
      heading="Roof materials"
      size="lg"
      noFooter
    >
      <div className={styles.roofTypesContent}>
        <Heading size="sm">Asphalt or composite</Heading>
        These are common shingles made from asphalt or a composite creating an
        even, brick laying pattern.
        <Heading size="sm">Architectural</Heading>
        Similar material to composite shingles, but feature multi-layered
        construction, where random tabs or pads are applied to a base shingle to
        achieve added dimension. These shingles are sometimes referred to as
        laminated or three-dimensional shingles.
        <Heading size="sm">Wood</Heading>
        Wood tiles attached to roof substrate with nails or screws.
        <Heading size="sm">Wood shake</Heading>
        Textured wood tiles that create a more uneven look.
        <Heading size="sm">Clay or concrete tiles</Heading>
        Roof tiles that are made of concrete or clay. These tiles may be
        attached to the roof deck with mortar, nails, screws, or clips. Include
        stone and slate roofs in this category.
        <Heading size="sm">Steel</Heading>A steel roof is panels of steel with
        seamed edges. Can be neutral or colored.
        {showMore ? (
          <>
            <Heading size="sm">Other metal</Heading>
            Other metal roof designs can use tin and aluminum.
            <Heading size="sm">Built-up</Heading>
            Built-up roof cover is composed of several layers of roofing felt
            bonded to the roof substrate with asphalt. Built-up roofing is often
            topped with gravel to prevent degradation from sunlight.
            <Heading size="sm">Reinforced concrete</Heading>
            Roof made from a solid concrete slab.
            <Heading size="sm">Single-ply</Heading>
            Single-ply membranes are flexible sheets of compounded synthetic
            materials that are manufactured in a factory. These are commonly
            known as rubber sheet roofing products, but are more accurately
            referred to as EPDM, PVC, or modified bitumen sheets. For steel and
            concrete buildings, this is the most common type of roof covering.
            <Heading size="sm">Rubber</Heading>
            Flat or low-slope roof covering made from rubber.
            <Heading size="sm">T-Lock or interlocking</Heading>
            Shingles shaped like a “T” that look interlocked like a puzzle.
          </>
        ) : null}
        <div>
          <DSLink onClick={() => setShowMore(!showMore)}>
            {showMore ? "Hide" : "See"} more roof materials...
          </DSLink>
        </div>
      </div>
    </Modal>
  );
};

export default RoofTypesModal;
