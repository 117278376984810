import PropTypes from "prop-types";

import { Card, Heading, UiIcon } from "@icg360/design-system";

import styles from "./property-profile-sidebar.module.scss";

export const PropertyProfileSidebar = ({
  iconName = "Document",
  headline,
  body,
}) => {
  return (
    <Card className={styles.sidebar}>
      <Heading size="md" className={styles.iconHeader}>
        <div className={styles.icon}>
          <UiIcon name={iconName} />
        </div>
        {headline}
      </Heading>
      {body}
    </Card>
  );
};

PropertyProfileSidebar.propTypes = {
  icon: PropTypes.node,
  headline: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
