export const DATE_PLACEHOLDER = "___ __, ____";
export const PHONE_PLACEHOLDER = "(       )         -";
export const PHONE_MASK = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const CARD_EXPIRY_DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
