export const screenSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tabletS: "580px",
  tablet: "768px",
  laptop: "992px",
  laptopL: "1200px",
  desktop: "2560px",
};
