import { useContext } from "react";

import { AuthAppContext } from "components/root/auth-app-provider";
import {
  useLogoutUserMutation,
  useResetPolicyMutation,
} from "gql/__generated__/hooks";
import {
  logout,
  removeAllCookies,
  resetAnalytics,
  trackEvent,
  useFlags,
} from "utils";

export const useLogout = () => {
  const { universalLogin } = useFlags();
  const [resetPolicy] = useResetPolicyMutation();
  const [logoutUser] = useLogoutUserMutation();
  const { selectedPolicyId } = useContext(AuthAppContext);

  const logoutHandler = async () => {
    trackEvent("Log Out");
    if (selectedPolicyId) {
      resetPolicy({ variables: { policyID: selectedPolicyId } });
    }
    removeAllCookies();
    if (universalLogin) {
      logout();
    } else {
      const logoutResult = await logoutUser();
      if (logoutResult?.data?.logoutUser === "Logged out") {
        resetAnalytics();
        window.location.replace("/");
      }
    }
  };

  return { logout: logoutHandler };
};
