import { useState } from "react";

import { Button, addToast } from "@icg360/design-system";

import { PolicyPaymentDataQuery } from "gql/__generated__/hooks";
import { en as locale } from "locale";
import { trackEvent } from "utils";

import EditMortgageeModal from "./edit-mortgagee-modal";

type EditMortgageeProps = {
  mortgagee: NonNullable<
    NonNullable<PolicyPaymentDataQuery["userBilling"]>["mortgagees"]
  >[number];
  policyId: string;
  keystonePolicyId: string;
};

const EditMortgagee = ({
  mortgagee,
  policyId,
  keystonePolicyId,
}: EditMortgageeProps) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const openModal = () => {
    setShowEditModal(true);
    trackEvent("Edit mortgagee modal displayed");
  };

  return (
    <>
      <Button
        appearance="tertiary"
        size="xs"
        onClick={openModal}
        data-testid="edit-mortgagee-btn"
      >
        {locale.buttons.edit}
      </Button>
      <EditMortgageeModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        mortgageeData={mortgagee ?? undefined}
        readablePolicyId={policyId}
        policyId={keystonePolicyId}
        onEditMortgageeSuccess={() => {
          setShowEditModal(false);
          addToast(locale.settingsPage.editMortgageeSuccessful, { icon: true });
        }}
      />
    </>
  );
};

export default EditMortgagee;
