import { useMutation } from "@apollo/client";
import { Field, Form, useFormikContext } from "formik";
import { useCallback, useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { useLocation } from "react-router-dom";

import { Alert, Button } from "@icg360/design-system";

import { MSSLink } from "components/common/link";
import { GoogleSignInButton } from "components/google-auth-button";
import { InputField } from "components/shared/form-fields";
import { HTTP_NOT_FOUND } from "consts";
import {
  decodeData as decodeDataMutation,
  loginUserGoogleMutation,
} from "gql/mutations";
import {
  logError,
  separateRedirectParams,
  trackEvent,
  urlSearchParamsObject,
  useLoginRedirect,
} from "utils";

import styles from "./login.module.scss";

type LoginFormProps = {
  onErrorMsg: (message: string | string[]) => void;
};

export const LoginForm = ({ onErrorMsg }: LoginFormProps) => {
  const { search } = useLocation();

  const [showNoAccountWarning, setShowNoAccountWarning] = useState(false);

  const loginRedirectUser = useLoginRedirect();
  const {
    errors: formikErrors,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormikContext();

  const [loginUserGoogle] = useMutation(loginUserGoogleMutation);
  const [decodeData] = useMutation(decodeDataMutation);

  const disabled = Object.keys(formikErrors).length !== 0 || isSubmitting;

  useEffect(() => {
    if (isSubmitting) {
      setShowNoAccountWarning(false);
    }
  }, [isSubmitting]);

  /*
    Removes message param to prevent it from showing up on register page
    when changing route. Query params are used in the link to /register for
    the case where a user is linked to the site with policy details in the
    query string that can be used to pre-fill the registration form.
  */
  const { _message, ...rest } = urlSearchParamsObject(search);
  const filteredQuery = new URLSearchParams(rest);
  const filteredQueryString = `?${filteredQuery.toString()}`;

  useEffect(() => {
    const redirectedSearchParams = separateRedirectParams();
    const { data: encodedData } = urlSearchParamsObject(redirectedSearchParams);

    if (encodedData) {
      decodeData({ variables: { data: encodedData } }).then(({ data }) => {
        const {
          decodeData: { success, email },
        } = data;

        if (success) {
          setFieldValue("username", email);
        }
      });
    }
  }, [decodeData, setFieldValue]);

  const loginViaGoogle = useCallback(
    async (googleJWT) => {
      setShowNoAccountWarning(false);
      setSubmitting(true);
      trackEvent("Login with Google Button Clicked");

      try {
        const {
          data: {
            loginUserGoogle: loginUserResponse,
            loginUserGoogle: { success, errors: loginErrors, errorCode },
          },
        } = await loginUserGoogle({ variables: { googleJWT } });

        if (loginErrors) {
          if (errorCode === HTTP_NOT_FOUND) {
            setShowNoAccountWarning(true);
            trackEvent("New login with Google redirected to register", {
              errorCode,
            });
          } else {
            trackEvent("Error Displayed (Google login)", {
              errorCode,
              errorMessage: loginErrors[0],
            });
            onErrorMsg(loginErrors);
          }

          setSubmitting(false);
        } else if (success) {
          loginRedirectUser(loginUserResponse);
        }
      } catch (err) {
        const errorMessage =
          "We're having technical difficulties. Please try again later.";
        setSubmitting(false);
        onErrorMsg([errorMessage]);
        trackEvent("Error Displayed (Google login)", {
          errorCode: 500,
          errorMessage,
        });
        logError(`Login via google: ${err.message}`);
      }
    },
    [loginRedirectUser, loginUserGoogle, onErrorMsg, setSubmitting]
  );

  const clickForgotPassword = () => {
    trackEvent("Forgot Password Link Clicked");
  };

  const clickActivateAccount = () => {
    trackEvent("Click Activate your account");
  };

  return (
    <>
      {showNoAccountWarning ? (
        <Alert
          title=""
          appearance="info"
          description={
            <div>
              {"Looks like you haven't registered your account. "}
              <MSSLink to="/register">Activate your account</MSSLink>
            </div>
          }
          onDismiss={() => setShowNoAccountWarning(false)}
          dismissable
        />
      ) : null}
      <Form className={styles.loginForm} data-testid="loginForm">
        <div className={styles.loginInputs}>
          <div>
            <Field
              name="username"
              title="Email"
              component={InputField}
              placeholder="Enter email address"
            />
          </div>
          <div className={styles.passwordWrapper}>
            <Field
              name="password"
              type="password"
              title="Password"
              component={InputField}
              placeholder="Enter password"
            />
            <span className={styles.forgotPasswordLink}>
              <MSSLink to="/request-reset" onClick={clickForgotPassword}>
                Forgot Password?
              </MSSLink>
            </span>
          </div>
        </div>

        <Button
          appearance="primary"
          type="submit"
          id="loginBtn"
          disabled={disabled}
          data-testid="submitLoginButton"
          fluid
        >
          Sign in
        </Button>

        <MediaQuery minWidth={400}>
          {(matches) => (
            <GoogleSignInButton
              gId="googleSignInBtn2"
              width={matches ? 400 : 325}
              callback={loginViaGoogle}
            />
          )}
        </MediaQuery>

        <div className={styles.activateAccountLink}>
          <span>
            New here?{" "}
            <MSSLink
              onClick={clickActivateAccount}
              to={`/register${filteredQueryString}`}
            >
              Activate your account
            </MSSLink>
          </span>
        </div>
      </Form>
    </>
  );
};
