import { useState } from "react";

type StringState = {
  output: string;
  isShowingAll: boolean;
  exceedsMaxLength: boolean;
  showAll: () => void;
  showLess: () => void;
};

/**
 * Manage string truncation state.
 */
export function useTruncatedString(
  str: string,
  maxLength: number
): StringState {
  const [showAll, setShowAll] = useState(false);
  const exceedsMaxLength = str.length > maxLength;
  const tail = exceedsMaxLength ? "…" : "";
  return {
    output: showAll ? str : `${str.slice(0, maxLength)}${tail}`,
    isShowingAll: showAll || !exceedsMaxLength,
    exceedsMaxLength,
    showAll() {
      setShowAll(true);
    },
    showLess() {
      setShowAll(false);
    },
  };
}
