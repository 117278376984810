import { en as locale } from "locale";

export const mailingUpdateReasons = [
  {
    value: "FIXING_TYPO",
    label: locale.settingsPage.mailingUpdateReasons.fixingTypo,
  },
  {
    value: "SEND_DOCUMENTS_ELSEWHERE",
    label: locale.settingsPage.mailingUpdateReasons.sendingElsewhere,
  },
  {
    value: "UPCOMING_MOVE",
    label: locale.settingsPage.mailingUpdateReasons.movingTo,
  },
  {
    value: "TEMPORARY_RESIDENCE",
    label: locale.settingsPage.mailingUpdateReasons.tempResidence,
  },
  {
    value: "MOVED_PERMANENTLY",
    label: locale.settingsPage.mailingUpdateReasons.movedOut,
  },
  {
    value: "MOVED_INTEND_TO_SELL",
    label: locale.settingsPage.mailingUpdateReasons.intendToSell,
  },
  {
    value: "MOVED_RENTING_TO_TENANTS",
    label: locale.settingsPage.mailingUpdateReasons.renting,
  },
];
Object.values(locale.settingsPage.mailingUpdateReasons);

export const detailsRequiredReasons = [
  "TEMPORARY_RESIDENCE",
  "MOVED_PERMANENTLY",
  "MOVED_INTEND_TO_SELL",
  "MOVED_RENTING_TO_TENANTS",
];
