import cx from "classnames";
import { ReactElement } from "react";

import styles from "./progress-bar.module.scss";

type ProgressBarProps = {
  stepCount: number;
  completeCount: number;
};

const ProgressBar = ({ stepCount, completeCount }: ProgressBarProps) => {
  const steps: ReactElement[] = [];
  for (let i = 0; i < stepCount; i++) {
    steps.push(
      <div
        key={i}
        className={cx(styles.step, i < completeCount ? styles.complete : null)}
      />
    );
  }

  return <div className={styles.wrapper}>{steps}</div>;
};

export default ProgressBar;
