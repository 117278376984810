import { FieldProps } from "formik";
import get from "lodash.get";
import { useRef } from "react";

import { Textarea, TextareaProps } from "@icg360/design-system";

import { scrollToError } from "utils";

type TextAreaFieldDsProps = Omit<
  TextareaProps,
  | "helpMessage"
  | "name"
  | "label"
  | "isError"
  | "errorMessage"
  | "value"
  | "defaultValue"
> & {
  field: FieldProps["field"];
  form: FieldProps["form"];
  title: string;
  maxChars: number;
};

export const TextAreaField = ({
  field: { name, value },
  form: { errors, touched, setFieldTouched, isSubmitting, setFieldValue },
  title,
  maxChars,
  ...props
}: TextAreaFieldDsProps) => {
  const errorsField = get(errors, name);
  const hasError = Boolean(errorsField && get(touched, name));
  const remainingChars = maxChars - value.length;
  const inputRef = useRef(null);
  scrollToError(name, inputRef, hasError, isSubmitting, errors);
  const helpMessage = `${remainingChars} character${
    remainingChars === 1 ? "" : "s"
  } left.`;

  return (
    <Textarea
      id={name}
      name={name}
      label={title}
      isError={hasError}
      errorMessage={errorsField}
      value={value}
      helpMessage={helpMessage}
      maxLength={maxChars}
      onChange={(e) => {
        setFieldTouched(name, true, true);

        setFieldValue(name, e.currentTarget.value);
        if (props.onChange) {
          props.onChange(e);
        }
      }}
      {...props}
    />
  );
};
