import { Field, Form, useFormikContext } from "formik";

import { Button, Heading } from "@icg360/design-system";

import { CeremonyRegistrationSchema } from "components/ceremony-registration/ceremony-registration";
import { InputField } from "components/shared/form-fields";
import { PasswordValidationList } from "components/shared/password-validation-list";
import { en as locale } from "locale";

import styles from "./ceremony-registration.module.scss";

export const CeremonyRegistrationForm = () => {
  const { dirty, isSubmitting, values, isValid } =
    useFormikContext<CeremonyRegistrationSchema>();

  return (
    <Form className={styles.formWrapper}>
      <Field
        name="policyNumber"
        title={locale.dataTable.policyNumber}
        component={InputField}
        placeholder={locale.dataTable.policyNumberPlaceholderExample}
      />
      <Field
        name="zipCode"
        type="tel"
        title={locale.dataTable.zipCode}
        component={InputField}
        placeholder={locale.dataTable.zipCodePlaceholderExample}
      />

      <Heading size="xs" className={styles.capitalizedHeader}>
        {locale.registrationPage.aboutYourself}
      </Heading>

      <Field
        md={12}
        sm={12}
        xs={12}
        name="firstName"
        title={locale.dataTable.firstName}
        component={InputField}
        placeholder={locale.dataTable.firstNamePlaceholderExample}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        name="lastName"
        title={locale.dataTable.lastName}
        component={InputField}
        placeholder={locale.dataTable.lastNamePlaceholderExample}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        name="email"
        type="email"
        title={locale.dataTable.emailLabel}
        component={InputField}
        placeholder={locale.dataTable.emailPlaceholderExample}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        type="password"
        name="password"
        title={locale.form.label.password}
        component={InputField}
      />
      <Field
        md={12}
        sm={12}
        xs={12}
        type="password"
        name="passwordConfirmation"
        title={locale.form.label.confirmPassword}
        component={InputField}
      />

      <PasswordValidationList
        password={values["password"]}
        passwordConfirmation={values["passwordConfirmation"]}
      />
      <Button
        disabled={!isValid || !dirty || isSubmitting}
        type="submit"
        className={styles.registerButton}
        data-testid="ceremony-register-btn"
      >
        {locale.buttons.registerLower}
      </Button>
    </Form>
  );
};
