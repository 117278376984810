import { DataDefinition, DataRow, DataTerm } from "components/common/data-row";
import { en as locale } from "locale";

import styles from "./pool-data.module.scss";

export const PoolData = ({ poolData }) => {
  const {
    poolType,
    poolFence,
    poolFenceAboveGround,
    unlockedPoolGate,
    divingBoardOrSlide,
    immovablePoolLadder,
    poolCovering,
  } = poolData;
  const {
    propertyProfile: { poolDataTitles },
  } = locale;
  const aboveGround = poolType === "Above-ground";
  const fence = aboveGround ? poolFenceAboveGround : poolFence;

  return (
    <>
      <DataRow flex="row" className={styles.dataTerm}>
        <DataTerm>{poolDataTitles.poolType}:</DataTerm>
        <DataDefinition>{poolType}</DataDefinition>
      </DataRow>

      {poolType !== "None" && (
        <>
          <DataRow flex="row" className={styles.dataTerm}>
            <DataTerm>{poolDataTitles.fence}:</DataTerm>
            <DataDefinition>{fence}</DataDefinition>
          </DataRow>
          {fence === "Yes" && (
            <ul>
              <li>
                {aboveGround
                  ? poolDataTitles.fenceBulletAbove
                  : poolDataTitles.fenceBulletIn}
              </li>
              <li>{poolDataTitles.fenceBulletTwo}</li>
            </ul>
          )}

          {unlockedPoolGate && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{poolDataTitles.unlockedPoolGate}:</DataTerm>
              <DataDefinition>{unlockedPoolGate}</DataDefinition>
            </DataRow>
          )}

          {divingBoardOrSlide && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{poolDataTitles.divingBoardOrSlide}:</DataTerm>
              <DataDefinition>{divingBoardOrSlide}</DataDefinition>
            </DataRow>
          )}

          {aboveGround && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{poolDataTitles.immovablePoolLadder}:</DataTerm>
              <DataDefinition>{immovablePoolLadder}</DataDefinition>
            </DataRow>
          )}

          {poolCovering && (
            <DataRow flex="row" className={styles.dataTerm}>
              <DataTerm>{poolDataTitles.poolCovering}:</DataTerm>
              <DataDefinition>{poolCovering}</DataDefinition>
            </DataRow>
          )}
        </>
      )}
    </>
  );
};
