import moment from "moment";

import { POLICY_STATE_REASON } from "consts";

export const isPaymentDue = (remainingBalance, totalBalance) =>
  remainingBalance?.toString().charAt(0) !== "-" &&
  totalBalance?.toString() !== "0";

export const acceptPaymentOnCancelledPolicy = (
  policyStatus,
  cancellationReason,
  renewalDate,
  minimumPayment
) => {
  const isCancelledPolicy = RegExp(/(^cancel)/gi).test(policyStatus);
  const isWithin20DaysOfRenewal =
    moment().diff(moment(renewalDate), "days") <= 20;
  const renewalWasNotAccepted =
    cancellationReason === POLICY_STATE_REASON.renewalNotAccepted;

  return (
    isCancelledPolicy &&
    renewalWasNotAccepted &&
    isWithin20DaysOfRenewal &&
    minimumPayment > 0
  );
};
