import cx from "classnames";
import { PropsWithChildren } from "react";

import { Heading, MarketingIcon, Span } from "@icg360/design-system";

import { Address, formatAddress } from "utils";

import styles from "./policy-summary.module.scss";

type PolicySummaryProps = PropsWithChildren<{
  address?: Address | null;
  policyId: string;
  isCommercial: boolean;
  className?: string;
}>;

export const PolicySummary = ({
  address,
  policyId,
  isCommercial,
  className,
  children,
}: PolicySummaryProps) => (
  <div className={cx(styles.policyItemHeader, className)}>
    <div className={styles.policyIcon}>
      <MarketingIcon name={isCommercial ? "Shop" : "FullHouse"} size="sm" />
    </div>
    <div className={styles.content}>
      <Heading size="sm">{formatAddress(address, true)}</Heading>
      <Span>
        <Span bold>Policy number:</Span> {policyId}
      </Span>
      {children}
    </div>
  </div>
);
